import classNames from 'classnames';
import { PropertyType } from 'components/Modals/PropertyConfigurationModal/PropertyConfigurationModal';
import SelectPreviewField from 'components/PropertyComponents/SelectPreviewField/SelectPreviewField';
import { runInAction } from 'mobx';
import { inject, observer } from 'mobx-react';
import { PredicateChild } from 'models/Bot';
import { useEffect, useRef, useState } from 'react';
import { getDefaultType, useClickedOutside, useEscape } from 'services/Helper';
import './BotConditionTag.scss';
import SelectDropDown from 'components/SelectDropDown/SelectDropDown';
import { countries } from 'services/CountryHelper';
import '../ConditionTag/ConditionTag.scss';
import ConditionValueSelect from 'components/ConditionValueSelect/ConditionValueSelect';
import { PropertyStore } from 'stores/private/PropertyStore';
import StatusSelection from 'components/BugStatusSelection/StatusSelection';
import PriorityFilter from 'components/MagicFilter/PriorityFilter/PriorityFilter';
import { ProjectStore } from 'stores/private/ProjectStore';

const textLike = [
  PropertyType.TEXT,
  PropertyType.EMAIL,
  PropertyType.NUMBER,
  PropertyType.TEXTAREA,
];

const typeMapping = {
  TEXT: 'string',
  NUMBER: 'number',
  BOOLEAN: 'boolean',
  DATE: 'date',
  EMAIL: 'string',
};

export const operatorOptions = [
  {
    label: 'is',
    value: 'equals',
    inputType: [...textLike, PropertyType.SELECT],
  },
  {
    label: 'is not',
    value: 'not_equals',
    inputType: [...textLike, PropertyType.SELECT],
  },
  {
    label: 'includes',
    value: 'includes',
    inputType: [PropertyType.MULTISELECT],
  },
  {
    label: 'contains',
    value: 'contains',
    inputType: textLike,
  },
  {
    label: 'does not contain',
    value: 'not_contains',
    inputType: textLike,
  },
  {
    label: 'starts with',
    value: 'starts_with',
    inputType: textLike,
  },
  {
    label: 'ends with',
    value: 'ends_with',
    inputType: textLike,
  },
  {
    label: 'more than',
    value: 'greater_than',
    inputType: textLike,
  },
  {
    label: 'less than',
    value: 'less_than',
    inputType: textLike,
  },
  {
    label: 'is empty',
    value: 'is_empty',
    hideInput: true,
  },
  {
    label: 'is not empty',
    value: 'not_empty',
    hideInput: true,
  },
  {
    label: 'on date',
    value: 'on_date',
    inputType: [PropertyType.DATE],
  },
  {
    label: 'before date',
    value: 'before_date',
    inputType: [PropertyType.DATE],
  },
  {
    label: 'after date',
    value: 'after_date',
    inputType: [PropertyType.DATE],
  },
];

interface BotConditionTagProps {
  predicate: PredicateChild;
  onAddCondition: () => void;
  onRemove: () => void;
  showAddCondition?: boolean;
  propertyStore?: PropertyStore;
  projectStore?: ProjectStore;
  properties?: any[];
}

const BotConditionTag = ({
  predicate,
  onAddCondition,
  onRemove,
  showAddCondition = true,
  propertyStore,
  projectStore,
  properties = [],
}: BotConditionTagProps) => {
  const [isOpen, setIsOpen] = useState(false);
  const [toggleState, setToggleState] = useState(false);
  const wrapperRef = useRef(null);
  useClickedOutside(wrapperRef, () => {
    setIsOpen(false);
  });
  useEscape(() => {
    setIsOpen(false);
  });

  useEffect(() => {
    if (!predicate.value) {
      setIsOpen(true);
    }
  }, [predicate]);

  const selectedProperty = properties.find((property) => {
    return property.id === predicate.attribute;
  });

  const isValid = () => {
    if (predicate.dataType === 'office-hours') {
      return true;
    }

    if (
      predicate.operator &&
      ['is_empty', 'not_empty'].includes(predicate.operator)
    ) {
      return true;
    }
    if (!predicate.dataType || !predicate.operator || !predicate.value) {
      return false;
    }

    return true;
  };

  const renderConditionName = () => {
    let label: any[] = [];

    // Data type
    if (selectedProperty != null) {
      label.push(selectedProperty.label);
    } else {
      if (predicate.dataType != null) {
        label.push(predicate.dataType);
      }
    }

    // Operator
    if (predicate.operator != null) {
      const operatorLabel = operatorOptions.find(
        (option) => option.value === predicate.operator,
      )?.label;

      if (operatorLabel != null) {
        label.push(
          <span key="operatorLabel" className="operator-label">
            {' '}
            {operatorLabel}{' '}
          </span>,
        );
      }
    }

    // Value
    if (predicate.attribute === 'status') {
      const readableStatus = projectStore?.findReadableStatusForTypeAndKey(
        predicate.value,
        (predicate as any).ticketType,
      );

      label.push(` ${readableStatus}`);
    } else if (
      predicate.value != null &&
      predicate.dataType !== 'office-hours'
    ) {
      label.push(` ${predicate.value}`);
    }

    // Wrap the array of elements in a parent span element
    return <span>{label}</span>;
  };

  const renderEditorModalContent = () => {
    if (predicate.dataType === 'office-hours') {
      return (
        <div className="text p-20">
          {predicate.attribute === 'during-office-hours'
            ? 'During office hours selected.'
            : 'Outside office hours selected.'}
        </div>
      );
    } else if (predicate.attribute === 'location.country') {
      return (
        <div className="operator-select-options">
          {operatorOptions
            .filter((operator) => {
              if (
                operator?.value === 'equals' ||
                operator?.value === 'not_equals'
              ) {
                return true;
              }

              return false;
            })
            .map((option, index) => {
              return (
                <>
                  <label
                    className="bb-feedback-multiplechoice-container"
                    key={`${option.value}-${index}`}
                  >
                    <div className="text">{option.label}</div>
                    <input
                      type="radio"
                      name={`condition-${option.value}`}
                      checked={predicate.operator === option.value}
                      onChange={() => {
                        runInAction(() => {
                          predicate.operator = option.value as any;

                          setToggleState(!toggleState);
                        });
                      }}
                    />
                    <span className="bb-feedback-multiplechoice-checkmark" />
                  </label>
                  {predicate.operator === option.value ? (
                    <div className="field-container value-input-container">
                      <div className="input-wrapper value-input-wrapper">
                        <SelectDropDown
                          className="country-dropdown"
                          isSearchable={true}
                          labelPropertyName="name"
                          valuePropertyName="code"
                          selectedItem={countries.find(
                            (country) =>
                              country.code ===
                              (predicate?.value || option.value),
                          )}
                          items={countries}
                          menuPosition="fixed"
                          onChange={(option) => {
                            runInAction(() => {
                              predicate.value = option.code;

                              setToggleState(!toggleState);
                            });
                          }}
                        />
                      </div>
                    </div>
                  ) : (
                    <></>
                  )}
                </>
              );
            })}
        </div>
      );
    } else if (predicate.dataType === 'attribute') {
      return (
        <div className="operator-select-options">
          {operatorOptions
            .filter((operator) => {
              if (!operator.inputType || !selectedProperty.type) {
                return true;
              }

              if (operator.inputType.includes(selectedProperty.type)) {
                return true;
              }

              return false;
            })
            .map((option, index) => {
              const requiredType =
                selectedProperty?.type && typeMapping[selectedProperty.type];

              return (
                <>
                  <label
                    className="bb-feedback-multiplechoice-container"
                    key={`${option.value}-${index}`}
                  >
                    <div className="text">{option.label}</div>
                    <input
                      type="radio"
                      name={`condition-${option.value}`}
                      checked={predicate.operator === option.value}
                      onChange={() => {
                        runInAction(() => {
                          predicate.operator = option.value as any;

                          setToggleState(!toggleState);
                        });
                      }}
                    />
                    <span className="bb-feedback-multiplechoice-checkmark" />
                  </label>
                  {predicate.operator === option.value &&
                    !option.hideInput &&
                    (selectedProperty.type === PropertyType.SELECT ||
                    selectedProperty.type === PropertyType.MULTISELECT ? (
                      <div className="field-container value-input-container">
                        <div className="input-wrapper value-input-wrapper">
                          <SelectPreviewField
                            isEditingForced={true}
                            labelPropertyName="label.localized.en"
                            valuePropertyName="value"
                            items={selectedProperty.options ?? []}
                            selectedItem={selectedProperty.options?.find(
                              (item) => item.value === predicate.value,
                            )}
                            onChange={(item) => {
                              runInAction(() => {
                                predicate.value = item.value;

                                setToggleState(!toggleState);
                              });
                            }}
                          />
                        </div>
                      </div>
                    ) : (
                      <div className="field-container">
                        <div className="input-wrapper">
                          <ConditionValueSelect
                            defaultType={
                              getDefaultType(predicate.value) ?? 'string'
                            }
                            label={predicate.attribute}
                            attribute={{
                              ...predicate,
                              data: predicate.value,
                            }}
                            requiredType={requiredType}
                            onValueChange={(val) => {
                              runInAction(() => {
                                predicate.value = val.data;
                                setToggleState(!toggleState);
                              });
                            }}
                          />
                        </div>
                      </div>
                    ))}
                </>
              );
            })}
        </div>
      );
    } else if (predicate.dataType === 'ticket') {
      if (predicate.attribute === 'status') {
        return (
          <StatusSelection
            status={predicate.value ?? ''}
            feedbackType={(predicate as any).ticketType ?? 'BUG'}
            onChange={(status) => {
              runInAction(() => {
                predicate.operator = 'equals' as any;
                predicate.value = status;
                setToggleState(!toggleState);
              });
            }}
          />
        );
      } else if (predicate.attribute === 'priority') {
        return (
          <PriorityFilter
            value={predicate.value}
            onValueChanged={(priority) => {
              runInAction(() => {
                predicate.operator = 'equals' as any;
                predicate.value = priority;
                setToggleState(!toggleState);
              });
            }}
          />
        );
      }
    }

    return null;
  };

  const buildAddCondition = () => {
    return (
      <div className="bot-condition-tag--inner" onClick={onAddCondition}>
        +
      </div>
    );
  };

  const cardClassName = classNames({
    'bot-condition-tag': true,
    'bot-condition-tag--invalid': !isOpen && !isValid(),
  });

  const overlayClassname = classNames({
    'bot-condition-tag-overlay': true,
    'bot-condition-tag-overlay--special': predicate.dataType === 'ticket',
    'bot-condition-tag-overlay--open': isOpen,
  });

  return (
    <>
      <div className={cardClassName} ref={wrapperRef}>
        <div
          className="bot-condition-tag--inner"
          onClick={() => {
            setIsOpen(!isOpen);
          }}
        >
          {renderConditionName()}
          <div className="condition-remove">
            <i
              className="fa-sharp fa-solid fa-xmark"
              onClick={() => {
                onRemove();
              }}
            ></i>
          </div>
        </div>
        <div className={overlayClassname}>{renderEditorModalContent()}</div>
      </div>
      {showAddCondition && buildAddCondition()}
    </>
  );
};

export default inject(
  'propertyStore',
  'projectStore',
)(observer(BotConditionTag));

import sanitizeHtml from 'sanitize-html';

export const sanitizeHtmlHelper = (dirtyHtml: string): string => {
  const options: any = {
    disallowedTagsMode: 'recursiveEscape',
  };

  const cleanHtml = sanitizeHtml(dirtyHtml, options);

  return cleanHtml;
};

import { inject, observer } from 'mobx-react';
import { Feature } from 'models/Enums';
import { useCallback, useEffect, useState } from 'react';
import { useNavigate } from 'react-router';
import ReactTooltip from 'react-tooltip';
import { isMacintosh } from 'services/Helper';
import { BugStore } from 'stores/private/BugStore';
import { OrganisationStore } from 'stores/private/OrganisationStore';
import { ProjectStore } from 'stores/private/ProjectStore';
import Swal from 'sweetalert2';
import './LiveTranslateMessageAction.scss';
import { toast } from 'react-toastify';

const LiveTranslateMessageAction = ({
  onTextTranslated,
  originalText,
  isEmpty,
  bugStore,
  projectStore,
  organisationStore,
}: {
  onTextTranslated: (text: string) => void;
  isEmpty: boolean;
  bugStore?: BugStore;
  projectStore?: ProjectStore;
  organisationStore?: OrganisationStore;
  originalText;
}) => {
  const [generating, setGenerating] = useState(false);
  const navigate = useNavigate();

  const performAction = async () => {
    const planIsEntitled = projectStore?.isFeatureInPlan(Feature.INTEGRATIONS, [
      'some',
      'all',
    ]);

    if (!planIsEntitled) {
      return Swal.fire({
        title: 'Upgrade to Gleap Pro',
        text: 'Upgrade to Gleap Pro to use AI reply.',
        showCancelButton: true,
        confirmButtonText: `Upgrade now ✨`,
        denyButtonText: `Cancel`,
      }).then(async (result) => {
        if (result.isConfirmed) {
          navigate(
            `/organization/${organisationStore?.currentOrganisation?.id}/billing?upgrade=true`,
          );
        }
      });
    }

    if (generating) {
      toast.info('Please wait until the previous generation is finished.');
      return;
    }

    if (!originalText || isEmpty) {
      toast.info('Please enter a message to translate.');
      return;
    }

    setGenerating(true);

    try {
      const translated = await projectStore?.aiTranslate(
        originalText?.content,
        bugStore?.currentBug?.session?.lang,
        projectStore?.currentProject?.id,
      );

      setGenerating(false);
      if (translated) {
        onTextTranslated(translated);
      }
    } catch (error) {
      setGenerating(false);
      toast.error('An error occurred while translating the message.');
    }
  };

  const handleUserKeyPress = useCallback((event: any) => {
    const { key } = event;

    if ((event.metaKey || event.ctrlKey) && key.toLowerCase() === 'j') {
      performAction();
      event.preventDefault();
      return;
    }

    if ((event.metaKey || event.ctrlKey) && event.shiftKey && key.toLowerCase() === 'j') {
      performAction();
      event.preventDefault();
      return;
    }
  }, []);

  useEffect(() => {
    window.addEventListener('keydown', handleUserKeyPress);
    return () => {
      window.removeEventListener('keydown', handleUserKeyPress);
    };
  }, [handleUserKeyPress]);

  return (
    <div className="macro-container">
      <ReactTooltip
        id="translateButtonTooltip"
        className="infoTooltip infoTooltipButton"
        delayHide={0}
        type="light"
        effect="solid"
        getContent={(content) => {
          return (
            <div className="send-key-tooltip">
              <span>{content}</span>
              <div className="hotkey-help">
                {isMacintosh() ? <div>⌘</div> : <div>Ctrl</div>}
                <div>Shift</div>
                <div>J</div>
              </div>
            </div>
          );
        }}
      />
      <div
        data-for="translateButtonTooltip"
        data-tip="Translate message to contact's language"
        className="macro-container-item"
        onClick={() => {
          performAction();
        }}
      >
        {generating ? (
          <i className="fa-duotone fa-spinner-third fa-spin" />
        ) : (
          <i className="fa-regular fa-language" />
        )}
      </div>
    </div>
  );
};

export default inject(
  'bugStore',
  'projectStore',
  'organisationStore',
)(observer(LiveTranslateMessageAction));

import LinkButton from 'components/LinkButton/LinkButton';
import SidebarFoldable from 'components/SidebarFoldable/SidebarFoldable';
import { inject, observer } from 'mobx-react';
import { Bug } from 'models/Bug';
import moment from 'moment';
import { BugStore } from 'stores/private/BugStore';
import { MODALTYPE, ModalStore } from 'stores/private/ModalStore';
import { ProjectStore } from 'stores/private/ProjectStore';
import './BugDuplicates.scss';

interface BugDuplicatesProps {
  bug?: Bug;
  projectStore?: ProjectStore;
  bugStore?: BugStore;
  modalStore?: ModalStore;
}

const BugDuplicates = ({
  bug,
  projectStore,
  bugStore,
  modalStore,
}: BugDuplicatesProps) => {
  if (bug?.archived) {
    return <></>;
  }

  return (
    <SidebarFoldable title="Merge similar ticket" infoKey="duplicates">
      <LinkButton
        onClick={() => {
          modalStore!.openModal(MODALTYPE.DUPLICATE_SEARCH, {}, true);
        }}
        iconSideRight={false}
        icon="merge"
        label="Merge with similar"
        className="bfw"
      />
    </SidebarFoldable>
  );
};

export default inject(
  'projectStore',
  'bugStore',
  'modalStore',
)(observer(BugDuplicates));

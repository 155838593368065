import addIcon from 'assets/icons/np_add_1776281_FFFFFF.svg';
import ExtendedMenu from 'components/ExtendedMenu/ExtendedMenu';
import { inject, observer } from 'mobx-react';
import { useNavigate } from 'react-router';
import ReactTooltip from 'react-tooltip';
import { MODALTYPE, ModalStore } from 'stores/private/ModalStore';
import { ProjectStore } from 'stores/private/ProjectStore';
import 'styles/swal-theme.scss';
import Swal from 'sweetalert2';
import './CustomLaneHeader.scss';

interface CustomLaneHeaderProps {
  title: string;
  laneKey: string;
  count?: number;
  unread?: number;
  modalStore?: ModalStore;
  projectStore?: ProjectStore;
  feedbackTypeFilter: string;
  isLoading?: boolean;
}

const CustomLaneHeader = ({
  title,
  laneKey,
  count,
  modalStore,
  projectStore,
  feedbackTypeFilter,
  unread = 0,
  isLoading = false,
}: CustomLaneHeaderProps) => {
  const navigate = useNavigate();
  const isFeatureRequest =
    projectStore?.currentFeedbackType?.type === 'FEATURE_REQUEST';

  const dropDownMenuClicked = async (item) => {
    if (item.id === 1) {
      Swal.fire({
        text: 'Do you really want to archive all selected items in this column?',
        showCancelButton: true,
        confirmButtonText: `Yes`,
        denyButtonText: `No`,
      }).then(async (result) => {
        if (result.isConfirmed) {
          await projectStore?.archiveFeedbackItems(laneKey, feedbackTypeFilter);
        }
      });
    }
  };

  const renderItemMenu = () => {
    const items = [
      {
        label: 'Archive all items in column',
        id: 1,
      },
    ];
    return <ExtendedMenu items={items} onClick={dropDownMenuClicked} />;
  };

  const renderAdd = () => {
    if (laneKey === 'OPEN') {
      return (
        <div
          className="add-bug"
          onClick={() => {
            modalStore!.openModal(MODALTYPE.CREATE_TICKET);
          }}
        >
          <img src={addIcon} alt="add icon" />
        </div>
      );
    }
    return <></>;
  };

  return (
    <>
      <div
        className={`custom-lane-header ${
          isFeatureRequest && 'custom-lane-header--feature-request'
        }`}
      >
        <div className="left-hand">
          {unread > 0 && (
            <div className="unread">{unread > 10 ? '10+' : unread}</div>
          )}
          <div className="status">
            {title === 'Under review' && (
              <i
                className="fa-sharp fa-solid fa-eye-slash"
                data-for="iconTooltip"
                data-tip="Please note that all feature requests in 'Under review' won't be displayed publicly."
              />
            )}
            {title === 'Closed' && (
              <i className="fa-sharp fa-solid fa-eye-slash" />
            )}
            {title}
          </div>
          {((count ?? 0) > 0 || !isLoading) && (
            <div className="counter">{count || 0}</div>
          )}
          {isLoading && (
            <div className="loading-data">
              <i className="fa-duotone fa-spinner-third fa-spin" />
            </div>
          )}
        </div>
        <div className="right-hand">
          {renderAdd()}
          {renderItemMenu()}
        </div>
      </div>
      <ReactTooltip
        id="iconTooltip"
        className="infoTooltip"
        delayHide={1000}
        type="light"
        place={'right'}
        effect="solid"
        html
      />
    </>
  );
};

export default inject('modalStore', 'projectStore')(observer(CustomLaneHeader));

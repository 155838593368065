import axios from './Axios';
import { validateRecaptchaAction } from './Recaptcha';

const snoozeBug = (id: string, data: any): Promise<unknown> => {
  return axios.put(`/bugs/${id}/snooze`, data);
};

const updateBug = (id: string, data: any): Promise<unknown> => {
  return axios.put(`/bugs/${id}`, data);
};

const sendToIntegration = (
  id: string,
  integrationId: string,
  integrationType: string,
): Promise<unknown> => {
  return axios.put(`/bugs/${id}/sendtointegration`, {
    integrationId,
    integrationType,
  });
};

const subscribeToBug = (id: string, email: string): Promise<unknown> => {
  return axios.put(`/bugs/${id}/subscribe`, {
    email,
  });
};

const voteBug = async (
  shareToken: string,
  gleapId,
  gleapHash,
): Promise<unknown> => {
  const token = (await validateRecaptchaAction('upvote')) as any;
  const res = axios.put(
    `/bugs/${shareToken}/vote`,
    {
      token,
    },
    {
      headers: {
        'gleap-id': gleapId,
        'gleap-hash': gleapHash,
      },
    },
  );
  return res;
};

const updateSession = async (
  data: {
    email?: string;
    name?: string;
  },
  gleapId,
  gleapHash,
  apiToken,
): Promise<unknown> => {
  const res = axios.put(`/sessions/update`, data, {
    headers: {
      'gleap-id': gleapId,
      'gleap-hash': gleapHash,
      'api-token': apiToken,
    },
  });
  return res;
};

const createBug = (bugData: any, projectId: string) => {
  return axios.post(`/projects/${projectId}/bugs`, bugData);
};

const addSharedCommentWithSession = async (
  shareToken: string,
  gleapId: string,
  gleapHash: string,
  comment: string,
) => {
  const token = (await validateRecaptchaAction('createsharedcomment')) as any;
  const res = await axios.post(`/bugs/${shareToken}/comments/shared`, {
    comment,
    gleapId,
    gleapHash,
    token,
  });
  return res;
};

const addSharedCommentWithEmail = async (
  shareToken: string,
  email: string,
  comment: string,
) => {
  const token = (await validateRecaptchaAction('createsharedcomment')) as any;
  const res = await axios.post(`/bugs/${shareToken}/comments/shared`, {
    comment,
    email,
    token,
  });
  return res;
};

const addCommentToBug = (
  id: string,
  comment: string,
  isNote: Boolean,
  attachments?: any[],
) => {
  return axios.post(`/bugs/${id}/comments`, {
    comment,
    isNote,
    attachments,
  });
};

const generateAIResponse = (id: string) => {
  return axios.post(`/bugs/${id}/generateairesponse`, {});
};

const suggestFeatureRequest = (id: string) => {
  return axios.get(`/bugs/${id}/suggestfeaturerequest`);
};

const runWorkflowForTicket = (id: string, workflowId: string) => {
  return axios.post(`/bugs/${id}/workflow`, {
    workflowId,
  });
};

const userIsTypingInTicket = (id: string, typing: boolean) => {
  return axios.post(`/bugs/${id}/typing`, {
    typing: typing,
  });
};

const deleteCommentFromBug = (id: string, commentId: string) => {
  return axios.delete(`/bugs/${id}/comments/${commentId}`);
};

const commentTranslate = (id: string, commentId: string, content: any) => {
  return axios.put(`/bugs/${id}/comments/${commentId}/translation`, {
    content,
  });
}

const updateComment = (
  bugId: string,
  commentId: string,
  data: any,
): Promise<any> => {
  return axios.put(`/bugs/${bugId}/comments/${commentId}/edit`, data);
};

const respondToFAQSuggestionComment = (
  id: string,
  commentId: string,
  accept: boolean,
) => {
  return axios.put(`/bugs/${id}/comments/${commentId}/faq`, {
    accept,
  });
};

const getComments = (shareToken: string) => {
  return axios.get(`/bugs/${shareToken}/comments`);
};

const getSharedComments = (
  shareToken: string,
  gleapId?: string,
  gleapHash?: string,
  sharedGleapIdSecret?: string,
) => {
  let options = {};
  if (gleapId && gleapHash) {
    options = {
      headers: {
        gleap_id: gleapId,
        gleap_hash: gleapHash,
        'secret-share-token': sharedGleapIdSecret ?? '',
      },
    };
  }
  return axios.get(`/bugs/${shareToken}/comments/shared`, options);
};

const getBug = (
  shareToken: string,
  gleapId?: string,
  gleapHash?: string,
  token?: string,
) => {
  let options = {
    headers: {
      'gleap-id': gleapId ?? '',
      'gleap-hash': gleapHash ?? '',
      'secret-share-token': token ?? '',
    },
  };
  return axios.get(`/bugs/${shareToken}`, options);
};

const getPropertiesForSharedBug = (shareToken: string) => {
  return axios.get(`/bugs/${shareToken}/properties`);
};

const getDuplicatedBugs = (id: string) => {
  return axios.get(`/bugs/${id}/duplicates`);
};

const getActivityLog = (
  shareToken: string,
  gleapId?: string,
  gleapHash?: string,
  sharedGleapIdSecret?: string,
) => {
  let options = {
    headers: {
      'gleap-id': gleapId ?? '',
      'gleap-hash': gleapHash ?? '',
      'secret-share-token': sharedGleapIdSecret ?? '',
    },
  };
  return axios.get(`/bugs/${shareToken}/activitylog`, options);
};

const getLogs = (
  shareToken: string,
  gleapId?: string,
  gleapHash?: string,
  sharedGleapIdSecret?: string,
) => {
  let options = {
    headers: {
      'gleap-id': gleapId ?? '',
      'gleap-hash': gleapHash ?? '',
      'secret-share-token': sharedGleapIdSecret ?? '',
    },
  };
  return axios.get(`/bugs/${shareToken}/consolelog`, options);
};

const getNetworkLogs = (
  shareToken: string,
  gleapId?: string,
  gleapHash?: string,
  sharedGleapIdSecret?: string,
) => {
  let options = {
    headers: {
      'gleap-id': gleapId ?? '',
      'gleap-hash': gleapHash ?? '',
      'secret-share-token': sharedGleapIdSecret ?? '',
    },
  };
  return axios.get(`/bugs/${shareToken}/networklog`, options);
};

const deleteBug = (id: string) => {
  return axios.delete(`/bugs/${id}`);
};

const archiveBug = (id: string) => {
  return axios.put(`/bugs/${id}/archive`);
};

const addMeAsAdmin = (id: string) => {
  return axios.put(`/bugs/${id}/addmeasadmin`);
};

const unarchiveBug = (id: string) => {
  return axios.put(`/bugs/${id}/unarchive`);
};

const markBugAsDuplicate = (args: {
  ticketToMergeId: string;
  sourceTicketId: string;
  shouldCreateNewTicket: boolean;
  shouldMergeTasks: boolean;
  shouldMergeContacts: boolean;
}) => {
  const {
    ticketToMergeId,
    sourceTicketId,
    shouldCreateNewTicket,
    shouldMergeTasks,
    shouldMergeContacts,
  } = args;

  return axios.post(`/bugs/${ticketToMergeId}/markduplicate`, {
    sourceTicketId,
    shouldCreateNewTicket,
    shouldMergeTasks,
    shouldMergeContacts,
  });
};

const linkTicket = (args: {
  ticketToLinkId: string;
  sourceTicketId: string;
}) => {
  const { ticketToLinkId, sourceTicketId } = args;

  return axios.post(`/bugs/${ticketToLinkId}/link`, {
    sourceTicketId,
  });
};

const unlinkTicket = (args: { ticketToLinkId: string }) => {
  const { ticketToLinkId } = args;

  return axios.post(`/bugs/${ticketToLinkId}/unlink`);
};

const removeBugAsDuplicate = async (id: string) => {
  return await axios.post(`/bugs/${id}/removeduplicate`, {});
};

export {
  addMeAsAdmin,
  createBug,
  updateBug,
  getBug,
  addCommentToBug,
  getActivityLog,
  deleteCommentFromBug,
  getComments,
  getLogs,
  getDuplicatedBugs,
  deleteBug,
  archiveBug,
  unarchiveBug,
  getNetworkLogs,
  voteBug,
  getSharedComments,
  addSharedCommentWithEmail,
  addSharedCommentWithSession,
  markBugAsDuplicate,
  linkTicket,
  unlinkTicket,
  removeBugAsDuplicate,
  sendToIntegration,
  updateSession,
  subscribeToBug,
  userIsTypingInTicket,
  snoozeBug,
  generateAIResponse,
  runWorkflowForTicket,
  respondToFAQSuggestionComment,
  getPropertiesForSharedBug,
  suggestFeatureRequest,
  commentTranslate,
  updateComment,
};

/* eslint-disable prefer-destructuring */
import BoardFilter from 'components/BoardFilter/BoardFilter';
import BoardSearchFilter from 'components/BoardSearchFilter/BoardSearchFilter';
import BoardSortFilter from 'components/BoardSortFilter/BoardSortFilter';
import BugBoard from 'components/BugBoard/BugBoard';
import Row from 'components/LayoutComponents/RowComponent/RowComponent';
import Loading from 'components/Loading/Loading';
import TicketViewModal from 'components/Modals/TicketViewModal/TicketViewModal';
import PageContainer from 'components/PageContainer/PageContainer';
import PageContent from 'components/PageContent/PageContent';
import { PageHeadLine } from 'components/PageHeadLine/PageHeadLine';
import PrimaryButton from 'components/PrimaryButton/PrimaryButton';
import TabButton from 'components/TabButton/TabButton';
import { inject, observer } from 'mobx-react';
import { useEffect, useRef } from 'react';
import { useParams } from 'react-router';
import { BugStore } from 'stores/private/BugStore';
import { MODALSIZE, MODALTYPE, ModalStore } from 'stores/private/ModalStore';
import { ProjectStore } from 'stores/private/ProjectStore';
import './BoardView.scss';

interface BoardViewProps {
  projectStore?: ProjectStore;
  bugStore?: BugStore;
  modalStore?: ModalStore;
}

const BoardView = ({ projectStore, modalStore, bugStore }: BoardViewProps) => {
  const { shareToken } = useParams();
  const activeView = projectStore?.getViewType() || 'BOARD';
  const activeViewRef = useRef(activeView);
  activeViewRef.current = activeView;

  const customViews =
    projectStore?.getTicketViewsForFeedbackType(
      projectStore?.currentFeedbackType?.type ?? 'BUG',
    ) ?? [];

  useEffect(() => {
    if (shareToken) {
      projectStore!.openFeedbackItem({ shareToken });
    } else {
      bugStore!.clearCurrentBug();
    }
  }, [shareToken]);

  const renderContentView = () => {
    return <BugBoard />;
  };

  const render = () => {
    return (
      <>
        <PageContainer>
          <PageHeadLine
            title={projectStore?.currentFeedbackType?.menuName ?? 'Feedback'}
          >
            <Row className="ml-20">
              <div className="tab-buttons">
                <div className="tab-buttons--inner">
                  <TabButton
                    active={projectStore?.currentSelectedView === 'ALL'}
                    label="All"
                    onClick={() => {
                      projectStore?.resetCurrentSelectedView();
                    }}
                  />
                  {customViews.map((view) => (
                    <TabButton
                      active={projectStore?.currentSelectedView === view._id}
                      label={view.name}
                      onClick={() => {
                        projectStore?.setCurrentSelectedView(view);
                      }}
                      onEdit={() => {
                        modalStore?.showModal(
                          <TicketViewModal
                            ticketType={projectStore?.currentFeedbackType?.type}
                            ticketView={view}
                          />,
                          MODALSIZE.XL_NOPAD,
                        );
                      }}
                    />
                  ))}
                  <TabButton
                    icon="add"
                    onClick={() => {
                      modalStore?.showModal(
                        <TicketViewModal
                          ticketType={projectStore?.currentFeedbackType?.type}
                        />,
                        MODALSIZE.XL_NOPAD,
                      );
                    }}
                  />
                </div>
              </div>
            </Row>

            <div className="filter-bar">
              {projectStore?.currentSelectedView === 'ALL' && (
                <>
                  <div className="button-separator hide-on-mobile"></div>
                  <BoardSortFilter
                    filterOptions={[
                      {
                        title: 'Sort manually',
                        key: 'lexorank',
                      },
                      ...(projectStore?.currentFeedbackType?.type ===
                      'FEATURE_REQUEST'
                        ? [
                            {
                              title: 'By upvotes',
                              key: 'upvotesCount',
                            },
                          ]
                        : []),
                      {
                        title: 'By date',
                        key: 'createdAt',
                      },
                      {
                        title: 'By due date',
                        key: 'dueDate',
                      },
                      {
                        title: 'By read status',
                        key: 'notificationsUnread',
                      },
                      {
                        title: 'By priority',
                        key: 'priority',
                      },
                    ]}
                  />
                  <BoardFilter />
                </>
              )}
              <BoardSearchFilter />
              {projectStore?.currentFeedbackType?.type ===
                'FEATURE_REQUEST' && (
                <>
                  <div className="button-seperator hide-on-mobile"></div>

                  <PrimaryButton
                    label="Share board"
                    icon="share-from-square"
                    className="mr-10 hide-on-mobile"
                    iconSideRight={false}
                    small
                    onClick={() => {
                      modalStore!.openModal(MODALTYPE.FEATURE_BOARD_SHARE, {
                        apiKey: projectStore?.currentProject?.apiKey,
                      });
                    }}
                  />
                </>
              )}
            </div>
          </PageHeadLine>
          <PageContent hasTitle hasPadding={false}>
            <div className="board-container">{renderContentView()}</div>
          </PageContent>
        </PageContainer>
      </>
    );
  };

  if (!projectStore!.currentProject) {
    return (
      <div className="full-loader">
        <Loading />
      </div>
    );
  }

  return <div className="project-dashboard">{render()}</div>;
};

export default inject(
  'projectStore',
  'modalStore',
  'bugStore',
)(observer(BoardView));

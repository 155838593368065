import InfoBox from 'components/InfoBox/InfoBox';
import PageContainer from 'components/PageContainer/PageContainer';
import PageContent from 'components/PageContent/PageContent';
import { PageHeadLine } from 'components/PageHeadLine/PageHeadLine';
import PrimaryButton from 'components/PrimaryButton/PrimaryButton';
import { inject, observer } from 'mobx-react';
import { useEffect, useState } from 'react';
import Switch from 'react-switch';
import { OrganisationStore } from 'stores/private/OrganisationStore';
import { ProjectStore } from 'stores/private/ProjectStore';
import './AICopilotProjectSettings.scss';
import Swal from 'sweetalert2';

interface AICopilotProjectSettingsProps {
  projectStore?: ProjectStore;
  organisationStore?: OrganisationStore;
}

const AICopilotProjectSettings = ({
  projectStore,
  organisationStore,
}: AICopilotProjectSettingsProps) => {
  const project = projectStore!.currentProject;
  const [loading, setLoading] = useState(false);
  const [copilot, setCopilot] = useState(false);
  const [copilotHistory, setCopilotHistory] = useState(false);
  const [customPrompt, setCustomPrompt] = useState('');

  useEffect(() => {
    if (project) {
      setCopilot(project.copilot ?? false);
      setCopilotHistory(project.copilotHistory ?? false);
      setCustomPrompt(
        project.customPrompt ??
          'You must start your response with a greeting (Hey FIRST_NAME_IF_SET) and end it with best regards (Best,\nMY_FIRST_NAME) as the response is sent as email.',
      );
    }
  }, [project]);

  if (!project) {
    return null;
  }

  return (
    <PageContainer>
      <PageHeadLine title="AI copilot ✨" tag="BETA" />
      <PageContent hasTitle isMediumBoxed className="email-settings">
        {organisationStore?.currentOrganisation &&
          !organisationStore?.hasAIPlan() && (
            <InfoBox
              className="mb-30 info-box--error"
              icon="triangle-exclamation"
            >
              Your current plan <b>does not support</b> answer bot. Please
              contact us to subscribe to our AI features.
            </InfoBox>
          )}
        <div className="options-group">
          <div className="options-group-header">Copilot</div>
          <div className="text mt-10 mb-10">
            Copilot helps you to answer your customers' questions faster by
            suggesting responses based on the conversation context.
            <br />
            <br />
            It also helps to educate new team members by suggesting responses
            based on your knowledge base, FAQs, feature requests, and more.
          </div>
          <div className="checkbox-settings-content">
            <div className="checkbox-settings-container">
              <div className="switch-container mb-20 mt-20">
                <Switch
                  width={40}
                  onColor="#2142E7"
                  height={20}
                  checkedIcon={false}
                  uncheckedIcon={false}
                  onChange={(checked) => {
                    setCopilot(false);

                    Swal.fire({
                      title: 'Copilot will be available next week!',
                      text: 'We are working hard to bring this feature to you. Stay tuned!',
                      icon: 'info',
                      confirmButtonText: 'Got it',
                    });

                    /*projectStore!.updateProject(project!.id, {
                      copilot: checked,
                    });*/
                  }}
                  checked={copilot}
                />
                <span>
                  {copilot ? 'Copilot enabled ✅' : 'Copilot disabled'}
                </span>
              </div>
            </div>
          </div>
          {copilot && (
            <div className="checkbox-settings-content">
              <div className="checkbox-settings-container">
                <div className="switch-container mb-20 mt-20">
                  <Switch
                    width={40}
                    onColor="#2142E7"
                    height={20}
                    checkedIcon={false}
                    uncheckedIcon={false}
                    onChange={(checked) => {
                      setCopilotHistory(checked);

                      projectStore!.updateProject(project!.id, {
                        copilotHistory: checked,
                      });
                    }}
                    checked={copilotHistory}
                  />
                  <span>
                    Allow copilot to use previous conversations to suggest
                    responses.
                  </span>
                </div>
              </div>
            </div>
          )}
        </div>
        {copilot && (
          <div className="options-group">
            <div className="options-group-header">Custom prompt</div>
            <div className="text mt-10 mb-10">
              The custom prompt allows you to set custom instructions for the AI
              copilot to suggest responses based on your requirements, including
              greetings, style, and more.
            </div>
            <textarea
              className="default-textarea mb-20"
              placeholder="Custom prompt for the AI copilot"
              value={customPrompt ?? ''}
              onChange={(e) => {
                setCustomPrompt(e.target.value);
              }}
            />
            <PrimaryButton
              isLoading={loading}
              onClick={async () => {
                setLoading(true);

                try {
                  await projectStore!.updateProject(project!.id, {
                    customPrompt,
                  });
                  // eslint-disable-next-line no-empty
                } catch (exp) {}

                setLoading(false);
              }}
              label="Save"
            />
          </div>
        )}
      </PageContent>
    </PageContainer>
  );
};

export default inject(
  'projectStore',
  'organisationStore',
)(observer(AICopilotProjectSettings));

import StatisticBarChart from 'components/Charts/StatisicBarChart/StatisticBarChart';
import PrimaryButton from 'components/PrimaryButton/PrimaryButton';
import UsageLimitSettings from 'components/UsageLimitSettings/UsageLimitSettings';
import Gleap from 'gleap';
import { inject, observer } from 'mobx-react';
import moment from 'moment';
import { useEffect, useState } from 'react';
import { getAIUsage } from 'services/OrganisationHttpService';
import { ModalStore } from 'stores/private/ModalStore';
import { OrganisationStore } from 'stores/private/OrganisationStore';
import './AIUsageStats.scss';

interface AIUsageStatsProps {
  organisationStore?: OrganisationStore;
  modalStore?: ModalStore;
}

export function formatNumber(num) {
  if (typeof num === 'string') {
    num = parseFloat(num);
  }

  return num.toLocaleString('en-US', {
    style: 'decimal',
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  });
}

const AIUsageStats = ({ organisationStore, modalStore }: AIUsageStatsProps) => {
  const [loading, setLoading] = useState(true);
  const [usage, setUsage] = useState(null as any);

  const currentOrganisation = organisationStore?.currentOrganisation;
  const mauUsage = usage?.usage ?? 0;
  const dailyAIUsage: {
    date: Date;
    usage: number;
  }[] = usage?.dailyUsage ?? [];
  const aiSpendingLimit = usage?.aiSpendingLimit ?? 0;
  const maxScale = Math.max(mauUsage, aiSpendingLimit) * 1.2;
  const mauUsagePercentage = Math.ceil((mauUsage / maxScale) * 100);
  const mauUsageIncludedPercentage = Math.ceil(
    (aiSpendingLimit / maxScale) * 100,
  );
  const limitText = formatNumber(Math.round(aiSpendingLimit));

  const loadAIUsage = async (orgaId) => {
    setLoading(true);

    const usage = await getAIUsage(orgaId);
    if (usage.data) {
      setUsage(usage.data);
    }

    setLoading(false);
  };

  useEffect(() => {
    if (currentOrganisation) {
      loadAIUsage(currentOrganisation.id);
    }
  }, [currentOrganisation]);

  if (loading) {
    return <div>Loading...</div>;
  }

  return (
    <div className="ai-usage-stat">
      <div className="mau-limit-cont">
        <div className="mau-limit-progress-bar">
          <div
            className="mau-limit-progress-bar-inner"
            style={{
              minWidth: `${mauUsagePercentage}%`,
            }}
          ></div>
          <div
            className="mau-limit-included"
            style={{
              left: `${mauUsageIncludedPercentage}%`,
            }}
          >
            <div className="mau-limit-included-label">
              ${limitText} usage limit
            </div>
          </div>
        </div>
        <div className="mau-limit-info">
          ${formatNumber(mauUsage)} / ${limitText}
        </div>
      </div>
      <div className="text mt-30">
        We charge a fee of ~$0.02 for every AI message generated. The actual fee
        varies based on the actual AI token usage and averages around $0.02 per
        message.
        <br />
        <br />
        {currentOrganisation?.subscription?.subscriptionStatus === 'active' && (
          <PrimaryButton
            label="Update usage limit"
            onClick={() => {
              modalStore?.showModal(
                <UsageLimitSettings
                  title="AI usage limit"
                  value={aiSpendingLimit}
                  min={0}
                  max={2500}
                  currencyFormatter={(value) => `$${formatNumber(value)} / mo`}
                  onSave={async (value) => {
                    await organisationStore?.updateOrganisation({
                      aiSpendingLimit: value,
                    });

                    modalStore?.closeModal();
                  }}
                />,
              );
            }}
          />
        )}
        {currentOrganisation?.subscription?.subscriptionStatus !== 'active' && (
          <>
            <a
              href="#"
              onClick={() => {
                Gleap.startFeedbackFlow('z8gblk');
              }}
            >
              Contact us
            </a>{' '}
            to increase your AI token usage limit.
          </>
        )}
      </div>
      {dailyAIUsage.length > 0 && (
        <div style={{ width: '100%' }}>
          <StatisticBarChart
            title={'Daily usage breakdown'}
            formatter={(value) => `$${formatNumber(value)}`}
            chartData={{
              title: 'AI usage',
              dataSets: [
                {
                  label: 'AI usage',
                  data: dailyAIUsage.map((item) => ({
                    label: moment(item.date).format('MMM D'),
                    value: item.usage,
                  })),
                },
              ],
            }}
          />
        </div>
      )}
    </div>
  );
};

export default inject(
  'modalStore',
  'organisationStore',
)(observer(AIUsageStats));

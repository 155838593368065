import { Paddle, initializePaddle } from '@paddle/paddle-js';
import Gleap from 'gleap';
import { makeAutoObservable, runInAction } from 'mobx';
import { toast } from 'react-toastify';
import { trackEvent } from 'services/GTagHelper';
import {
  activateSubscription,
  cancelSubscription,
  getCustomer,
  getPDFForTransaction,
  getPaymentMethodUpdateTransaction,
  getSubscription,
  saveCustomer,
  updateSubscription,
} from 'services/PaddleHttpService';
import Swal from 'sweetalert2';

const pwAuth = '7e434f712595b57d8e2fd7dbf7f999cd';
const token = process.env.REACT_APP_PADDLE_TOKEN ?? '';

const calculateMonthlyRevenue = (amount, interval) => {
  // Make sure amount is a number
  try {
    amount = parseFloat(amount);

    if (interval === 'year') {
      return Math.round(amount / 12);
    }

    return amount;
  } catch (error) {
    return amount;
  }
};

export class PaddleStore {
  stores: any = {};
  customer: any = null;
  listeners: Array<Function> = [];
  subscription: any = null;
  discount: any = null;
  usedSeats: number = 0;
  transactions: any[] = [];
  loadingCustomer = false;
  savingCustomer = false;
  updateSubscriptionLoading = false;
  paddleClient?: Paddle = undefined;
  loadingSubscription = false;
  isOnboarding = false;

  constructor() {
    makeAutoObservable(this);
  }

  // Method to add a new listener
  addEventListener(callback: Function) {
    this.listeners.push(callback);
  }

  endTrialEarly = async () => {
    this.updateSubscriptionLoading = true;

    try {
      await activateSubscription(
        this.stores.organisationStore.currentOrganisation.id,
      );
      setTimeout(() => {
        window.location.reload();
      }, 4000);
      return true;
    } catch (err: any) {
      Swal.fire({
        text: 'The plan cannot be downgraded as there are too many projects or users associated with the organization. Please delete all but one project or user and try again.',
        icon: 'error',
      });

      runInAction(() => {
        this.updateSubscriptionLoading = false;
      });
    }
  };

  downloadPDFForTransaction = async (transactionId: string) => {
    const url = await getPDFForTransaction(transactionId);
    if (url) {
      var link = document.createElement('a');
      link.href = url;
      link.download = 'file.pdf';
      link.dispatchEvent(new MouseEvent('click'));
    }
  };

  saveCurrentCustomer = async () => {
    const orgaId = this.stores.organisationStore.currentOrganisation?.id;
    if (!orgaId) {
      return;
    }

    this.savingCustomer = true;

    const res = await saveCustomer(orgaId, {
      address: this.customer?.address ?? null,
      business: this.customer?.business ?? null,
    });
    if (res) {
      runInAction(() => {
        this.customer = res;
        this.savingCustomer = false;
      });

      return true;
    }

    runInAction(() => {
      this.savingCustomer = false;
    });

    return false;
  };

  getCurrentCustomer = async () => {
    this.customer = null;
    this.loadingCustomer = true;

    const orgaId = this.stores.organisationStore.currentOrganisation?.id;
    if (!orgaId) {
      return;
    }

    try {
      const res = await getCustomer(orgaId);
      if (res) {
        runInAction(() => {
          this.customer = res;
          this.loadingCustomer = false;
        });
      }
    } catch (exp) {}

    runInAction(() => {
      this.loadingCustomer = false;
    });
  };

  getCurrentSubscription = async () => {
    this.loadingSubscription = true;
    this.subscription = null;
    this.discount = null;
    this.usedSeats = 1;
    this.transactions = [];

    const orgaId = this.stores.organisationStore.currentOrganisation?.id;
    if (!orgaId) {
      return;
    }

    try {
      const res = await getSubscription(orgaId);
      if (res && res.subscription) {
        runInAction(() => {
          this.subscription = res.subscription;
          this.transactions = res.transactions;
          this.discount = res.discount;
          this.usedSeats = res.usedSeats;
        });
      }

      runInAction(() => {
        this.loadingSubscription = false;
      });
    } catch (exp) {
      runInAction(() => {
        this.loadingSubscription = false;
      });
    }
  };

  // Method to remove an existing listener
  removeEventListener(callback: Function) {
    this.listeners = this.listeners.filter((listener) => listener !== callback);
  }

  setStores(stores) {
    this.stores = stores;
  }

  // Private method to notify all listeners with the event data
  private notifyListeners(data: any) {
    for (let listener of this.listeners) {
      listener(data);
    }
  }

  public checkoutWithPrice = async (
    priceId,
    startupDiscount = false,
    isOnboarding = false,
  ) => {
    const referral = this.stores?.usersStore?.currentUser?.referral;
    const darkMode = localStorage.getItem('theme') === 'dark';
    this.isOnboarding = isOnboarding;
    this.paddleClient?.Checkout.open({
      settings: {
        displayMode: 'overlay',
        theme: darkMode ? 'dark' : 'light',
        locale: 'en',
        allowLogout: false,
        showAddTaxId: true,
        showAddDiscounts: true,
      },
      items: [
        {
          priceId: priceId,
          quantity: 1,
        },
      ],
      ...(referral || startupDiscount
        ? { discountId: 'dsc_01hywq3brwfhnrqtahny2ma6t6' }
        : {}),
      customData: {
        organizationId: this.stores.organisationStore.currentOrganisation?.id,
        ...(referral ? { referral } : {}),
      },
      customer: {
        email: this.stores.organisationStore.currentOrganisation?.email,
      },
    });
  };

  updateSubscription = async (
    items: {
      price_id: String;
      quantity: Number;
    }[],
  ) => {
    this.updateSubscriptionLoading = true;
    if (
      !this.stores.organisationStore.currentOrganisation ||
      !this.stores.organisationStore.currentOrganisation.id
    ) {
      toast.error('Something went wrong, please contact our support.');
      return;
    }

    try {
      const subscription = await updateSubscription(
        this.stores.organisationStore.currentOrganisation.id,
        items,
      );
      if (subscription) {
        runInAction(() => {
          this.updateSubscriptionLoading = false;
          this.subscription = subscription;

          setTimeout(() => {
            window.location.reload();
          }, 3000);
        });
      } else {
        runInAction(() => {
          this.updateSubscriptionLoading = false;
        });
      }
      return true;
    } catch (err: any) {
      Swal.fire({
        text: 'The plan cannot be downgraded as there are too many projects or users associated with the organization. Please delete all but one project or user and try again.',
        icon: 'error',
      });

      runInAction(() => {
        this.updateSubscriptionLoading = false;
      });
    }

    return false;
  };

  cancelSubscription = async (
    cancel: boolean,
    reason?: string,
    reasonExtended?: string,
  ) => {
    this.updateSubscriptionLoading = true;
    if (
      !this.stores.organisationStore.currentOrganisation ||
      !this.stores.organisationStore.currentOrganisation.id
    ) {
      toast.error('Something went wrong, please contact our support.');
      return;
    }

    try {
      const subscription = await cancelSubscription(
        this.stores.organisationStore.currentOrganisation.id,
        cancel,
        reason,
        reasonExtended,
      );
      if (subscription) {
        runInAction(() => {
          this.updateSubscriptionLoading = false;
          this.subscription = subscription;
        });
      } else {
        runInAction(() => {
          this.updateSubscriptionLoading = false;
        });
      }
      return true;
    } catch (err: any) {
      toast.error('You are not authorized.');
      runInAction(() => {
        this.updateSubscriptionLoading = false;
      });
    }

    return false;
  };

  public checkoutForTransaction = async (transactionId: string) => {
    try {
      if (!transactionId) {
        return;
      }

      const client = await this.getPaddleClientWithFallback();

      const darkMode = localStorage.getItem('theme') === 'dark';
      client?.Checkout.open({
        settings: {
          displayMode: 'overlay',
          theme: darkMode ? 'dark' : 'light',
          locale: 'en',
        },
        transactionId: transactionId,
      });
    } catch (exp) {
      console.log(exp);
    }
  };

  public updatePaymentDetails = async () => {
    const orgaId = this.stores.organisationStore.currentOrganisation?.id;
    if (!orgaId) {
      return;
    }

    try {
      const transactionId = await getPaymentMethodUpdateTransaction(orgaId);
      if (!transactionId) {
        return;
      }

      const darkMode = localStorage.getItem('theme') === 'dark';

      const client = await this.getPaddleClientWithFallback();
      client?.Checkout.open({
        settings: {
          displayMode: 'overlay',
          theme: darkMode ? 'dark' : 'light',
          locale: 'en',
          allowLogout: false,
        },
        transactionId: transactionId,
      });
    } catch (exp) {
      console.log(exp);
    }
  };

  onCheckoutSuccess = (data: any) => {
    if (this.isOnboarding) {
      Gleap.trackEvent('Started trial');
    }
    
    try {
      const interval = data?.items?.[0]?.billing_cycle?.interval;

      trackEvent('lead', {
        tutorial_name: this.isOnboarding ? 'onboarding' : 'billing',
        organization_id:
          this?.stores?.organisationStore?.currentOrganisation?.id ?? 'notset',
        currency: data.currency_code,
        value: calculateMonthlyRevenue(
          data?.recurring_totals?.total ?? 0,
          interval,
        ),
        discount: calculateMonthlyRevenue(
          data?.recurring_totals?.discount ?? 0,
          interval,
        ),
        items: data?.items?.map((item) => ({
          item_id: item?.price_id,
          item_name: item?.price_name,
          price: calculateMonthlyRevenue(
            item?.recurring_totals?.total,
            interval,
          ),
          quantity: item?.quantity,
          discount: calculateMonthlyRevenue(
            item?.recurring_totals?.discount ?? 0,
            interval,
          ),
          item_variant: interval === 'year' ? 'Yearly' : 'Monthly',
        })),
      });
    } catch (exp) {}

    this.stores.organisationStore?.getMyOrganisations();
    this.stores.organisationStore?.updateCurrentOrganisation();
    this.stores.projectStore?.getProjects();
    this.stores.projectStore?.getProjectsUnreadCount();
  };

  initializePaddle = async () => {
    if (this.paddleClient) {
      return;
    }

    const self = this;

    return initializePaddle({
      environment:
        process.env.REACT_APP_SANDBOX === 'dev' ? 'sandbox' : 'production',
      token: token,
      pwAuth: pwAuth,
      eventCallback: function (data: any) {
        if (data.name === 'checkout.completed') {
          setTimeout(() => {
            self.paddleClient?.Checkout.close();
            self.onCheckoutSuccess(data?.data);
          }, 3000);
        }

        self.notifyListeners(data);
      },
    }).then((paddleInstance: Paddle | undefined) => {
      if (paddleInstance) {
        runInAction(() => {
          // Don't overwrite if already set.
          if (self.paddleClient) {
            return;
          }

          self.paddleClient = paddleInstance;
        });
      }
      return paddleInstance;
    });
  };

  getPaddleClientWithFallback = async () => {
    if (!this.paddleClient) {
      return await this.initializePaddle();
    }
    return this.paddleClient;
  };

  initializePaddleSignedIn = async ({ userEmail }) => {
    const self = this;

    return initializePaddle({
      environment:
        process.env.REACT_APP_SANDBOX === 'dev' ? 'sandbox' : 'production',
      token: token,
      pwAuth: pwAuth,
      pwCustomer: {
        email: userEmail,
      },
      eventCallback: function (data: any) {
        if (data.name === 'checkout.completed') {
          setTimeout(() => {
            self.paddleClient?.Checkout.close();
            self.onCheckoutSuccess(data?.data);
          }, 3000);
        }

        self.notifyListeners(data); // Notify all listeners about the event
      },
    }).then((paddleInstance: Paddle | undefined) => {
      if (paddleInstance) {
        runInAction(() => {
          self.paddleClient = paddleInstance;
        });
      }
    });
  };
}

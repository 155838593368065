import { inject, observer } from 'mobx-react';
import React, { useEffect, useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { ProjectStore } from 'stores/private/ProjectStore';
import classNames from 'classnames';
import { ReactComponent as GleapLogo } from 'assets/icons/GleapLogo.svg';
import './MobileNavigation.scss';
import { UsersStore } from 'stores/private/UsersStore';

interface MobileNavigationProps {
  projectStore?: ProjectStore;
  usersStore?: UsersStore;
}

interface NavigationItem {
  label: string;
  path: string;
  subNavigationItems?: NavigationItem[];
  additionalNavigationItems?: NavigationItem[];
  isOpen?: boolean;
  notificationCount?: number;
}

const MobileNavigation = ({
  projectStore,
  usersStore,
}: MobileNavigationProps) => {
  // @ts-ignore
  const location = useLocation();
  const navigate = useNavigate();

  const currentProject = projectStore?.currentProject;

  const [navIsOpen, setNavIsOpen] = useState(false);
  const [toggle, setToggle] = useState(false);

  const [navigationItems, setNavigationItems] = useState(
    [] as NavigationItem[],
  );

  const sidebarCount = (projectType) => {
    var unreadCount = 0;

    for (const itemKey in projectStore?.unreadItems) {
      if (
        projectStore?.unreadItems[itemKey] &&
        projectStore?.unreadItems[itemKey].type === projectType
      ) {
        unreadCount++;
      }
    }

    return unreadCount;
  };

  useEffect(() => {
    setNavigationItems([
      ...[
        {
          label: 'Feedback',
          path: 'bugs',
          subNavigationItems: currentProject?.projectTypes
            .filter((projectType) => {
              if (projectType.type === 'RATING') {
                return false;
              }

              if (
                projectType.type === 'CRASH' &&
                !projectStore?.hasCrashReports
              ) {
                return false;
              }

              return true;
            })
            .map((projectType) => {
              return {
                path: projectType.path,
                label: projectType.menuName,
                notificationCount: sidebarCount(projectType?.type),
              };
            }),
          additionalNavigationItems: [
            { label: 'Archive & Spam', path: 'archive' },
            { label: 'Insights', path: 'insights' },
          ],
        },
        {
          label: 'Surveys',
          path: 'outbound',
        },
        {
          label: 'Contacts',
          path: 'sessions',
        },
      ],
    ]);
  }, [currentProject, projectStore?.currentTicketsData]);

  const isActive = (path, additionalPaths: any = []) => {
    if (location.pathname) {
      const parts = location.pathname.split('/');

      if (parts.length >= 3) {
        if (parts[3] === path) {
          return true;
        }

        for (let i = 0; i < additionalPaths.length; i++) {
          if (parts[3] === additionalPaths[i]) {
            return true;
          }
        }
      }

      if (
        path === '' &&
        parts.length === 3 &&
        parts[1] === 'projects' &&
        parts[2] !== 'create'
      ) {
        return true;
      }
    }

    return false;
  };

  const buildHeader = () => {
    return (
      <div className="header-container">
        <div
          className="current-project-container"
          onClick={() => {
            navigate(`/dashboard`);
            setNavIsOpen(false);
          }}
        >
          <div className="project-cover-container">
            {!currentProject && (
              <div className="logo">
                <GleapLogo
                  onClick={() => {
                    navigate('/');
                  }}
                />
              </div>
            )}
            {currentProject && !currentProject?.picture && (
              <div className="project-cover-placeholder">
                {currentProject?.name &&
                  currentProject.name.charAt(0).toLocaleUpperCase()}
              </div>
            )}
            {currentProject?.picture && (
              <img
                className="project-cover-image"
                src={currentProject?.picture}
                alt="projpicture"
              />
            )}
          </div>
          <div className="project-name-label ml-10">{currentProject?.name}</div>
        </div>
        {!navIsOpen && location.pathname !== '/dashboard' && (
          <i
            onClick={() => {
              setNavIsOpen(true);
            }}
            className="burger-icon fa-solid fa-bars"
          ></i>
        )}
        {navIsOpen && (
          <i
            onClick={() => setNavIsOpen(false)}
            className="close-icon fa-solid fa-xmark-large"
          ></i>
        )}
      </div>
    );
  };

  const buildNavigationItem = (data: NavigationItem) => {
    data.subNavigationItems = data.subNavigationItems ?? [];
    data.additionalNavigationItems = data.additionalNavigationItems ?? [];

    const mainPathIsActiveClass = classNames(
      {
        'main-navigation-item-label-active': isActive(data.path, [
          ...data.subNavigationItems!.map((item) => item.path),
          ...data.additionalNavigationItems!.map((item) => item.path),
        ]),
      },
      'main-navigation-item-label',
    );

    // Notification badge
    let mainNotificationBadge;
    const mainNotificationCount = data.subNavigationItems.reduce(
      (partialSum, a) => partialSum + (a?.notificationCount ?? 0),
      0,
    );

    if (mainNotificationCount && mainNotificationCount > 0) {
      mainNotificationBadge = (
        <div className="notifications-badge">{mainNotificationCount}</div>
      );
    }

    return (
      <div key={`main-nav-item-${data.label}`} className="navigation-item">
        <div
          className="main-navigation-item"
          onClick={() => {
            if (data.isOpen) {
              data.isOpen = false;
              setToggle(!toggle);
            } else {
              data.isOpen = true;
              setToggle(!toggle);
            }
          }}
        >
          <div
            className={mainPathIsActiveClass}
            onClick={() => {
              navigate(`/projects/${currentProject?.id}/${data.path}`);
              setNavIsOpen(false);
            }}
          >
            {data.label}
            {mainNotificationBadge}
          </div>
          {data.subNavigationItems && data.subNavigationItems!.length > 0 && (
            <>
              <>
                {!data.isOpen && (
                  <i className="main-navigation-item-arrow fa-solid fa-chevron-down"></i>
                )}
              </>
              <>
                {data.isOpen && (
                  <i className="main-navigation-item-arrow fa-solid fa-chevron-up"></i>
                )}
              </>
            </>
          )}
        </div>
        {data.isOpen &&
          data.subNavigationItems &&
          data.subNavigationItems!.length > 0 && (
            <div className="sub-navigation-items-container">
              {data.subNavigationItems!.map((item) => {
                // Notification badge
                let notificationBadge;
                if (item.notificationCount && item.notificationCount > 0) {
                  notificationBadge = (
                    <div className="notifications-badge">
                      {item.notificationCount > 10
                        ? '10+'
                        : item.notificationCount}
                    </div>
                  );
                }

                const subPathIsActiveClass = classNames(
                  {
                    'sub-navigation-item-active': isActive(item.path),
                  },
                  'sub-navigation-item',
                );

                return (
                  <>
                    <div
                      key={`nav-item-${item.label}`}
                      className={subPathIsActiveClass}
                      onClick={() => {
                        navigate(
                          `/projects/${currentProject?.id}/${item.path}`,
                        );
                        setNavIsOpen(false);
                      }}
                    >
                      {item.label}
                      {notificationBadge}
                    </div>
                  </>
                );
              })}
            </div>
          )}
        {data.isOpen &&
          data.additionalNavigationItems &&
          data.additionalNavigationItems!.length > 0 && (
            <>
              <div className="spacer-line"></div>
              {data.additionalNavigationItems!.map((item) => {
                const subPathIsActiveClass = classNames(
                  {
                    'sub-navigation-item-active': isActive(item.path),
                  },
                  'sub-navigation-item',
                );

                return (
                  <div
                    key={`nav-item-${item.label}`}
                    className={subPathIsActiveClass}
                    onClick={() => {
                      navigate(`/projects/${currentProject?.id}/${item.path}`);
                      setNavIsOpen(false);
                    }}
                  >
                    {item.label}
                  </div>
                );
              })}
            </>
          )}
      </div>
    );
  };

  if (
    location.pathname === '/' ||
    location.pathname === '/register' ||
    location.pathname === '/login'
  ) {
    return <></>;
  }

  if (!navIsOpen) {
    return (
      <>
        <div className="mobile-navigation-placeholder hide-on-desktop"></div>
        <div className="mobile-navigation-container closed-navigation">
          {buildHeader()}
        </div>
      </>
    );
  }

  return (
    <>
      <div className="mobile-navigation-placeholder hide-on-desktop"></div>
      <div className="mobile-navigation-container">
        {buildHeader()}
        <div className="scroll-wrapper">
          <div className="body-container">
            {navigationItems.map((item) => buildNavigationItem(item))}
          </div>
          <div className="footer-container">
            <div
              className="footer-item mb-20"
              onClick={() => {
                navigate('/profile/dangerzone');
                setNavIsOpen(false);
              }}
            >
              Profile
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default inject('projectStore', 'usersStore')(observer(MobileNavigation));

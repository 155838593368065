import axios from './Axios';
import { CopilotMessage } from 'models/CopilotMessage';

export const askQuestion = (
  ticketId: string,
  question: string,
): Promise<unknown> => {
  return axios.post(`/bugs/${ticketId}/copilot`, {
    question,
  });
};

export const getCopilotMessages = async (
  ticketId: string,
): Promise<CopilotMessage[]> => {
  try {
    const res = await axios.get(`/bugs/${ticketId}/copilot`);

    if (!res.data) {
      return [];
    }

    return res.data;
  } catch (err) {}

  return [];
};

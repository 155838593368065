import AIUsageStats from 'components/AIUsageStats/AIUsageStats';
import EmailUsageProgress from 'components/EmailUsageProgress/EmailUsageProgress';
import LinkButton from 'components/LinkButton/LinkButton';
import Loading from 'components/Loading/Loading';
import NoAdminCard from 'components/NoAdminCard/NoAdminCard';
import PaddleBillingDetails from 'components/PaddleBillingDetails/PaddleBillingDetails';
import PaddleInvoiceTable from 'components/PaddleInvoiceTable/PaddleInvoiceTable';
import PaddleNewPriceTable, {
  paddleBasePlans,
} from 'components/PaddleNewPriceTable/PaddleNewPriceTable';
import PaddlePriceTable, {
  currencySymbols,
} from 'components/PaddlePriceTable/PaddlePriceTable';
import PageContainer from 'components/PageContainer/PageContainer';
import PageContent from 'components/PageContent/PageContent';
import { PageHeadLine } from 'components/PageHeadLine/PageHeadLine';
import PrimaryButton from 'components/PrimaryButton/PrimaryButton';
import { TextHref } from 'components/TextHref/TextHref';
import Gleap from 'gleap';
import { inject, observer } from 'mobx-react';
import { Feature } from 'models/Enums';
import moment from 'moment';
import { useEffect } from 'react';
import { MODALTYPE, ModalStore } from 'stores/private/ModalStore';
import { OrganisationStore } from 'stores/private/OrganisationStore';
import { PaddleStore } from 'stores/private/PaddleStore';
import './Billing.scss';

interface PaddleBillingNewProps {
  organisationStore?: OrganisationStore;
  paddleStore?: PaddleStore;
  modalStore?: ModalStore;
}

export const getPaddlePlanByProductId = (priceId) => {
  for (const planKey in paddleBasePlans) {
    if (paddleBasePlans.hasOwnProperty(planKey)) {
      if (
        paddleBasePlans[planKey].monthly.trial === priceId ||
        paddleBasePlans[planKey].monthly.noTrial === priceId
      ) {
        return paddleBasePlans[planKey];
      }
      if (
        paddleBasePlans[planKey].yearly.trial === priceId ||
        paddleBasePlans[planKey].yearly.noTrial === priceId
      ) {
        return paddleBasePlans[planKey];
      }
    }
  }

  return null;
};

const getBillingInterval = (data) => {
  if (data?.price?.billing_cycle?.interval === 'year') {
    return 'yearly';
  }

  return 'monthly';
};

function calculateMonthlyPriceWithDiscount(data, discount) {
  // Safely access nested properties
  const totalCents = data?.price?.unit_price?.amount ?? 0;
  const currencyCode = data?.price?.unit_price?.currency_code;

  // Calculate the monthly total in cents
  const monthlyTotalCents = parseInt(totalCents);

  let parsedDiscount = parseInt(discount);
  if (isNaN(parsedDiscount)) {
    parsedDiscount = 0;
  }

  // Calculate the total with discount
  let discountedTotalCents = monthlyTotalCents * ((100 - parsedDiscount) / 100);

  const interval = getBillingInterval(data);
  if (interval === 'yearly') {
    discountedTotalCents = discountedTotalCents / 12;
  }

  // Convert to dollars
  const monthlyTotalDollars = (discountedTotalCents / 100).toFixed(2);

  // Format using the currencyCode
  return `${
    currencySymbols[currencyCode] || currencyCode
  }${monthlyTotalDollars}`;
}

const PaddleBillingNew = ({
  organisationStore,
  paddleStore,
  modalStore,
}: PaddleBillingNewProps) => {
  useEffect(() => {
    if (organisationStore?.currentOrganisation?.id) {
      paddleStore!.getCurrentSubscription();
      paddleStore!.getCurrentCustomer();
    }
  }, [organisationStore?.currentOrganisation?.id]);

  let search = window.location.search;
  let params = new URLSearchParams(search);
  let upgrade = params.get('upgrade');

  useEffect(() => {
    if (upgrade === 'true') {
      modalStore?.openModalSmart(MODALTYPE.MANAGE_SUBSCRIPTION);
    }
  }, []);

  if (
    !organisationStore?.currentOrganisation ||
    paddleStore?.loadingSubscription ||
    paddleStore?.loadingCustomer
  ) {
    return (
      <PageContainer>
        <PageHeadLine title="Billing" />
        <PageContent hasTitle className="billing">
          <Loading />
        </PageContent>
      </PageContainer>
    );
  }

  let mainProduct = undefined as any;
  if (
    paddleStore?.subscription?.items &&
    paddleStore?.subscription?.items.length > 0 &&
    paddleStore?.subscription?.items[0].price
  ) {
    mainProduct = paddleStore?.subscription.items[0];
  }

  const currentPlanId =
    organisationStore?.currentOrganisation?.subscription?.planID ?? 'free';
  const currentPriceId =
    organisationStore?.currentOrganisation?.subscription?.priceID ?? 'free';

  // Show pricing table.
  if (
    !mainProduct ||
    currentPlanId === 'free' ||
    !organisationStore?.currentOrganisation?.subscription?.subscriptionStatus ||
    organisationStore?.currentOrganisation?.subscription?.subscriptionStatus ===
      'canceled'
  ) {
    return (
      <PageContainer>
        <PageHeadLine title="Billing" />
        <PageContent hasTitle className="billing">
          {!organisationStore?.isOrganisationAdmin ? (
            <NoAdminCard title="Billing is" />
          ) : (
            <PaddleNewPriceTable onboarding={false} />
          )}
        </PageContent>
      </PageContainer>
    );
  }

  const currentPlan = getPaddlePlanByProductId(currentPriceId);

  const renderSubscriptionDetails = () => {
    if (
      organisationStore?.currentOrganisation?.subscription?.planID === 'free'
    ) {
      return <></>;
    }

    const renderRenewText = () => {
      if (!paddleStore?.subscription?.status) {
        return;
      }

      if (paddleStore?.subscription?.scheduled_change) {
        const actionMap = {
          cancel: 'cancels',
          pause: 'pauses',
          resume: 'resumes',
        };

        return `Your subscription ${
          actionMap[paddleStore?.subscription?.scheduled_change?.action]
        } on ${moment(
          paddleStore?.subscription?.scheduled_change?.effective_at,
        ).format('LL')}.`;
      }

      const currentPeriodEnd =
        paddleStore?.subscription?.current_billing_period?.ends_at;
      if (paddleStore?.subscription?.status === 'trialing') {
        return `Your free trial ends on ${moment(currentPeriodEnd).format(
          'LL',
        )}.`;
      }

      return `Your subscription renews on ${moment(currentPeriodEnd).format(
        'LL',
      )}.`;
    };

    const getDiscountInfo = () => {
      if (
        paddleStore?.discount &&
        paddleStore?.discount?.type === 'percentage'
      ) {
        if (paddleStore?.discount?.expires_at) {
          return (
            <div className="current-plan-discount">
              {paddleStore?.discount?.amount}% discount until{' '}
              {moment(paddleStore?.discount?.expires_at).format('LL')}
            </div>
          );
        }
        return (
          <div className="current-plan-discount">
            {paddleStore?.discount?.amount}% discount
          </div>
        );
      }
    };

    const getSubPrice = () => {
      try {
        const basePrice = calculateMonthlyPriceWithDiscount(
          mainProduct,
          paddleStore?.discount?.amount ?? 0,
        );
        return (
          <div className="current-plan-seatprice">{basePrice} per month</div>
        );
      } catch (e) {}

      return;
    };

    return (
      <>
        <div className="current-plan">{mainProduct?.price?.name}</div>
        <div className="current-plan-price-wrapper">
          {getSubPrice()}
          {getDiscountInfo()}
        </div>
        {paddleStore?.subscription?.status && (
          <div className="current-plan-interval">{renderRenewText()}</div>
        )}
        <div className="manage-invoice-buttons">
          {paddleStore?.subscription?.scheduled_change ? (
            <>
              <PrimaryButton
                isLoading={paddleStore?.updateSubscriptionLoading}
                onClick={() => {
                  paddleStore!.cancelSubscription(false);
                }}
                label="Reactivate subscription 🚀"
              />
            </>
          ) : (
            <>
              <PrimaryButton
                className="mr-10"
                icon="wand-magic-sparkles"
                onClick={() => {
                  modalStore?.openModalSmart(MODALTYPE.MANAGE_SUBSCRIPTION);
                }}
                label="Manage subscription"
              />
              <LinkButton
                onClick={() => {
                  modalStore!.openModal(MODALTYPE.CANCEL_PADDLE_PLAN);
                }}
                label="Cancel"
              />
            </>
          )}
        </div>
      </>
    );
  };

  const planIsEntitled = organisationStore?.isFeatureInPlan(Feature.SURVEYS);

  const renderContent = () => {
    if (currentPlan === 'free' || currentPlan === 'free-trial') {
      return <PaddlePriceTable />;
    }

    return (
      <>
        <div className="billing-header">
          <div className="options-group">
            <div className="options-group-header">Current subscription</div>
            <div className="current-plan-container">
              {renderSubscriptionDetails()}
            </div>
          </div>
          <div className="options-group">
            <div className="options-group-header">Billing address</div>
            <div className="current-plan-container">
              <PaddleBillingDetails customerData={paddleStore?.customer} />
              <div className="payment-card-update mt-20">
                <LinkButton
                  onClick={() => {
                    modalStore?.openModalSmart(
                      MODALTYPE.UPDATE_BILLING_ADDRESS,
                    );
                  }}
                  label="Update billing address"
                />
                <LinkButton
                  className="ml-10"
                  onClick={() => {
                    paddleStore?.updatePaymentDetails();
                  }}
                  label="Update payment method"
                />
              </div>
            </div>
          </div>
        </div>
        <div className="usage-group">
          <div className="usage-group-header">
            <div className="usage-group-header-title">
              <i className="fa-solid fa-paper-plane"></i> AI services
            </div>
            <div className="usage-group-header-actions">
              {paddleStore?.subscription?.status === 'trialing' && (
                <TextHref
                  onClick={() => {
                    modalStore?.openModalSmart(MODALTYPE.END_TRIAL_EARLY);
                  }}
                  label="Increase usage limit"
                />
              )}
            </div>
          </div>
          <AIUsageStats />
        </div>
        <div className="usage-group">
          <div className="usage-group-header">
            <div className="usage-group-header-title">
              <i className="fa-solid fa-envelope"></i> Emails
            </div>
            <div className="usage-group-header-actions">
              <TextHref
                onClick={() => {
                  if (paddleStore?.subscription?.status === 'trialing') {
                    // End trial early dialog.
                    modalStore?.openModalSmart(MODALTYPE.END_TRIAL_EARLY);
                  } else {
                    Gleap.startFeedbackFlow('acwu3');
                  }
                }}
                label="Increase usage limit"
              />
            </div>
          </div>
          <EmailUsageProgress />
        </div>
        <div className="pb-30">
          <div className="usage-group">
            <div className="usage-group-header">
              <div className="usage-group-header-title">
                <i className="fa-solid fa-receipt"></i> Invoice history
              </div>
              <div className="usage-group-header-actions"></div>
            </div>
            <PaddleInvoiceTable />
          </div>
        </div>
      </>
    );
  };

  return (
    <PageContainer>
      <PageHeadLine title="Billing" />
      <PageContent
        hasTitle
        className="billing"
        isLargeBoxed={currentPlan !== 'free'}
      >
        {!organisationStore?.isOrganisationAdmin ? (
          <NoAdminCard title="Billing is" />
        ) : (
          renderContent()
        )}
      </PageContent>
    </PageContainer>
  );
};

export default inject(
  'organisationStore',
  'paddleStore',
  'modalStore',
)(observer(PaddleBillingNew));

import classNames from 'classnames';
import { useRef, useState } from 'react';
import { isMacintosh, useClickedOutside, useEscape } from 'services/Helper';
import './DropDownDualButton.scss';
import ReactTooltip from 'react-tooltip';

interface DropDownDualButtonProps {
  label?: string;
  onClick?: any;
  tooltipLabel?: string;
  tooltipKeys?: string[];
  className?: any;
  icon?: string;
  items?: {
    label: string;
    icon?: string;
    iconType?: string;
    onClick: any;
    shortcuts?: string[];
  }[];
}

const DropDownDualButton = ({
  label,
  className,
  items,
  onClick,
  icon,
  tooltipLabel,
  tooltipKeys,
}: DropDownDualButtonProps) => {
  const [showDropdown, setShowDropdown] = useState(false);
  const linkButtonClassName = classNames(
    {
      'dual-dropdown-button': true,
      'dual-dropdown-button--opened': showDropdown,
    },
    className,
  );

  const wrapperRef = useRef(null);
  useClickedOutside(wrapperRef, () => {
    setShowDropdown(false);
  });
  useEscape(() => {
    setShowDropdown(false);
  });

  return (
    <div className={linkButtonClassName} ref={wrapperRef}>
      {!showDropdown && (
        <>
          <ReactTooltip
            id="doneButtonTooltip"
            className="infoTooltip infoTooltipButton"
            delayHide={0}
            place="bottom"
            offset={{ top: -10, left: 0 }}
            type="light"
            effect="solid"
            getContent={(content) => {
              return (
                <div className="send-key-tooltip">
                  <span>{content}</span>
                  {tooltipKeys && tooltipKeys.length > 0 && (
                    <div className="hotkey-help">
                      {isMacintosh() ? <div>⌘</div> : <div>Ctrl</div>}
                      {(tooltipKeys ?? []).map((key) => {
                        return <div>{key}</div>;
                      })}
                    </div>
                  )}
                </div>
              );
            }}
          />
          <ReactTooltip
            id="moreTicketButtonTooltip"
            className="infoTooltip infoTooltipButton"
            delayHide={0}
            place="bottom"
            offset={{ top: -10, left: 0 }}
            type="light"
            effect="solid"
            getContent={(content) => {
              return (
                <div className="send-key-tooltip">
                  <span>{content}</span>
                </div>
              );
            }}
          />
        </>
      )}
      <div className="dual-dropdown-button-wrapper">
        <div
          className="simple-button dual-dropdown-button-label"
          data-for="doneButtonTooltip"
          data-tip={tooltipLabel}
          onClick={onClick}
        >
          {icon && <i className={`fa-solid fa-${icon}`} />}
          {label}
        </div>
        <div
          className="simple-button dual-dropdown-button-toggle"
          data-for="moreTicketButtonTooltip"
          data-tip="Show ticket actions"
          onClick={() => {
            setShowDropdown(!showDropdown);
          }}
        >
          <div className="dual-dropdown-button-toggle-inner">
            <i className={`fa-solid fa-caret-down`} />
          </div>
        </div>
      </div>
      {showDropdown && (
        <div className="dropdown">
          {items &&
            items.map((item: any) => {
              return (
                <div
                  className="dropdown-item"
                  onClick={() => {
                    setShowDropdown(false);
                    item.onClick();
                  }}
                >
                  <div className="dropdown-item-icon-placeholder">
                    {item.icon && (
                      <i
                        className={`icon fa-${item.iconType ?? 'regular'} fa-${
                          item.icon
                        }`}
                      />
                    )}
                  </div>
                  <div className="dropdown-item-lable">{item.label}</div>
                  {item.shortcuts && (
                    <div className="dropdown-item-hotkeys">
                      {isMacintosh() ? <div>⌘</div> : <div>Ctrl</div>}
                      {item.shortcuts.map((shortcut: any) => {
                        return <div>{shortcut}</div>;
                      })}
                    </div>
                  )}
                </div>
              );
            })}
        </div>
      )}
    </div>
  );
};

export default DropDownDualButton;

import FactChart from 'components/Charts/FactChart/FactChart';
import StatisticLineChart from 'components/Charts/StatisticLineChart/StatisticLineChart';
import ComponentWrapper from 'components/ComponentWrapper/ComponentWrapper';
import InfoBox from 'components/InfoBox/InfoBox';
import Column from 'components/LayoutComponents/ColumnComponent/ColumnComponent';
import Grid from 'components/LayoutComponents/GridComponent/grid.component';
import Row from 'components/LayoutComponents/RowComponent/RowComponent';
import ListDataTable, {
  DataTableHeader,
} from 'components/ListDataTable/ListDataTable';
import LoadingAnimationSmall from 'components/LoadingAnimationSmall/LoadingAnimationSmall';
import { inject, observer } from 'mobx-react';
import moment from 'moment';
import { useEffect, useState } from 'react';
import { OutboundStore } from 'stores/private/OutboundStore';

interface ProjectEmailStatisticsProps {
  outboundStore?: OutboundStore;
  startDate?: Date | null;
  endDate?: Date | null;
}

export const isOutboundStillSending = (outboundRule) => {
  return (
    outboundRule?.type === 'EMAIL' &&
    outboundRule.frequencyType === 'fixed' &&
    outboundRule.sentAt &&
    moment().diff(moment(outboundRule.sentAt), 'minutes') <= 5
  );
};

const ProjectEmailStatistics = ({
  outboundStore,
  startDate,
  endDate,
}: ProjectEmailStatisticsProps) => {
  const emailOverviewData = outboundStore?.emailOverviewData;
  const emailClientUsageData = outboundStore?.emailClientUsageData;
  const emailClientBouncesData = outboundStore?.emailClientBouncesData;
  const outboundRule = outboundStore?.outboundRule;
  const stillSending = isOutboundStillSending(outboundRule);

  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    initialize(startDate, endDate);
  }, [startDate, endDate]);

  const initialize = async (start, end) => {
    setIsLoading(true);

    const formatDate = (date) => date.toISOString().split('T')[0];

    // Calculate fallback dates (30 days ago to now)
    const fallbackEndDate = new Date();
    const fallbackStartDate = new Date();
    fallbackStartDate.setDate(fallbackEndDate.getDate() - 30);

    // Use provided dates or fallbacks
    const formattedStartDate = start
      ? formatDate(start)
      : formatDate(fallbackStartDate);
    const formattedEndDate = end
      ? formatDate(end)
      : formatDate(fallbackEndDate);

    await Promise.all([
      outboundStore?.fetchAndSetEmailOverviewData({
        query: {
          startDate: formattedStartDate,
          endDate: formattedEndDate,
        },
      }),
      outboundStore?.fetchAndSetEmailClientUsageData({
        query: {
          startDate: formattedStartDate,
          endDate: formattedEndDate,
        },
      }),
      outboundStore?.fetchAndSetEmailClientBounces({
        query: {
          startDate: formattedStartDate,
          endDate: formattedEndDate,
        },
      }),
    ]);
    setIsLoading(false);
  };

  const prepareColumns = (data) => {
    if (!data?.columns) return [];

    const headers: DataTableHeader[] = data?.columns?.map((column) => {
      return {
        flex: column.key === 'description' ? 2 : 1,
        child: <div className="bold">{column.title}</div>,
      };
    });

    return headers;
  };

  const dataItemBuilder = (data: any, dataItem: any, index: number): any => {
    return {
      key: index.toString(),
      children: data?.columns?.map((column) => {
        return {
          child: <p>{dataItem[column.key]}</p>,
        };
      }),
    };
  };

  return (
    <Column>
      {stillSending && (
        <Row className="mb-20">
          <InfoBox>
            <Row>
              <LoadingAnimationSmall />
              <div style={{ marginLeft: 10 }}>
                Emails are being sent right now. Stats might take a moment to
                update.
              </div>
            </Row>
          </InfoBox>
        </Row>
      )}
      <Grid templateColumns="repeat(3, 1fr)">
        <FactChart
          isLoading={isLoading}
          infoText="Number of sent emails."
          fact={{
            title: 'Emails sent',
            value: emailOverviewData?.Sent ?? 0,
            valueUnit: '',
          }}
        />
        <FactChart
          isLoading={isLoading}
          infoText="Number of opens."
          fact={{
            title: 'Opens',
            value: emailOverviewData?.Opens ?? 0,
            valueUnit: '',
          }}
        />
        <FactChart
          isLoading={isLoading}
          infoText="Number of unique opens."
          fact={{
            title: 'Unique opens',
            value: emailOverviewData?.UniqueOpens ?? 0,
            valueUnit: '',
          }}
        />
        <FactChart
          isLoading={isLoading}
          infoText="Total number of clicks from all tracked links."
          fact={{
            title: 'Total clicks',
            value: emailOverviewData?.TotalClicks ?? 0,
            valueUnit: '',
          }}
        />
        <FactChart
          isLoading={isLoading}
          infoText="Total number of unique clicks from all tracked links."
          fact={{
            title: 'Total unique clicks',
            value: emailOverviewData?.UniqueLinksClicked ?? 0,
            valueUnit: '',
          }}
        />
        <FactChart
          isLoading={isLoading}
          infoText="CTR (Click-Through Rate) represents the percentage of recipients who clicked on a tracked link out of the total number of tracked links sent."
          fact={{
            title: 'CTR (Click-Through Rate)',
            value: emailOverviewData?.CTR ?? 0,
            valueUnit: '%',
          }}
        />
        <FactChart
          isLoading={isLoading}
          infoText="Number of bounced emails."
          fact={{
            title: 'Bounced',
            value: emailOverviewData?.Bounced ?? 0,
            valueUnit: '',
          }}
        />
        <FactChart
          isLoading={isLoading}
          infoText="Bounce rate percentage calculated by total sent."
          fact={{
            title: 'Bounce rate',
            value: emailOverviewData?.BounceRate ?? 0,
            valueUnit: '%',
          }}
        />
        <FactChart
          isLoading={isLoading}
          infoText="Number of spam complaints received."
          fact={{
            title: 'Spam complaints',
            value: emailOverviewData?.SpamComplaints ?? 0,
            valueUnit: '',
          }}
        />
        <FactChart
          isLoading={isLoading}
          infoText="Spam complaints percentage calculated by total sent."
          fact={{
            title: 'Spam complaints rate',
            value: emailOverviewData?.SpamComplaintsRate ?? 0,
            valueUnit: '%',
          }}
        />
        <FactChart
          isLoading={isLoading}
          infoText="Number of SMTP errors."
          fact={{
            title: 'SMTP errors',
            value: emailOverviewData?.SMTPApiErrors ?? 0,
            valueUnit: '%',
          }}
        />
      </Grid>
      <StatisticLineChart
        isLoading={isLoading}
        chartData={emailClientUsageData}
      />
      <ComponentWrapper title="Bounces" className="statistic-bar-chart">
        <ListDataTable
          columns={prepareColumns(emailClientBouncesData)}
          data={emailClientBouncesData?.data ?? []}
          dataTableItemBuilder={(dataItem, index) => {
            return dataItemBuilder(emailClientBouncesData, dataItem, index);
          }}
          isLoading={isLoading}
          noDataMessage="No data to display"
          isScrollable
        />
      </ComponentWrapper>
    </Column>
  );
};

export default inject('outboundStore')(observer(ProjectEmailStatistics));

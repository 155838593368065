import { Organisation } from 'models/Organisation';
import axios from './Axios';

const getOrganisation = (organisationID): Promise<any> => {
  return axios.get(`/organisations/${organisationID}`);
};

const getOrganisations = (): Promise<any> => {
  return axios.get(`/organisations/my`);
};

const createOrganisation = (organisation: Organisation): Promise<any> => {
  return axios.post('/organisations', organisation);
};

const getVoucherOfCurrentOrganisation = (organisationID): Promise<any> => {
  return axios.get(
    `/organisations/${organisationID}/createreferralpromotioncode`,
  );
};

const getAIUsage = (organisationID): Promise<any> => {
  return axios.get(`/organisations/${organisationID}/aiusage`);
};

const getMyIssues = (filter: string): Promise<any> => {
  return axios.get(`/bugs/my?filter=${filter}`);
};

const leaveOrganisation = (organisationID) => {
  return axios.delete(`/organisations/${organisationID}/leave`);
};

const updateOrganisation = (organisationID, organisation: Organisation) => {
  return axios.put(`/organisations/${organisationID}`, organisation);
};

const startFreeOrgaTrial = (organisationID) => {
  return axios.put(`/organisations/${organisationID}/startfreetrial`, {});
};

const deleteOrganisation = (organisationID) => {
  return axios.delete(`/organisations/${organisationID}`);
};

const inviteTeam = (
  id: string,
  emails: string[],
  token: string,
): Promise<any> => {
  return axios.post(`/organisations/${id}/invitations`, {
    emails,
    token,
  });
};

const getInvitedTeamMembers = (organisationID) => {
  return axios.get(`invitations/${organisationID}`);
};

const getInvitedOrgaTeamMembers = (organisationID) => {
  return axios.get(`/organisations/${organisationID}/invitations/`);
};

const getMyInvitations = () => {
  return axios.get('/invitations');
};

const deleteInvitation = (invitationsID) => {
  return axios.delete(`/invitations/${invitationsID}`);
};

const answerInvitation = (id, accept) => {
  return axios.put(`/invitations/${id}/`, {
    accept,
  });
};

const getOrgaUsers = (organisationID) => {
  return axios.get(`/organisations/${organisationID}/users`);
};

const setUserRole = (organisationID, userID: String, role: String) => {
  return axios.put(`/organisations/${organisationID}/users/${userID}`, {
    role,
  });
};

const deleteUserFromOrga = (organisationID, userID: String) => {
  return axios.delete(`/organisations/${organisationID}/users/${userID}`);
};

const createSetupIntent = (organisationID) => {
  return axios.post(`/organisations/${organisationID}/createSetupIntent`);
};

const subscribeToPlan = (organisationID, planID, discountCode) => {
  return axios.post(`/organisations/${organisationID}/subscribeToPlan`, {
    planID,
    discountCode,
  });
};

export {
  createOrganisation,
  updateOrganisation,
  deleteOrganisation,
  inviteTeam,
  getMyInvitations,
  getInvitedTeamMembers,
  getInvitedOrgaTeamMembers,
  answerInvitation,
  getOrganisation,
  getOrgaUsers,
  leaveOrganisation,
  setUserRole,
  deleteUserFromOrga,
  getOrganisations,
  getVoucherOfCurrentOrganisation,
  deleteInvitation,
  createSetupIntent,
  subscribeToPlan,
  getMyIssues,
  startFreeOrgaTrial,
  getAIUsage,
};

import classNames from 'classnames';
import './DropDownButton.scss';
import LoadingAnimationSmall from 'components/LoadingAnimationSmall/LoadingAnimationSmall';
import LinkButton from 'components/LinkButton/LinkButton';
import { useRef, useState } from 'react';
import { useClickedOutside, useEscape } from 'services/Helper';

interface DropDownButtonProps {
  label?: string;
  icon?: string;
  className?: any;
  items?: {
    label: string;
    icon?: string;
    iconType?: string;
    onClick: any;
  }[];
}

const DropDownButton = ({
  label,
  className,
  items,
}: DropDownButtonProps) => {
  const [showDropdown, setShowDropdown] = useState(false);
  const linkButtonClassName = classNames(
    {
      'dropdown-button': true,
      'dropdown-button--opened': showDropdown,
    },
    className,
  );

  const wrapperRef = useRef(null);
  useClickedOutside(wrapperRef, () => {
    setShowDropdown(false);
  });
  useEscape(() => {
    setShowDropdown(false);
  });

  return (
    <div className={linkButtonClassName} ref={wrapperRef}>
      <LinkButton
        label={label}
        className="mr-10"
        iconSideRight={true}
        icon="caret-down"
        faIconType='solid'
        onClick={() => {
          setShowDropdown(!showDropdown);
        }}
      />
      {showDropdown && (
        <div className="dropdown">
          {items && items.map((item: any) => {
            return (
              <div className="dropdown-item" onClick={() => {
                setShowDropdown(false);
                item.onClick();
              }}>
                {item.icon && <i className={`icon fa-${item.iconType ?? 'regular'} fa-${item.icon}`} />}
                {item.label}
              </div>
            );
          })}
        </div>
      )}
    </div>
  );
};

export default DropDownButton;

import CreateNewProject from 'assets/CreateNewProject.png';
import IssueCard from 'components/BugReportCard/IssueCard';
import { HeadLine } from 'components/HeadLine/HeadLine';
import InvitationCard from 'components/InvitationCard/InvitationCard';
import LinkButton from 'components/LinkButton/LinkButton';
import Loading from 'components/Loading/Loading';
import LoadingAnimationSmall from 'components/LoadingAnimationSmall/LoadingAnimationSmall';
import PrimaryButton from 'components/PrimaryButton/PrimaryButton';
import ProjectCard from 'components/ProjectCard/ProjectCard';
import TabButton from 'components/TabButton/TabButton';
import UserAvatar from 'components/UserAvatar/UserAvatar';
import Gleap from 'gleap';
import { inject, observer } from 'mobx-react';
import moment from 'moment';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router';
import { checkIfIsOrgaUser } from 'services/CheckIfUserIsAdmin';
import { MODALTYPE, ModalStore } from 'stores/private/ModalStore';
import { OrganisationStore } from 'stores/private/OrganisationStore';
import { ProjectStore } from 'stores/private/ProjectStore';
import { UsersStore } from 'stores/private/UsersStore';
import Swal from 'sweetalert2';
import './Dashboard.scss';

interface DashboardProps {
  usersStore?: UsersStore;
  projectStore?: ProjectStore;
  modalStore?: ModalStore;
  organisationStore?: OrganisationStore;
}

function Dashboard({
  usersStore,
  projectStore,
  modalStore,
  organisationStore,
}: DashboardProps) {
  const navigate = useNavigate();
  const projects = projectStore?.projects;
  const [onboardingShown, setOnboardingShown] = useState(false);
  const [projectOrgas, setProjectOrgas] = useState({} as any);
  const [reloadingPage, setReloadingPage] = useState(false);
  const [showOutreachBanner, setShowOutreachBanner] = useState(false);

  let initialMyIssuesFilter = 'all';
  try {
    initialMyIssuesFilter = localStorage.getItem('myIssuesFilter') ?? 'all';
  } catch (error) {}

  const [myIssuesFilter, setMyIssuesFilter] = useState(initialMyIssuesFilter);

  const isEvening = new Date().getHours() > 17;
  const welcomeSalut = isEvening ? 'Good Evening' : 'Hey';
  const welcomeSalutIcon = isEvening ? '🌜' : '👋';

  useEffect(() => {
    if (usersStore?.currentUser && usersStore?.currentUser?.id) {
      if (usersStore) {
        usersStore.findInvitations();
      }
    }

    if (usersStore?.currentUser) {
      const userCreated = moment(usersStore?.currentUser?.createdAt);
      const now = moment();

      // Only proceed when user is older than 16 day
      if (now.diff(userCreated, 'days') < 16) {
        return;
      }

      try {
        const referralBannerClosed = localStorage.getItem(
          'referralBannerClosed',
        );
        if (referralBannerClosed) {
          const referralBannerClosedDate = moment(referralBannerClosed);
          const now = moment();
          if (now.diff(referralBannerClosedDate, 'days') > 30) {
            setShowOutreachBanner(true);
          }
        } else {
          setShowOutreachBanner(true);
        }
      } catch (error) {}
    }
  }, [usersStore?.currentUser]);

  useEffect(() => {
    projectStore!.clearCurrentProject();

    if (projectStore) {
      projectStore.getProjectsUnreadCount();
    }
  }, []);

  useEffect(() => {
    usersStore!.loadCello(usersStore!.currentUser!);

    return () => {
      usersStore!.unloadCello();
    };
  }, []);

  useEffect(() => {
    if (organisationStore && myIssuesFilter) {
      organisationStore.loadMyIssues(myIssuesFilter);
    }
  }, [myIssuesFilter]);

  useEffect(() => {
    if (projects) {
      const projectOrgasInternal = {};
      for (let i = 0; i < projects?.length; i++) {
        const project = projects[i];
        const projectOrgaKey = project.organisation?.id ?? 'UNDEFINED';
        if (!projectOrgasInternal[projectOrgaKey]) {
          projectOrgasInternal[projectOrgaKey] = {
            organisation: project.organisation,
            projects: [],
          };
        }

        projectOrgasInternal[projectOrgaKey].projects.push(project);
      }

      if (organisationStore?.organisations) {
        for (let i = 0; i < organisationStore?.organisations.length; i++) {
          const organisation = organisationStore?.organisations[i];
          const projectOrgaKey = organisation.id ?? 'UNDEFINED';
          if (!projectOrgasInternal[projectOrgaKey]) {
            projectOrgasInternal[projectOrgaKey] = {
              organisation,
              projects: [],
            };
          }
        }
      }

      setProjectOrgas(projectOrgasInternal);
    }
  }, [projects, organisationStore?.organisations]);

  useEffect(() => {
    if (
      !onboardingShown &&
      usersStore?.currentUser &&
      (!usersStore?.currentUser?.completedOnboarding ||
        !usersStore?.currentUser?.confirmed)
    ) {
      localStorage.setItem('upsell-modal-shown', new Date().toISOString());

      setOnboardingShown(true);
      modalStore!.openModal(MODALTYPE.ONBOARDING);
    }
  }, [usersStore?.currentUser, onboardingShown]);

  const setMyIssuesFilterAndReload = (filter: string) => {
    setMyIssuesFilter(filter);

    try {
      localStorage.setItem('myIssuesFilter', filter);
    } catch (error) {}
  };

  const renderMyIssues = () => {
    return (
      <div className="main-tasks">
        <div className="my-tasks-header">
          <UserAvatar
            email={usersStore?.currentUser?.email}
            imageUrl={usersStore?.currentUser?.profileImageUrl}
          />
          <div className="my-tasks-header-title">My tickets</div>
          <div className="my-tasks-filter tab-buttons">
            <div className="tab-buttons--inner">
              <TabButton
                active={myIssuesFilter === 'all'}
                label="My"
                onClick={() => {
                  setMyIssuesFilterAndReload('all');
                }}
              />
              <TabButton
                active={myIssuesFilter === 'unread'}
                icon="eye-slash"
                label="Unread"
                onClick={() => {
                  setMyIssuesFilterAndReload('unread');
                }}
              />
              <TabButton
                active={myIssuesFilter === 'overdue'}
                icon="circle-exclamation"
                label="Overdue"
                onClick={() => {
                  setMyIssuesFilterAndReload('overdue');
                }}
              />
              <TabButton
                active={myIssuesFilter === 'mentioned'}
                icon="at"
                label="Mentioned"
                onClick={() => {
                  setMyIssuesFilterAndReload('mentioned');
                }}
              />
            </div>
          </div>
        </div>
        <div className="my-tasks-list">
          {organisationStore?.loadingMyIssues ? (
            <div className="centered p-20">
              <LoadingAnimationSmall />
            </div>
          ) : (
            <>
              {organisationStore?.myIssues &&
                organisationStore?.myIssues.length === 0 && (
                  <div className="centered p-20">
                    <b className="title-text">✅ Looking good!</b>
                  </div>
                )}
              {organisationStore?.myIssues &&
                organisationStore?.myIssues.map((myIssue: any) => {
                  return <IssueCard key={myIssue.id} ticket={myIssue} />;
                })}
            </>
          )}
        </div>
      </div>
    );
  };

  const renderProjects = () => {
    if (usersStore!.invitations.length === 0 && projects?.length === 0) {
      return (
        <>
          <div className="dashboard-headline">Projects</div>
          <div className="no-projects-container">
            <img
              src={CreateNewProject}
              alt="No projects created yet"
              className="no-projects-image"
            />
            <HeadLine
              title="No projects yet"
              subtitle="Create a project to get started."
            />
            <PrimaryButton
              icon="plus"
              className="hide-on-mobile"
              label="Create project"
              onClick={() => {
                navigate(`/projects/create/`);
              }}
            />
          </div>
        </>
      );
    }

    return Object.keys(projectOrgas).map((projectOrgaKey) => {
      const projectOrga = projectOrgas[projectOrgaKey];
      const projects = projectOrga.projects ?? [];

      const isAdmin = checkIfIsOrgaUser(
        usersStore?.currentUser,
        projectOrga.organisation,
      );

      const renderTrialBanner = () => {
        if (
          projectOrga.organisation.subscription?.planID === 'free-trial' &&
          projectOrga.organisation?.freeTrialUntil
        ) {
          return (
            <div className="trial-tag">
              <div className="trial-tag-content">
                Free trial until{' '}
                {moment(projectOrga.organisation?.freeTrialUntil).format(
                  'MMM Do',
                )}{' '}
                ✨
              </div>
              <LinkButton
                label="Upgrade now"
                onClick={() => {
                  navigate(
                    `/organization/${projectOrga.organisation.id}/billing`,
                  );
                }}
              />
            </div>
          );
        }

        if (
          projectOrga.organisation.subscription?.subscriptionStatus ===
          'trialing'
        ) {
          return (
            <div className="trial-tag">
              <div className="trial-tag-content">Free trial ✨</div>
              <LinkButton
                label="Manage"
                onClick={() => {
                  navigate(
                    `/organization/${projectOrga.organisation.id}/billing`,
                  );
                }}
              />
            </div>
          );
        }

        if (
          projectOrga.organisation.subscription?.planID === 'free' ||
          !projectOrga.organisation.subscription?.planID
        ) {
          return (
            <div className="trial-tag">
              <div className="trial-tag-content">Your trial expired</div>
              <LinkButton
                label="Upgrade now 🚀"
                onClick={() => {
                  navigate(
                    `/organization/${projectOrga.organisation.id}/billing`,
                  );
                }}
              />
            </div>
          );
        }

        return null;
      };

      const renderEmailUsageDataBanner = (organisation) => {
        if (
          organisation?.usageData?.emails >
          organisation?.emailSendingLimit * 0.95
        ) {
          return (
            <div className="usage-banner">
              <div className="usage-banner-content">
                🚨 You sent {organisation?.usageData?.emails?.toLocaleString()}{' '}
                emails this month. You're close to your limit of{' '}
                {organisation?.emailSendingLimit?.toLocaleString()} emails.
              </div>
              <PrimaryButton
                label="Upgrade now 🚀"
                onClick={() => {
                  navigate(
                    `/organization/${projectOrga.organisation.id}/billing`,
                  );
                }}
              />
            </div>
          );
        }

        return null;
      };

      /*const renderAITokenUsageDataBanner = (organisation) => {
        if (
          organisation?.usageData?.tokens >
          organisation?.aiTokenLimit * 0.95
        ) {
          return (
            <div className="usage-banner">
              <div className="usage-banner-content">
                🚨 You used{' '}
                {Math.round(organisation?.usageData?.tokens)?.toLocaleString()}{' '}
                AI tokens this month. You're close to your limit of{' '}
                {organisation?.aiTokenLimit?.toLocaleString()} AI tokens.
              </div>
              <PrimaryButton
                label="Upgrade now 🚀"
                onClick={() => {
                  navigate(
                    `/organization/${projectOrga.organisation.id}/billing`,
                  );
                }}
              />
            </div>
          );
        }

        return null;
      };*/

      return (
        <div className="organisation-group" key={projectOrga.organisation.id}>
          <div className="organisation-header">
            <div className="organisation-header-headline">
              {projectOrga.organisation.name}
            </div>
            <div className="organisation-header-free-trial">
              {renderTrialBanner()}
            </div>
            <div className="organisation-header-actions">
              {isAdmin && (
                <>
                  <LinkButton
                    label="Billing"
                    iconSideRight={false}
                    icon="credit-card"
                    onClick={() => {
                      navigate(
                        `/organization/${projectOrga.organisation.id}/billing`,
                      );
                    }}
                  />
                  <LinkButton
                    label="Team"
                    iconSideRight={false}
                    icon="user-plus"
                    onClick={() => {
                      navigate(
                        `/organization/${projectOrga.organisation.id}/team`,
                      );
                    }}
                  />
                  <LinkButton
                    label="Settings"
                    iconSideRight={false}
                    icon="gear"
                    onClick={() => {
                      navigate(`/organization/${projectOrga.organisation.id}/`);
                    }}
                  />
                </>
              )}
            </div>
          </div>
          {renderEmailUsageDataBanner(projectOrga?.organisation)}
          <div className="project-cards">
            {projects.map((project) => {
              return (
                <ProjectCard
                  organization={projectOrga.organisation}
                  key={project.id}
                  project={project}
                />
              );
            })}
            <LinkButton
              iconSideRight={false}
              label="Create project"
              icon="plus"
              className="create-project-button hide-on-mobile"
              onClick={() => {
                if (isAdmin) {
                  navigate(
                    `/projects/create?orga=${projectOrga.organisation.id}`,
                  );
                } else {
                  Swal.fire({
                    text: 'You must have organization admin permissions to create projects.',
                    showCancelButton: false,
                    confirmButtonText: `Ok`,
                  });
                }
              }}
            />
          </div>
        </div>
      );
    });
  };

  const renderContentCards = () => {
    if (projectStore!.loadingProjects) {
      return (
        <div className="project-loading">
          <Loading />
        </div>
      );
    }

    return (
      <>
        <div className="dashboard-content fancy-gradient-bg">
          <div className="organisations-container">
            <div className="dash-header">
              <div className="dash-header-title">{`${welcomeSalut} ${usersStore?.currentUser?.firstName} ${welcomeSalutIcon}`}</div>
              <div className="dash-header-sub">
                Welcome to Gleap! We're here to help. If you have any questions
                or need assistance, don't hesitate to{' '}
                <a
                  href="#"
                  onClick={() => {
                    Gleap.open();
                  }}
                >
                  reach out
                </a>
                .
              </div>
            </div>
            <div className="my-issues-container">{renderMyIssues()}</div>
            {usersStore!.invitations.length > 0 && (
              <>
                <div className="dashboard-headline">Pending invitations</div>
                <div className="invitations-list project-cards mb-30">
                  {usersStore!.invitations.map((invitation, key) => {
                    return <InvitationCard invitation={invitation} key={key} />;
                  })}
                </div>
              </>
            )}
            {renderProjects()}
          </div>
        </div>
      </>
    );
  };

  if (projectStore!.loadingFailed) {
    return (
      <div className="loading-failed">
        <div className="title mb-10">Connection lost ❤️‍🩹</div>
        <div className="text">
          It looks like you're not connected to the internet.
          <br />
          Give it another try or reach out to us for assistance.
        </div>
        <PrimaryButton
          isLoading={reloadingPage}
          label="Reload app"
          onClick={() => {
            setReloadingPage(true);
            window.location.reload();
          }}
        />
      </div>
    );
  }

  return (
    <>
      <div className="onboarding-welcome-container">{renderContentCards()}</div>
    </>
  );
}

export default inject(
  'usersStore',
  'projectStore',
  'organisationStore',
  'modalStore',
)(observer(Dashboard));

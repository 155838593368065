export const staticTranslationsGrouped = [
  {
    name: 'General',
    icon: 'general',
    translations: [
      {
        label: 'Next',
        key: 'next',
      },
      {
        label: 'Reply',
        key: 'Reply',
      },
      {
        label: 'Previous',
        key: 'previous',
      },
      {
        label: 'Done',
        key: 'done',
      },
      {
        label: 'Wrong API key',
        key: 'wrongApiKey',
      },
      {
        label: 'Retry',
        key: 'retry',
      },
      {
        label: 'Home',
        key: 'homeLabel',
      },
      {
        label: 'Mute',
        key: 'mute',
      },
      {
        label: 'Unmute',
        key: 'unmute',
      },
      {
        label: 'Browser not supported',
        key: 'browserNotSupported',
      },
      {
        label: 'Choose one...',
        key: 'chooseOption',
      },
      {
        label: 'Choose a file or drag it here.',
        key: 'chooseFile',
      },
      {
        label: 'Load more',
        key: 'loadMore',
      },
      {
        label: 'Undo',
        key: 'undo',
      },
      {
        label: 'Submit',
        key: 'submit',
      },
      {
        label: 'Pick a color',
        key: 'pickAColor',
      },
      {
        label: 'Scheduled',
        key: 'scheduled',
      },
      {
        label: 'E-mail',
        key: 'email',
      },
      {
        label: 'Your e-mail',
        key: 'yourEmail',
      },
      {
        label: 'Unsubscribe from these emails',
        key: 'unsubscribeFromEmails',
      },
    ],
  },
  {
    name: 'Kai',
    icon: 'ai',
    translations: [
      {
        label: 'Gathering information...',
        key: 'kaiGatheringInformation',
      },
      {
        label: 'Report a bug',
        key: 'kaiReportABug',
      },
      {
        label:
          "Unfortunately, I'm not able to answer your question at the moment.",
        key: 'kaiIssue',
      },
      {
        label: 'Answer',
        key: 'kaiAnswerTag',
      },
      {
        label: 'Sources',
        key: 'kaiSources',
      },
      {
        label: 'Submit my idea',
        key: 'kaiSubmitIdea',
      },
      {
        label: 'Back to Home',
        key: 'kaiSubmitIdeaDone',
      },
    ],
  },
  {
    name: 'Feedback & Bug Reporting',
    icon: 'feedback',
    translations: [
      {
        label: 'Mark the bug',
        key: 'markTheBug',
      },
      {
        label: 'Record screen',
        key: 'recordScreen',
      },
      {
        label: 'Click and drag to mark the bug',
        key: 'clickAndDragToMarkTheBug',
      },
      {
        label: 'Draw on the screen to mark the bug',
        key: 'drawOnTheScreenToMarkTheBug',
      },
      {
        label: 'Record your screen to showcase the bug',
        key: 'recordYourScreenToShowcaseTheBug',
      },
      {
        label: 'Stop recording',
        key: 'stopRecording',
      },
      {
        label: 'Start recording',
        key: 'startRecording',
      },
      {
        label: 'Screen recording',
        key: 'screenRecording',
      },
      {
        label: 'Screenshot',
        key: 'screenshot',
      },
      {
        label:
          'You denied access to screen sharing. Please turn it on in your browser settings.',
        key: 'accessToScreenSharingDenied',
      },
    ],
  },
  {
    name: 'Communication',
    icon: 'communication',
    translations: [
      {
        label: 'Hi (Greeting)',
        key: 'hi',
      },
      {
        label: 'I read and accept the ',
        key: 'ppolicyIRead',
      },
      {
        label: 'privacy policy',
        key: 'ppolicy',
      },
      {
        label: 'Write a reply...',
        key: 'writeReply',
      },
      {
        label: 'No messages yet',
        key: 'noMessages',
      },
      {
        label: 'Send us a message',
        key: 'sendMessage',
      },
      {
        label: 'No news published yet.',
        key: 'noNews',
      },
      {
        label:
          'We are currently offline but will get back to you as soon as possible.',
        key: 'weAreOffline',
      },
      {
        label: 'We are back',
        key: 'weAreBack',
      },
      {
        label:
          'We found existing feature requests that might align with your idea. Upvote the ones you like or go ahead and submit your own idea.',
        key: 'suggestFeatureRequest',
      },
      {
        label: 'Your conversation has ended.',
        key: 'conversationEnded',
      },
      {
        label: 'Search...',
        key: 'search',
      },
      {
        label: 'Request a feature',
        key: 'requestFeature',
      },
      {
        label: 'Our bot will reply instantly',
        key: 'botReplyTime',
      },
      {
        label: 'Submitted',
        key: 'submitted',
      },
      {
        label: 'In progress',
        key: 'inProgress',
      },
      {
        label: 'Resolved',
        key: 'resolved',
      },
      {
        label: 'No answer found.',
        key: 'noAnswer',
      },
    ],
  },
  {
    name: 'Public Roadmap',
    icon: 'roadmap',
    translations: [
      {
        label: 'Feature request',
        key: 'featureRequests',
      },
      {
        label: 'Roadmap',
        key: 'roadmap',
      },
      {
        label: 'News',
        key: 'news',
      },
      {
        label: 'Not done',
        key: 'notDone',
      },
      {
        label: 'Released',
        key: 'released',
      },
      {
        label: 'All',
        key: 'all',
      },
      {
        label: 'Comments',
        key: 'comments',
      },
      {
        label: 'Updates',
        key: 'updates',
      },
      {
        label: 'Write a comment',
        key: 'writeComment',
      },
      {
        label: 'Write a comment...',
        key: 'writeCommentPlaceholder',
      },
      {
        label: 'Send',
        key: 'send',
      },
      {
        label: 'No comments yet',
        key: 'noComments',
      },
      {
        label: 'No updates yet',
        key: 'noUpdates',
      },
      {
        label: 'Kickstart the conversation by writing a comment.',
        key: 'kickstartTheConversation',
      },
      {
        label: 'We will post updates on this feature request here.',
        key: 'weWillPostUpdates',
      },
      {
        label: 'Nothing found',
        key: 'nothingFoundTitle',
      },
      {
        label: 'Try to reset the filters to see more feature requests.',
        key: 'nothingFoundDescription',
      },
      {
        label: 'Nothing unread, good job 💪',
        key: 'nothingUnseenTitle',
      },
      {
        label: 'There are no unread feature requests waiting for you.',
        key: 'nothingUnseenDescription',
      },
      {
        label: 'No feature requests yet',
        key: 'noFeatureRequestsTitle',
      },
      {
        label:
          'New feature requests will show up, as soon as they get reported.',
        key: 'noFeatureRequestsDescription',
      },
      {
        label: 'Try to reset the filters or',
        key: 'resetFiltersOr',
      },
      {
        label: 'submit a new feature request',
        key: 'submitNewFeatureRequrest',
      },
      {
        label: 'Sort: Most votes',
        key: 'featureRequestSort',
      },
      {
        label: 'Sort: Recent',
        key: 'featureRequestSortDate',
      },
      {
        label: 'Sort: Random',
        key: 'featureRequestSortRandom',
      },
    ],
  },
  {
    name: 'Checklist',
    icon: 'checklist',
    translations: [
      {
        label: '{a} of {b} done',
        key: 'taskProgress',
      },
      {
        label: 'About {a} minutes left',
        key: 'aboutMinutes',
      },
      {
        label: 'Mark as done',
        key: 'markAsDone',
      },
      {
        label: 'Next step',
        key: 'nextStep',
      },
    ],
  },
];

export const getDefaultTranslation = (obj: any, lang: string) => {
  if (obj.localized && Object.keys(obj.localized).length > 0) {
    return (
      obj.localized[lang] ||
      obj.localized.en ||
      obj.localized[Object.keys(obj.localized)[0]]
    );
  }

  return '--';
};

import React from 'react';
import './StartupDiscountPage.scss';
import PrimaryButton from 'components/PrimaryButton/PrimaryButton';

interface StartupDiscountPageProps {
  onApplyDiscount: (applied: boolean) => void;
}

const StartupDiscountPage: React.FC<StartupDiscountPageProps> = ({
  onApplyDiscount,
}) => {
  return (
    <div className="startup-discount-page">
      <div className="startup-discount-container">
        <div className="startup-discount-content">
          <h1 className="startup-discount-title">Congratulations! 🎉</h1>
          <p className="startup-discount-subtitle">
            You're eligible for our exclusive startup discount
          </p>
          <div className="startup-discount-info">
            <div className="discount-badge">
              Welcome to the Gleap startup program!
            </div>
            <p className="discount-description">
              We’re excited to help you leverage the importance of customer
              feedback in driving your startup’s success. As part of our
              program, you’re eligible for an exclusive offer: 50% off on our
              plans for three years.
            </p>
          </div>
          <div className="startup-discount-benefits">
            <span className="benefit-badge">
              <i className="fa fa-solid fa-check" /> save 50 % off for 3 years
            </span>
            <span className="benefit-badge">
              <i className="fa fa-solid fa-check" /> drive customer engagement
            </span>
            <span className="benefit-badge">
              <i className="fa fa-solid fa-check" /> double your impact
            </span>
            <span className="benefit-badge">
              <i className="fa fa-solid fa-check" /> grow 2x faster
            </span>
          </div>
          <PrimaryButton
            className="apply-discount-button"
            label="Apply 50% discount"
            onClick={() => onApplyDiscount(true)}
          />
          <span className="cursor-focus" onClick={() => onApplyDiscount(false)}>
            Skip
          </span>
        </div>
      </div>
    </div>
  );
};

export default StartupDiscountPage;

import axios from './Axios';

const getBarChartStatisic = async ({
  projectId,
  query,
  signal,
}: {
  projectId: any;
  query: any;
  signal?: any;
}): Promise<any> => {
  if (!projectId) {
    return null;
  }

  if (signal) {
    try {
      const response = await axios.get(
        `/projects/${projectId}/statistics/bar-chart`,
        {
          params: query,
          signal: signal,
        },
      );
      return response.data;
    } catch (error) {
      if ((axios as any).isCancel(error)) {
        throw new Error('Request canceled');
      } else {
        throw error;
      }
    }
  }
  return (
    await axios.get(`/projects/${projectId}/statistics/bar-chart`, {
      params: query,
    })
  ).data;
};

const getFactStatistic = async ({
  projectId,
  query,
  signal,
}: {
  projectId: any;
  query: any;
  signal?: any;
}): Promise<any> => {
  if (!projectId) {
    return null;
  }

  if (signal) {
    try {
      const response = await axios.get(
        `/projects/${projectId}/statistics/facts`,
        {
          params: query,
          signal: signal,
        },
      );
      return response.data;
    } catch (error) {
      if ((axios as any).isCancel(error)) {
        throw new Error('Request canceled');
      } else {
        throw error;
      }
    }
  }

  return (
    await axios.get(`/projects/${projectId}/statistics/facts`, {
      params: query,
    })
  ).data;
};

const getListStatistic = async ({ projectId, query }): Promise<any> => {
  if (!projectId) {
    return null;
  }

  return (
    await axios.get(`/projects/${projectId}/statistics/lists`, {
      params: query,
    })
  ).data;
};

const getListStatisticExport = async ({ projectId, query }): Promise<any> => {
  return await axios.get(`/projects/${projectId}/statistics/lists/export`, {
    params: query,
  });
};

const getContactsExport = async ({ projectId }): Promise<any> => {
  return await axios.get(`/projects/${projectId}/contacts/export`);
};

const getHeatmapStatistic = async ({ projectId, query }): Promise<any> => {
  return (
    await axios.get(`/projects/${projectId}/statistics/heatmap`, {
      params: query,
    })
  ).data;
};

const getEmailOverviewStatistic = async ({
  projectId,
  query,
}): Promise<any> => {
  return axios.get(`/projects/${projectId}/statistics/email-overview`, {
    params: query,
  });
};

const getEmailClientUsageStatistic = async ({
  projectId,
  query,
}): Promise<any> => {
  return axios.get(`/projects/${projectId}/statistics/email-client-usage`, {
    params: query,
  });
};

const getEmailClientBounces = async ({ projectId, query }): Promise<any> => {
  return axios.get(`/projects/${projectId}/statistics/email-client-bounces`, {
    params: query,
  });
};

export {
  getBarChartStatisic,
  getFactStatistic,
  getListStatistic,
  getHeatmapStatistic,
  getListStatisticExport,
  getEmailOverviewStatistic,
  getEmailClientUsageStatistic,
  getEmailClientBounces,
  getContactsExport,
};

import PageContainer from 'components/PageContainer/PageContainer';
import PageContent from 'components/PageContent/PageContent';
import { PageHeadLine } from 'components/PageHeadLine/PageHeadLine';
import PrimaryButton from 'components/PrimaryButton/PrimaryButton';
import SelectDropDown from 'components/SelectDropDown/SelectDropDown';
import SmartDateRangePicker from 'components/SmartDateRangePicker/SmartDateRangePicker';
import { inject, observer } from 'mobx-react';
import moment, { Moment } from 'moment';
import { useState } from 'react';
import { toast } from 'react-toastify';
import { getContactsExport } from 'services/StatisticsService';
import { ProjectStore } from 'stores/private/ProjectStore';
import './UpdateProjectDataExport.scss';

interface UpdateProjectDataExportProps {
  projectStore?: ProjectStore;
}

const UpdateProjectDataExport = ({
  projectStore,
}: UpdateProjectDataExportProps) => {
  const project = projectStore!.currentProject;
  const [exportIsLoading, setExportIsLoading] = useState(false);
  const [selectedFeedbackType, setSelectedFeedbackType] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [csvIsLoading, setCsvIsLoading] = useState(false);
  const [projectionFilters, setProjectionFilters] = useState([]);

  const [startDate, setStartDate] = useState(
    moment().subtract(4, 'years').startOf('day') as Moment | null,
  );
  const [endDate, setEndDate] = useState(
    moment().endOf('day') as Moment | null,
  );

  if (!project) {
    return null;
  }

  return (
    <PageContainer>
      <PageHeadLine title="Data export">
        <SmartDateRangePicker
          startIndex={4}
          onDateChange={(start, end) => {
            setStartDate(start);
            setEndDate(end);
          }}
        />
      </PageHeadLine>
      <PageContent hasTitle isMediumBoxed>
        <div className="options-group">
          <div className="options-group-header">Tickets</div>
          <div className="danger-zone">
            <div className="danger-zone-container">
              <div className="danger-zone-item">
                <div className="danger-zone-label">
                  Select the board you want to export
                </div>
                <div className="dropdown-selection w-50">
                  <SelectDropDown
                    labelPropertyName="name"
                    valuePropertyName="code"
                    placeholder="Select board"
                    items={
                      projectStore?.currentProject?.projectTypes.map(
                        (projectType) => {
                          return {
                            name: projectType.name,
                            type: projectType.type,
                          };
                        },
                      ) || []
                    }
                    onChange={(selectedOption) => {
                      setSelectedFeedbackType(selectedOption);
                    }}
                  />
                </div>
              </div>
              <div className="danger-zone-item">
                <div className="danger-zone-label">Fields (Optional)</div>
                <SelectDropDown
                  createable
                  isMulti
                  items={projectionFilters}
                  onChange={(values) => {
                    setProjectionFilters(values);
                  }}
                />
              </div>
              <div className="danger-zone-item">
                <div className="danger-zone-label">
                  Export all feedback items
                </div>
                <PrimaryButton
                  className="mr-10"
                  label="Export as JSON"
                  isLoading={isLoading}
                  disabled={!selectedFeedbackType}
                  onClick={async () => {
                    if (selectedFeedbackType) {
                      setIsLoading(true);
                      try {
                        await projectStore!.downloadBugsAsJSON({
                          feedbackType: selectedFeedbackType,
                          startDate,
                          endDate,
                          projection: projectionFilters.map((filter: any) => {
                            return filter.value;
                          }),
                        });
                      } catch (error) {
                        toast.error('An error occurred during export');
                      } finally {
                        setIsLoading(false);
                      }
                    } else {
                      toast.error('Please select a board first');
                    }
                  }}
                />
                <PrimaryButton
                  label="Export as CSV"
                  isLoading={csvIsLoading}
                  disabled={!selectedFeedbackType}
                  onClick={async () => {
                    if (selectedFeedbackType) {
                      setCsvIsLoading(true);
                      try {
                        await projectStore!.downloadTicketsAsCSV({
                          feedbackType: selectedFeedbackType,
                          startDate,
                          endDate,
                          projection: projectionFilters.map((filter: any) => {
                            return filter.value;
                          }),
                        });
                      } catch (error) {
                        toast.error('An error occurred during export');
                      } finally {
                        setCsvIsLoading(false);
                      }
                    } else {
                      toast.error('Please select a board first');
                    }
                  }}
                />
              </div>
            </div>
          </div>
        </div>
        <div className="options-group">
          <div className="options-group-header">Contacts</div>
          <div className="danger-zone">
            <div className="danger-zone-container">
              <div className="danger-zone-item">
                <div className="danger-zone-label">
                  Export all contacts with email set
                </div>
                {projectStore?.isProjectAdmin ? (
                  <PrimaryButton
                    label="Export"
                    disabled={!projectStore?.isProjectAdmin}
                    isLoading={exportIsLoading}
                    onClick={async () => {
                      try {
                        const isAdmin = projectStore?.isProjectAdmin;
                        if (!isAdmin) {
                          toast.error(
                            'You need to be an admin to export contacts',
                          );
                          return;
                        }
                        setExportIsLoading(true);

                        const response = await getContactsExport({
                          projectId: projectStore?.currentProject?.id,
                        });

                        const hiddenElement = document.createElement('a');
                        hiddenElement.href = `data:text/csv;charset=utf-8,${encodeURI(
                          response.data,
                        )}`;
                        hiddenElement.target = '_blank';
                        hiddenElement.download = `contacts.csv`;
                        hiddenElement.click();

                        setExportIsLoading(false);
                      } catch (err) {
                        toast.error('Export failed');
                        setExportIsLoading(false);
                      }
                    }}
                  />
                ) : (
                  <span className="text">
                    Only an admin can export contacts.
                  </span>
                )}
              </div>
            </div>
          </div>
        </div>
      </PageContent>
    </PageContainer>
  );
};

export default inject('projectStore')(observer(UpdateProjectDataExport));

import RichTextEditor, {
  RichTextEditorRefProps,
} from 'components/Editors/RichTextEditor/RichTextEditor';
import GiphyAttachment from 'components/GiphyAttachment/GiphyAttachment';
import Column from 'components/LayoutComponents/ColumnComponent/ColumnComponent';
import Row from 'components/LayoutComponents/RowComponent/RowComponent';
import LiveTranslateMessageAction from 'components/LiveTranslateMessageAction/LiveTranslateMessageAction';
import LoadingAnimationSmall from 'components/LoadingAnimationSmall/LoadingAnimationSmall';
import PerformHelpcenterAction from 'components/PerformHelpcenterAction/PerformHelpcenterAction';
import PrimaryButton from 'components/PrimaryButton/PrimaryButton';
import SendInputAttachmentUpload from 'components/SendInputAttachmentUpload/SendInputAttachmentUpload';
import SendInputMacros from 'components/SendInputMacros/SendInputMacros';
import SendInputWorkflowAction from 'components/SendInputWorkflowAction/SendInputWorkflowAction';
import TabButton from 'components/TabButton/TabButton';
import UserAvatar from 'components/UserAvatar/UserAvatar';
import { replaceServerVariables } from 'helper/ReplaceVariables';
import { Session } from 'models/Session';
import {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from 'react';
import { toast } from 'react-toastify';
import ReactTooltip from 'react-tooltip';
import { runWorkflowForTicket } from 'services/BugHttpService';
import { getSessionName } from 'services/GuestNameHelper';
import { isMacintosh } from 'services/Helper';
import './SendInputEditor.scss';

interface SendInputEditorProps {
  inputTypes?: string[];
  recepients?: Session[];
  isSending?: boolean;
  shared?: boolean;
  ticketId?: string;
  sessionId?: string;
  canSendAndClose?: boolean;
  currentInputType?: string;
  inputTypeChanged?: any;
  inputContentChanged?: any;
  inputPlaceholder?: string;
  disableEmailFallback?: boolean;
  onClickSend?: any;
  allowSend?: boolean;
  onFilesSelected?: (any) => void;
  attachmentsRef?: any;
  variables?: any;
  mentions?: {
    id: string;
    label: string;
    email?: string;
    profileImageUrl?: string;
  }[];
  defaultContent?: any;
  customLabel?: string;
  disableTooltips?: boolean;
  hideTabButtons?: boolean;
  showAttachments: boolean;
  attachments?: any;
  attachmentsUpdated?: (any) => void;
  currentlyTyping?: any;
  draftId?: string;
}

const SendInputEditor = forwardRef(
  (
    {
      inputTypes = [],
      recepients = [],
      isSending,
      ticketId,
      shared = false,
      currentInputType,
      canSendAndClose = false,
      inputTypeChanged,
      inputContentChanged,
      inputPlaceholder,
      onClickSend,
      variables = {},
      allowSend = true,
      disableEmailFallback = false,
      mentions = [],
      attachmentsRef,
      onFilesSelected,
      showAttachments = true,
      hideTabButtons = false,
      attachments = [],
      attachmentsUpdated,
      currentlyTyping,
      draftId,
      defaultContent,
      customLabel,
      disableTooltips,
      sessionId,
    }: SendInputEditorProps,
    ref,
  ) => {
    const editorRef = useRef<RichTextEditorRefProps>(null);
    const [canSend, setCanSend] = useState(false);
    const canSendRef = useRef(canSend);
    canSendRef.current = canSend;

    // Load draft.
    var initialContentFromDraft = defaultContent || ({} as any);
    try {
      if (draftId && !defaultContent) {
        const draft = localStorage.getItem(draftId);
        if (draft) {
          const jsonDraft = JSON.parse(draft);
          if (jsonDraft) {
            initialContentFromDraft = jsonDraft;
          }
        }
      }
    } catch (exp) {}

    const [currentContent, setCurrentContent] = useState(
      initialContentFromDraft,
    );

    useEffect(() => {
      ReactTooltip.rebuild();
    }, [canSend]);

    useImperativeHandle(ref, () => ({
      updateContent(content: any) {
        setCurrentContent(content);
        const editor: any = editorRef.current;
        try {
          if (editor) {
            editor.updateContent(content);

            // Update can send.
            if (editor?.state?.doc?.textContent?.length === 0) {
              setCanSend(false);
            } else {
              setCanSend(true);
            }
          }
        } catch (exp) {}
      },
    }));

    const buildTabButtons = () => {
      if (shared) {
        return null;
      }

      if (inputTypes.length === 0 || hideTabButtons) {
        return <></>;
      }

      const getInputTextType = (inputType) => {
        if (inputType === 'Reply') {
          if (disableEmailFallback) {
            return 'The message will be sent as chat message only';
          }
          return 'The message will be sent as chat message with email fallback';
        }

        if (inputType === 'Send message to subscribers') {
          if (disableEmailFallback) {
            return 'The message will be sent to all subscribers as chat message only';
          }
          return 'The message will be sent to all subscribers as chat message with email fallback';
        }

        return 'Notes will be sent to the team only';
      };

      return (
        <div className="tab-buttons">
          <div className="tab-buttons--inner">
            {inputTypes.map((inputType: string) => {
              return (
                <div
                  data-for="sendTypeTooltip"
                  data-tip={getInputTextType(inputType)}
                >
                  <TabButton
                    key={inputType}
                    active={inputType === currentInputType}
                    label={inputType}
                    onClick={() => {
                      inputTypeChanged(inputType);
                    }}
                  />
                </div>
              );
            })}
          </div>
        </div>
      );
    };

    const insertGiphy = (gifData: any) => {
      editorRef.current?.insertImage(gifData.images.original.url);
      setCanSend(true);
    };

    const insertHelpCenterArticle = (article: any) => {
      const editor: any = editorRef.current;

      if (editor) {
        editor.insertContent({
          type: 'helpCenterArticle',
          attrs: {
            articleId: article.id,
            articleTitle: article.title,
            articleDescription: article.description,
            articleUrl: article.url,
          },
        });
      }
    };

    const buildAttachmentOptions = () => {
      if (!showAttachments || shared) {
        return <div />;
      }

      return (
        <div className="attachment-row-outer">
          <div className="attachment-row">
            <SendInputAttachmentUpload
              ref={attachmentsRef}
              onFilesSelected={onFilesSelected}
            />
            <GiphyAttachment onGifSelected={insertGiphy} />
            <ReactTooltip
              id="codeBlockTooltip"
              className="infoTooltip infoTooltipButton"
              delayHide={0}
              type="light"
              effect="solid"
              getContent={(content) => {
                return (
                  <div className="send-key-tooltip">
                    <span>{content}</span>
                  </div>
                );
              }}
            />
            <div
              data-for="codeBlockTooltip"
              data-tip="Code block"
              onClick={() => editorRef?.current?.insertCodeBlock()}
              className={`bubble-menu-item`}
            >
              <i className="fa-solid fa-square-code icon-size" />
            </div>
            <SendInputMacros
              onTextPaste={(content) => {
                if (editorRef.current) {
                  // Replace variables.
                  const contentJson = replaceServerVariables({
                    contentJson: content,
                    variables,
                  });
                  setCanSend(true);
                  if (contentJson.type && contentJson.type === 'doc') {
                    content = contentJson.content;
                  }

                  editorRef.current!.insertContent(content);
                }
              }}
            />
            <PerformHelpcenterAction
              onArticleSelected={insertHelpCenterArticle}
            />
            {ticketId && ticketId.length > 0 && (
              <>
                <SendInputWorkflowAction
                  onWorkflowSelected={async (workflow: any) => {
                    if (workflow && workflow.id && ticketId) {
                      try {
                        const res = await runWorkflowForTicket(
                          ticketId,
                          workflow.id,
                        );
                        if (res && res.data && res.data.success) {
                          toast.success(`Started workflow '${workflow.name}'.`);
                        } else {
                          throw new Error('Failed to start workflow.');
                        }
                      } catch (exp) {
                        toast.error(
                          `Failed to start workflow '${workflow.name}'.`,
                        );
                      }
                    }
                  }}
                />
                <LiveTranslateMessageAction
                  isEmpty={!canSend}
                  originalText={currentContent}
                  onTextTranslated={(content) => {
                    if (editorRef.current) {
                      editorRef.current!.setContent({
                        content: content,
                        type: 'doc',
                      });
                    }
                  }}
                />
              </>
            )}
          </div>
        </div>
      );
    };

    const sendMessage = (
      shouldCloseConversation = false,
      shouldArchive = false,
    ) => {
      if (!allowSend) {
        return;
      }

      if (!canSendRef.current && (!attachments || attachments.length === 0)) {
        return;
      }

      const json = editorRef.current!.getJSON();
      onClickSend(
        json,
        shouldCloseConversation && canSendAndClose,
        shouldArchive && canSendAndClose,
      );
      if (editorRef.current) {
        editorRef.current!.clearContent();
      }
      setCanSend(false);
    };

    const buildSendOptions = () => {
      let label = 'Send';

      if (currentInputType === 'Note') {
        label = 'Add note';
      }
      if (customLabel) {
        label = customLabel;
      }

      return (
        <div data-for="sendButtonTooltip" data-tip={label}>
          <PrimaryButton
            label={label}
            className="send-button"
            disabled={
              (!canSend || !allowSend) &&
              (!attachments || attachments.length === 0)
            }
            onClick={() => {
              if (!onClickSend) {
                return;
              }

              sendMessage(false);
            }}
          />
        </div>
      );
    };

    const buildActionRow = () => {
      return (
        <div>
          <div>
            {attachments && attachments.length > 0 && (
              <div className="attachments-container">
                {attachments.map((attachment, index) => {
                  return (
                    <div
                      className="input-selected-file"
                      key={`attachment-${index}`}
                    >
                      <span>{attachment.name}</span>
                      <i
                        className="fa-solid fa-xmark"
                        onClick={() => {
                          if (attachmentsUpdated) {
                            attachments.splice(index, 1);
                            attachmentsUpdated(attachments);
                          }
                        }}
                      />
                    </div>
                  );
                })}
              </div>
            )}
          </div>
          <div className="action-row">
            {buildAttachmentOptions()}
            {buildSendOptions()}
          </div>
        </div>
      );
    };

    const renderCurrentlyTyping = () => {
      if (!currentlyTyping) {
        return <></>;
      }

      if (currentlyTyping?.session) {
        return (
          <div className="currently-typing">
            <UserAvatar small email={currentlyTyping?.session?.email} />
            <div className="currently-typing-text">
              <span>{getSessionName(currentlyTyping?.session)}</span> is
              typing...
            </div>
          </div>
        );
      }

      return (
        <div className="currently-typing">
          <UserAvatar small imageUrl={currentlyTyping?.user?.profileImageUrl} />
          <div className="currently-typing-text">
            <span>{currentlyTyping?.user?.firstName}</span> is typing...
          </div>
        </div>
      );
    };

    return (
      <div
        className={`send-input-editor-container send-input-editor-container--${currentInputType?.toLocaleLowerCase()}`}
      >
        {renderCurrentlyTyping()}
        <Row alignItems="center">
          {buildTabButtons()}
          {currentInputType !== 'Note' && recepients.length > 1 && (
            <div
              className="recepient-preview-container ml-15"
              data-for="recepientTooltip"
              data-tip={recepients}
            >
              {recepients.map((session, index) => {
                return (
                  <UserAvatar
                    className="recepient-preview"
                    email={session?.email}
                  />
                );
              })}
            </div>
          )}
        </Row>
        <div className="editor-container">
          <RichTextEditor
            ref={editorRef}
            showToolbar={!shared}
            mentions={mentions}
            content={currentContent}
            onFilesSelected={onFilesSelected}
            closeAndArchiveConversation={() => {
              sendMessage(false, true);
            }}
            emptyStateChanged={(isEmpty) => {
              if (isEmpty) {
                setCanSend(false);
              } else {
                setCanSend(true);
              }
            }}
            sendMessage={() => {
              sendMessage(false);
            }}
            closeConversation={() => {
              sendMessage(true);
            }}
            inputContentChanged={(content) => {
              setCurrentContent(content);
              inputContentChanged(content);
            }}
            placeholder={inputPlaceholder}
          />
        </div>
        {buildActionRow()}
        {isSending && (
          <div className="send-input-editor-container--sending">
            <LoadingAnimationSmall />
          </div>
        )}
        <ReactTooltip
          id="sendButtonTooltip"
          className="infoTooltip infoTooltipButton"
          delayHide={500}
          type="light"
          disable={disableTooltips}
          effect="solid"
          getContent={(content) => {
            return (
              <div>
                <div
                  className={`send-key-tooltip ${canSendAndClose && 'mb-5'}`}
                >
                  <span
                    className={canSendAndClose ? 'send-key-tooltip--span' : ''}
                  >
                    {content}
                  </span>
                  <div className="hotkey-help">
                    {isMacintosh() ? <div>⌘</div> : <div>Ctrl</div>}
                    <div>Enter</div>
                  </div>
                </div>
                {canSendAndClose && (
                  <div className="send-key-tooltip">
                    <span className="send-key-tooltip--span">Send & close</span>
                    <div className="hotkey-help">
                      {isMacintosh() ? <div>⌘</div> : <div>Ctrl</div>}
                      <div>Shift</div>
                      <div>Enter</div>
                    </div>
                  </div>
                )}
                {canSendAndClose && (
                  <div className="send-key-tooltip mt-5">
                    <span className="send-key-tooltip--span">
                      Send & archive
                    </span>
                    <div className="hotkey-help">
                      {isMacintosh() ? <div>⌘</div> : <div>Ctrl</div>}
                      <div>Shift</div>
                      <div>A</div>
                    </div>
                  </div>
                )}
              </div>
            );
          }}
        />
        <ReactTooltip
          id="sendTypeTooltip"
          className="infoTooltip infoTooltipButton"
          delayHide={500}
          disable={disableTooltips}
          type="light"
          effect="solid"
          getContent={(content) => {
            return (
              <div>
                <div className="send-key-tooltip">
                  <span
                    className={canSendAndClose ? 'send-key-tooltip--span' : ''}
                  >
                    {content}
                  </span>
                </div>
              </div>
            );
          }}
        />
        <ReactTooltip
          id="recepientTooltip"
          className="infoTooltip infoTooltipButton"
          delayHide={0}
          disable={disableTooltips}
          type="light"
          effect="solid"
          getContent={(content) => {
            return (
              <Column>
                <Row alignItems="center" className="mb-10">
                  <span className="recepient-tooltip-title text">
                    <b>Recipients</b>
                  </span>
                </Row>
                {recepients.map((session, index) => {
                  return (
                    <Row key={index} alignItems="center" className="mb-5">
                      <UserAvatar
                        className="recepient-tooltip-avatar"
                        small
                        email={session?.email}
                      />
                      <div className="recepient-tooltip-email text ml-10">
                        {session?.email ?? getSessionName(session)}
                      </div>
                    </Row>
                  );
                })}
              </Column>
            );
          }}
        />
      </div>
    );
  },
);

export default SendInputEditor;

import LinkButton from 'components/LinkButton/LinkButton';
import ProjectArchive from 'components/ProjectArchive/ProjectArchive';
import { getIconForFeedbackType } from 'components/Sidenav/SidenavElement/SidenavElement';
import SimpleSidenavElement from 'components/Sidenav/SimpleSidenavElement/SimpleSidenavElement';
import SidenavContainer from 'components/SidenavContainer/SidenavContainer';
import SubSidenav from 'components/SubSidenav/SubSidenav';
import { inject, observer } from 'mobx-react';
import { useEffect, useState } from 'react';
import {
  Route,
  Routes,
  useLocation,
  useNavigate,
  useParams,
} from 'react-router';
import { toast } from 'react-toastify';
import { ModalStore } from 'stores/private/ModalStore';
import { ProjectStore } from 'stores/private/ProjectStore';
import Swal from 'sweetalert2';
import ProjectFeatureRequestSettings from '../ProjectFeatureRequests/ProjectFeatureRequestSettings';
import ProjectFeedback from '../ProjectFeedback/ProjectFeedback';
import './ProjectInbound.scss';
import TicketDetailScreen from 'components/BugDetail/TicketDetailScreen';
import UpgradePlan from 'components/UpgradePlan/UpgradePlan';

interface ProjectInboundProps {
  projectStore?: ProjectStore;
  modalStore?: ModalStore;
}

function ProjectInbound({ projectStore, modalStore }: ProjectInboundProps) {
  const location = useLocation();
  const navigate = useNavigate();
  const { projectId } = useParams();
  const currentProject = projectStore?.currentProject;
  const [subSidenavCollapsed, setSubSidenavCollapsed] = useState(() => {
    const storedValue = localStorage.getItem('subSidenavCollapsed');
    return storedValue ? JSON.parse(storedValue) : false;
  });

  const isListView = projectStore?.viewType === 'LIST';

  useEffect(() => {
    modalStore?.setSideNavCollapsed(subSidenavCollapsed);
  }, [subSidenavCollapsed]);

  const updateSubSidenavCollapsed = (newState) => {
    localStorage.setItem('subSidenavCollapsed', JSON.stringify(newState));
    setSubSidenavCollapsed(newState);
  };

  const handleSubSidenavCollapseToggle = () => {
    updateSubSidenavCollapsed(!subSidenavCollapsed);
  };

  useEffect(() => {
    initialize();
  }, []);

  const initialize = async () => {
    try {
      if (projectId) {
        await projectStore!.loadProjectById(projectId);
      }
    } catch (err) {
      toast.error('Could not load project');
      navigate('/dashboard');
    }
  };

  if (!currentProject) {
    return <></>;
  }

  const isExpired = projectStore?.isPlanExpired();
  if (isExpired) {
    return (
      <div className="add-outreach-full">
        <UpgradePlan />
      </div>
    );
  }

  const sidebarCount = (projectType) => {
    var unreadCount = 0;

    for (const itemKey in projectStore?.unreadItems) {
      if (
        projectStore?.unreadItems[itemKey] &&
        projectStore?.unreadItems[itemKey].type === projectType
      ) {
        unreadCount++;
      }
    }

    return unreadCount;
  };

  const isActive = (path) => {
    if (location.pathname) {
      const parts = location.pathname.split('/');

      if (parts.length >= 3 && parts[3] === path) {
        return true;
      }
    }

    return false;
  };

  const isNestedActive = (path, subPath?: string) => {
    if (location.pathname) {
      const parts = location.pathname.split('/');

      if (parts.length >= 3 && parts[3] === path) {
        if (subPath) {
          if (parts.length >= 4 && parts[4] === subPath) {
            return true;
          }
        } else {
          return true;
        }
      }
    }

    return false;
  };

  const isRoadmap = isActive('featurerequests');

  const addBoard = (boardName) => {
    const baseName = boardName.toLowerCase().replace(/[^a-z]/g, '');

    if (!baseName || baseName === '') {
      toast.error('Board name must be at least contain one character.');
      return;
    }

    if (
      baseName.toLowerCase() === 'survey' ||
      baseName.toLowerCase() === 'surveys' ||
      baseName.toLowerCase() === 'crash' ||
      baseName.toLowerCase() === 'feature_request'
    ) {
      toast.error('Board name is reserved.');
      return;
    }

    if (
      projectStore!.currentProject!.projectTypes.find(
        (board) =>
          board.name.toLowerCase().replaceAll(' ', '') ===
          baseName.toLowerCase().replaceAll(' ', ''),
      )
    ) {
      toast.error('Board name characters must be unique.');
      return;
    }

    projectStore!.addProjectType(boardName, baseName);
  };

  const renderSidebar = () => {
    if (isRoadmap) {
      const featureRequestCount = sidebarCount('FEATURE_REQUEST');

      return (
        <>
          <SimpleSidenavElement
            onClick={() => {
              navigate(`/projects/${projectId}/featurerequests`);
            }}
            isActive={
              isActive('featurerequests') &&
              !isNestedActive('featurerequests', 'settings')
            }
            key="featurerequests"
            name="Board"
            notificationCount={featureRequestCount}
          />
          <SimpleSidenavElement
            onClick={() => {
              navigate(`/projects/${projectId}/featurerequests/settings`);
            }}
            iconEnd={true}
            isActive={isNestedActive('featurerequests', 'settings')}
            key="settings"
            name="Settings"
          />
        </>
      );
    }

    return (
      <>
        {currentProject.projectTypes.map((projectType) => {
          if (projectType.type === 'RATING') {
            return null;
          }

          if (projectType.type === 'CRASH' && !projectStore.hasCrashReports) {
            return null;
          }

          if (projectType.type === 'INQUIRY') {
            return null;
          }

          if (projectType.type === 'FEATURE_REQUEST') {
            return null;
          }

          return (
            <SimpleSidenavElement
              onClick={() => {
                navigate(`/projects/${projectId}/${projectType.path}`);
              }}
              Icon={getIconForFeedbackType(projectType.icon)}
              isActive={isActive(projectType.path)}
              key={projectType.path}
              name={projectType.menuName}
              notificationCount={sidebarCount(projectType?.type)}
              className={
                subSidenavCollapsed ? 'simple-sidenav-element-icon' : ''
              }
            />
          );
        })}
        {projectStore?.isProjectAdmin && (
          <LinkButton
            className="add-new-board-button"
            icon="plus"
            noBorder
            iconSideRight={false}
            label="Add board"
            onClick={() => {
              Swal.fire({
                title: 'Add a new board',
                input: 'text',
                inputPlaceholder: 'Board name',
                showCancelButton: true,
                confirmButtonText: 'Add',
                showLoaderOnConfirm: true,
                preConfirm: (boardName) => {
                  addBoard(boardName);
                },
              });
            }}
          />
        )}
        <div className="subnav-spacer-top mt-20" />
        <SimpleSidenavElement
          name="Archive & spam"
          onClick={() => {
            navigate(`/projects/${projectId}/archive`);
          }}
          faIcon="box-archive"
          isActive={isActive('archive')}
        />
      </>
    );
  };

  const isTicket = !currentProject.projectTypes.some((projectType) => {
    return (
      projectStore?.currentFeedbackType?.type === 'RATING' ||
      projectStore?.currentFeedbackType?.type === 'CRASH' ||
      projectStore?.currentFeedbackType?.type === 'INQUIRY' ||
      projectStore?.currentFeedbackType?.type === 'FEATURE_REQUEST'
    );
  });

  return (
    <SidenavContainer
      isCollapsed={subSidenavCollapsed}
      className="sidenav-container--small"
      type={projectStore.currentFeedbackType?.type}
    >
      <SubSidenav
        title={isRoadmap ? 'Roadmap' : 'Tickets'}
        isCollapsed={!isRoadmap && subSidenavCollapsed}
        onCollapseToggle={
          !isRoadmap ? handleSubSidenavCollapseToggle : undefined
        }
        type={isTicket}
      >
        {renderSidebar()}
      </SubSidenav>
      <Routes>
        <Route path="/archive" element={<ProjectArchive />}>
          <Route path=":shareToken" element={<TicketDetailScreen />} />
        </Route>
        <Route
          path="/featurerequests/settings"
          element={<ProjectFeatureRequestSettings />}
        />
        <Route path="/:feedbackTypePath" element={<ProjectFeedback />}>
          <Route
            path=":shareToken"
            element={isListView ? <></> : <TicketDetailScreen />}
          />
        </Route>
      </Routes>
    </SidenavContainer>
  );
}

export default inject('projectStore', 'modalStore')(observer(ProjectInbound));

import TextInput from 'components/TextInput/TextInput';
import { inject, observer } from 'mobx-react';
import { useEffect, useRef, useState } from 'react';
import { BugStore } from 'stores/private/BugStore';
import { CopilotStore } from 'stores/private/CopilotStore';
import { ReactComponent as GleapBot } from 'assets/icons/gleapbot.svg';
import './KaiCopilot.scss';
import UserAvatar from 'components/UserAvatar/UserAvatar';
import PrimaryButton from 'components/PrimaryButton/PrimaryButton';
import TipTapPreview, {
  replaceMarkdown,
} from 'components/Editors/RichTextEditor/TipTapPreview';
import { ProjectStore } from 'stores/private/ProjectStore';
import { ModalStore, MODALTYPE } from 'stores/private/ModalStore';
import { CommentTypes } from 'models/Comment';
import moment from 'moment';
import { subscribeToBug } from 'services/BugHttpService';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router';

interface KaiCopilotProps {
  bugStore?: BugStore;
  copilotStore?: CopilotStore;
  projectStore?: ProjectStore;
  modalStore?: ModalStore;
}

const KaiCopilot = ({
  bugStore,
  copilotStore,
  projectStore,
  modalStore,
}: KaiCopilotProps) => {
  const [copilotText, setCopilotText] = useState('');
  const commentsRef = useRef(null as any);
  const copilotMessages = copilotStore?.messages ?? [];
  const navigate = useNavigate();

  const scrollCommentsViewToBottom = () => {
    setTimeout(() => {
      if (copilotMessages.length > 0 && commentsRef && commentsRef.current) {
        if ((commentsRef.current.scrollTop ?? 0) === 0) {
          commentsRef.current!.scroll({
            top: commentsRef.current!.scrollHeight * 0.75,
            left: 0,
            behavior: 'auto',
          });
        }
        commentsRef.current!.scroll({
          top: commentsRef.current!.scrollHeight,
          left: 0,
          behavior: 'smooth',
        });
      }
    }, 100);
  };

  useEffect(() => {
    if (bugStore?.currentBug?.id) {
      copilotStore?.loadMessages(bugStore?.currentBug?.id);
    }
  }, [bugStore?.currentBug?.id]);

  useEffect(() => {
    scrollCommentsViewToBottom();
  }, [copilotStore?.messages?.length, copilotStore?.generating]);

  const renderSourceItem = (source: any) => {
    let icon = 'book';

    if (source.sourceType === 'url') {
      icon = 'globe';
    } else if (source.sourceType === 'featurerequest') {
      icon = 'lightbulb';
    } else if (source.sourceType === 'snippet') {
      icon = 'comment';
    } else {
      icon = 'book';
    }

    return (
      <div
        key={source.id}
        className="copilot--message--source"
        onClick={() => {
          console.log('Source clicked', JSON.stringify(source));

          // TODO: Implement this
          if (source.sourceType === 'url') {
          } else if (source.sourceType === 'featurerequest') {
            (window as any)
              .open(
                `https://app.gleap.io/projects/${projectStore?.currentProject?.id}/featurerequests/${source.shareToken}`,
                '_blank',
              )
              .focus();
          } else if (source.sourceType === 'snippet') {
          } else {
          }
        }}
      >
        <div className="copilot--message--source-icon">
          <i className={`fa-solid fa-${icon}`} />
        </div>
        <span>{source?.title ?? 'No title'}</span>
      </div>
    );
  };

  const renderAIComments = () => {
    if (
      !copilotStore?.generating &&
      !copilotStore?.loadingMessages &&
      copilotMessages.length <= 0
    ) {
      return (
        <div className="copilot-placeholder">
          <div className="copilot-placeholder-card">
            <GleapBot className="kai" />
            <div className="copilot-placeholder-title">Hi, I'm Kai 👋</div>
            <div className="copilot-placeholder-text">
              Ask me anything about this conversation.
            </div>
          </div>
        </div>
      );
    }

    return copilotStore?.messages?.map((message) => {
      const sources = message?.sources ?? [];
      const featureRequestSource = sources.find(
        (source) => source.sourceType === 'featurerequest',
      );

      if (message.type === 'BOT') {
        return (
          <div key={message.id} className="copilot--message">
            <div className="copilot--message--avatar">
              <GleapBot className="kai" />
              <span>Kai</span>
            </div>
            <div className="copilot--message--content">
              <div className="copilot--message--content-bot">
                <TipTapPreview content={message?.message} />
                {!message?.contextMissing && message?.isCustomerReply && (
                  <PrimaryButton
                    small
                    className="mt-10"
                    icon="pen-to-square"
                    fullWidth
                    onClick={() => {
                      if ((window as any).onMessagePaste) {
                        (window as any).onMessagePaste(
                          replaceMarkdown(message?.message ?? '').replaceAll(
                            '\n',
                            '<br>',
                          ),
                        );
                      }
                    }}
                    label="Add to composer"
                  />
                )}
              </div>
              <div className="copilot--message--sources">
                {message?.isCustomerReply &&
                  (message?.searchToolUsed || sources.length > 0) && (
                    <>
                      <div className="copilot--message--sources--title">
                        {sources?.length === 0 ? 'No' : sources?.length} sources
                        used
                      </div>
                      <div className="copilot--message--sources--items">
                        {sources?.map((source) => renderSourceItem(source))}
                        <div
                          key="add-source"
                          className="copilot--message--source"
                          onClick={() => {
                            (window as any)
                              .open(
                                `https://app.gleap.io/projects/${projectStore?.currentProject?.id}/aibot`,
                                '_blank',
                              )
                              .focus();
                          }}
                        >
                          <div className="copilot--message--source-icon">
                            <i className={`fa-solid fa-plus`} />
                          </div>
                          <span>Add sources</span>
                        </div>
                      </div>
                    </>
                  )}
                {message?.featureRequestSuggestion && (
                  <>
                    <div className="copilot--message--sources--title mt-20">
                      💡 Feature request suggestion
                    </div>
                    <div
                      key="add-feature-request"
                      className="copilot--message--source"
                      onClick={() => {
                        recommendFeatureRequest(
                          message?.featureRequestSuggestion?.title ?? '',
                          message?.featureRequestSuggestion?.description ?? '',
                        );
                      }}
                    >
                      <div className="copilot--message--source-icon">
                        <i className={`fa-solid fa-plus`} />
                      </div>
                      <span>
                        Add '
                        {message?.featureRequestSuggestion?.title ?? 'No title'}
                        ' to roadmap
                      </span>
                    </div>
                  </>
                )}
                {featureRequestSource && (
                  <>
                    <div className="copilot--message--sources--title mt-20">
                      💡 Add upvote to feature
                    </div>
                    <div
                      key="add-feature-request"
                      className="copilot--message--source"
                      onClick={() => {
                        upvoteFeatureRequest(featureRequestSource?.id);
                      }}
                    >
                      <div className="copilot--message--source-icon">
                        <i className={`fa-solid fa-triangle`} />
                      </div>
                      <span>
                        Upvote '{featureRequestSource?.title ?? 'No title'}'
                      </span>
                    </div>
                  </>
                )}
              </div>
            </div>
          </div>
        );
      }

      return (
        <div key={message.id} className="copilot--message">
          <div className="copilot--message--avatar">
            <UserAvatar
              small
              email={message?.user?.email}
              imageUrl={message?.user?.profileImageUrl}
            />
            <span>{message?.user?.firstName}</span>
          </div>
          <div className="copilot--message--content">
            {message?.message ?? '--'}
          </div>
        </div>
      );
    });
  };

  const recommendFeatureRequest = (title, description) => {
    // Auto-apply the next response.
    copilotStore?.setAutoApplyNextResponse(true);

    // Kickstart the response generation.
    askQuestion(
      'Write a short message to the customer, that we really appriciate his feature request and that we added it to our roadmap',
    );

    // Open the feature request modal.
    modalStore!.openModalSmart(MODALTYPE.CREATE_TICKET, {
      form: {
        title,
        description,
      },
      type: 'FEATURE_REQUEST',
      sessionId: bugStore?.currentBug?.session?.id,
    });
  };

  const upvoteFeatureRequest = (featureRequestId) => {
    if (featureRequestId && bugStore?.currentBug?.session?.email) {
      copilotStore?.setAutoApplyNextResponse(true);

      // Kickstart the response generation.
      askQuestion(
        'Write a short message to inform the customer about the current feature status and tell him that we added him to the upvotes. He will get notified on any updates.',
      );

      // Upvote the feature request.
      subscribeToBug(featureRequestId, bugStore?.currentBug?.session?.email);
    } else {
      toast.info('No email found for upvoting the feature request.');
    }
  };

  const askQuestion = (text) => {
    copilotStore?.askQuestion(bugStore?.currentBug?.id, text);
    setCopilotText('');
  };

  const renderActions = () => {
    let questions: any[] = [
      {
        label: '📕 Answer question',
        prompt:
          'Generate a reply to the customer`s most recent question / inquiry',
      },
    ];

    // Find latest message from customer.
    const latestCopilotMessage = copilotMessages.at(-1);

    if (copilotMessages.length <= 0) {
      questions.push({
        label: '📝 Summarize',
        prompt: 'Generate a concise summary of the ticket.',
      });

      if (bugStore?.currentBug?.status === 'OPEN') {
        questions.push({
          label: '✅ Acknowledge ticket',
          prompt:
            'Generate a friendly reply to the customer, that the team will look into the ticket asap.',
        });
      }
    } else {
      if (bugStore?.currentBug?.status !== 'DONE') {
        questions.push({
          label: '⏰ Still working on it',
          prompt:
            'Generate a friendly reply to the customer, to let him know, the team is still working on the ticket and will get back to him asap.',
        });
      }
    }

    if (bugStore?.currentBug?.status === 'DONE') {
      questions.push({
        label: '✅ Write resolve message',
        prompt:
          'Generate a friendly reply message to inform the customer that the ticket has been resolved (if applicable). Encourage to create a new ticket if they have any further questions.',
      });
    } else {
      questions.push({
        label: '💡 Suggest feature',
        prompt:
          'Analyse the current ticket and check if the customer has requested a feature. If so, suggest the feature.',
      });
    }

    const lastMessageFeatureRequest =
      latestCopilotMessage?.featureRequestSuggestion;
    if (lastMessageFeatureRequest !== null && lastMessageFeatureRequest) {
      questions = [
        {
          label: `💡 Add '${lastMessageFeatureRequest?.title}' to roadmap`,
          action: () => {
            recommendFeatureRequest(
              lastMessageFeatureRequest?.title ?? 'No title',
              lastMessageFeatureRequest?.description ?? 'No description',
            );
          },
        },
      ];
    }

    if (latestCopilotMessage) {
      const featureRequestSource = (latestCopilotMessage?.sources ?? []).find(
        (source) => source.sourceType === 'featurerequest',
      );

      if (featureRequestSource) {
        questions = [
          {
            label: `🔼 Upvote '${featureRequestSource?.title ?? 'No title'}'`,
            action: () => {
              upvoteFeatureRequest(featureRequestSource?.id);
            },
          },
        ];
      }
    }

    // Predefined questions for feature requests.
    if (bugStore?.currentBug?.type === 'FEATURE_REQUEST') {
      questions = [
        {
          label: '✅ Write feature released message',
          prompt:
            'Generate a friendly reply message to inform all subscribers that the features has been released and that you are excited to hear their feedback.',
        },
      ];
    }

    return (
      <div className="copilot-suggestions">
        {questions.map((question, index) => (
          <div
            key={index}
            className="copilot-suggestion"
            onClick={() => {
              // Execute the action.
              if (question?.action) {
                question?.action();
              }

              // Execute the prompt.
              if (question?.prompt) {
                askQuestion(question?.prompt);
              }
            }}
          >
            <span>{question?.label}</span>
          </div>
        ))}
      </div>
    );
  };

  return (
    <div className="copilot">
      {projectStore?.isProjectAdmin && (
        <div
          className="copilot-settings"
          onClick={() => {
            navigate(
              '/projects/' +
                projectStore?.currentProject?.id +
                '/settings/copilot',
            );
          }}
        >
          <i className="fa-solid fa-gear" />
        </div>
      )}
      <div className="copilot--content custom-scrollbar" ref={commentsRef}>
        {renderAIComments()}
        {copilotStore?.generating && (
          <div className="copilot--message">
            <div className="copilot--message--avatar">
              <GleapBot className="kai" />
              <span>Kai</span>
            </div>
            <div className="copilot--message--content">
              <div className="copilot--message--content-bot">
                <div className="copilot--message--loading">
                  <i className="fa-solid fa-spinner-third fa-spin" />
                  <span>Thinking...</span>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
      {!copilotStore?.generating && !copilotStore?.loadingMessages && (
        <div className="copilot--input-wrapper">
          {renderActions()}
          <TextInput
            key={'copilot'}
            className="copilot--input"
            onEnter={() => {
              askQuestion(copilotText);
            }}
            onChange={(text) => {
              setCopilotText(text);
            }}
            value={copilotText}
            icon="arrow-up"
            placeholder="Ask Kai a question..."
            sendIcon
          />
        </div>
      )}
    </div>
  );
};

export default inject(
  'bugStore',
  'copilotStore',
  'projectStore',
  'modalStore',
)(observer(KaiCopilot));

import { replaceMarkdown } from 'components/Editors/RichTextEditor/TipTapPreview';
import { makeAutoObservable, runInAction } from 'mobx';
import { CopilotMessage } from 'models/CopilotMessage';
import { askQuestion, getCopilotMessages } from 'services/CopilotHttpService';
import WebSocketMessage from 'services/WebSocketMessage';
import { WEBSOCKET_EVENTS } from 'services/WebSocketService';

export class CopilotStore implements WebSocketMessage {
  stores: any;
  messages: CopilotMessage[] = [];
  loadingMessages: boolean = false;
  generating: boolean = false;
  autoApplyNextResponse: boolean = false;

  constructor() {
    makeAutoObservable(this);
  }

  refreshData() {}

  setStores(stores) {
    this.stores = stores;
  }

  setMessages(messages: CopilotMessage[]) {
    this.messages = messages;
  }

  addMessage(message: CopilotMessage) {
    this.messages.push(message);
  }

  async loadMessages(ticketId: string) {
    this.setMessages([]);
    this.loadingMessages = true;
    this.generating = false;

    const messages = await getCopilotMessages(ticketId);

    runInAction(() => {
      this.setMessages(messages);
      this.loadingMessages = false;
    });
  }

  setAutoApplyNextResponse(autoApplyNextResponse: boolean) {
    this.autoApplyNextResponse = autoApplyNextResponse;
  }

  askQuestion(ticketId?: string, question?: string) {
    if (!ticketId || !question) {
      return;
    }

    this.generating = true;
    askQuestion(ticketId, question);
  }

  onEvent(event: string, data: any) {
    if (
      event === WEBSOCKET_EVENTS.COPILOT_CREATED &&
      this.stores?.bugStore?.currentBug?.id &&
      this.stores?.bugStore?.currentBug?.id === data.bug
    ) {
      runInAction(() => {
        if (data.type === 'BOT') {
          this.generating = false;

          if (this.autoApplyNextResponse) {
            // Apply to editor view.
            if ((window as any).onMessagePaste) {
              (window as any).onMessagePaste(
                replaceMarkdown(data?.message ?? '').replaceAll('\n', '<br>'),
              );
            }

            this.autoApplyNextResponse = false;
          }
        }

        this.addMessage(data);
      });
    }
  }
}

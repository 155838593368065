import CreateableSelectDropdown from 'components/CreateableSelectDropdown/CreateableSelectDropdown';
import InfoBox from 'components/InfoBox/InfoBox';
import Row from 'components/LayoutComponents/RowComponent/RowComponent';
import PageContainer from 'components/PageContainer/PageContainer';
import PageContent from 'components/PageContent/PageContent';
import { PageHeadLine } from 'components/PageHeadLine/PageHeadLine';
import PrimaryButton from 'components/PrimaryButton/PrimaryButton';
import SelectDropDown from 'components/SelectDropDown/SelectDropDown';
import TextInput from 'components/TextInput/TextInput';
import { languages } from 'constants/Languages';
import { inject, observer } from 'mobx-react';
import { useEffect, useState } from 'react';
import Switch from 'react-switch';
import { OrganisationStore } from 'stores/private/OrganisationStore';
import { ProjectStore } from 'stores/private/ProjectStore';
import './AIAssistProjectSettings.scss';

interface AIAssistProjectSettingsProps {
  projectStore?: ProjectStore;
  organisationStore?: OrganisationStore;
}

const AIAssistProjectSettings = ({
  projectStore,
  organisationStore,
}: AIAssistProjectSettingsProps) => {
  const project = projectStore!.currentProject;
  const [loading, setLoading] = useState(false);
  const [generateAiTitle, setGenerateAiTitle] = useState(false);
  const [doNotSuggestFAQ, setDoNotSuggestFAQ] = useState(false);
  const [defaultSummaryLanguage, setDefaultSummaryLanguage] = useState('en');
  const [classifyFeedback, setClassifyFeedback] = useState(false);
  const [tags, setTags] = useState<any[]>([]);
  const [generateBotAiSummary, setGenerateBotAiSummary] = useState(false);

  useEffect(() => {
    if (project) {
      setGenerateAiTitle(project!.generateAiTitle);
      setDoNotSuggestFAQ(project.doNotSuggestFAQ);
      setGenerateBotAiSummary(project!.generateBotAiSummary);
      setDefaultSummaryLanguage(project.defaultSummaryLanguage);
      setClassifyFeedback(project!.classifyFeedback);

      let tags = project!.autoTags ?? [];
      if (tags.length === 0) {
        tags.push({
          tag: null,
          description: '',
        });
      }

      setTags(tags);
    }
  }, [project]);

  if (!project) {
    return null;
  }

  const possibleTags = projectStore?.currentProject?.feedbackTags
    ? projectStore?.currentProject?.feedbackTags!.map((tagItem) => {
        return {
          label: tagItem.label,
          value: tagItem.label,
          color: tagItem.color ?? '#060d25',
        };
      })
    : [];

  const renderAutoTag = () => {
    return (
      <div className="options-group ai-tagging-request-modal">
        <div className="options-group-header">AI-based auto tagging</div>
        <InfoBox className="mb-20">
          AI based auto tagging will automatically tag new tickets based on the
          defined tags and descriptions below.
          <br />
          <br />
          <b>Example:</b>
          <br />
          <b>Bug report</b> - User reports a bug
          <br />
          <b>Refund</b> - User asks for a refund
          <br />
        </InfoBox>
        {tags.map((tagItem, index) => {
          const selectedTag = possibleTags.find(
            (tag) => tag?.value === tagItem?.tag,
          );

          return (
            <Row key={index} alignItems="center" className="mt-5">
              <div className="tagcontainer">
                <CreateableSelectDropdown
                  showBorder={true}
                  shared={false}
                  selectedItem={selectedTag}
                  isMulti={false}
                  items={possibleTags}
                  onChange={(selectedItem: any) => {
                    if (selectedItem && selectedItem.value) {
                      let existingTags = (
                        projectStore?.currentProject?.feedbackTags ?? []
                      ).map((tagItem) => tagItem.label);

                      // Add the tag to the project if it doesn't exist
                      projectStore?.handleTags([
                        ...existingTags,
                        selectedItem.value,
                      ]);

                      // Save tag to the action.
                      tags[index].tag = selectedItem?.value;
                      setTags([...tags]);
                    }
                  }}
                />
              </div>
              <TextInput
                name="prompt"
                placeholder="When to apply this tag?"
                icon="wand-magic-sparkles"
                type="text"
                error=""
                initalValue={tagItem?.description ?? ''}
                onChange={(text) => {
                  tags[index].description = text;
                  setTags([...tags]);
                }}
              />
              <PrimaryButton
                className="ml-10"
                iconOnly
                icon="trash"
                onClick={() => {
                  tags.splice(index, 1);
                  setTags([...tags]);
                }}
              />
            </Row>
          );
        })}
        <div className="mt-10">
          <PrimaryButton
            label="Add another tag"
            onClick={() => {
              tags.push({
                tag: null,
                description: '',
              });
              setTags([...tags]);
            }}
          />
        </div>
        <PrimaryButton
          isLoading={loading}
          className="mt-20"
          onClick={async () => {
            setLoading(true);

            try {
              await projectStore!.updateProject(project!.id, {
                autoTags: tags,
              });
              // eslint-disable-next-line no-empty
            } catch (exp) {}

            setLoading(false);
          }}
          label="Save"
        />
      </div>
    );
  };

  return (
    <PageContainer>
      <PageHeadLine title="AI assist ✨" />
      <PageContent hasTitle isMediumBoxed className="email-settings">
        {organisationStore?.currentOrganisation &&
          !organisationStore?.hasAIPlan() && (
            <InfoBox
              className="mb-30 info-box--error"
              icon="triangle-exclamation"
            >
              Your current plan <b>does not support</b> answer bot. Please
              contact us to subscribe to our AI features.
            </InfoBox>
          )}
        <div className="options-group">
          <div className="options-group-header">AI feedback assist</div>
          <div className="text mt-10">
            Use AI assist to automatically analyse incomming feedback.
          </div>
          <div className="checkbox-settings-content">
            <div className="checkbox-settings-container">
              <div className="switch-container mt-20">
                <Switch
                  width={40}
                  onColor="#2142E7"
                  height={20}
                  checkedIcon={false}
                  uncheckedIcon={false}
                  onChange={(checked) => {
                    setGenerateAiTitle(checked);
                  }}
                  checked={generateAiTitle}
                />
                <span>Generate ticket title</span>
              </div>
            </div>
          </div>
          <div className="checkbox-settings-content">
            <div className="checkbox-settings-container">
              <div className="switch-container mt-20">
                <Switch
                  width={40}
                  onColor="#2142E7"
                  height={20}
                  checkedIcon={false}
                  uncheckedIcon={false}
                  onChange={(checked) => {
                    setGenerateBotAiSummary(checked);
                  }}
                  checked={generateBotAiSummary}
                />
                <span>Generate ticket summary</span>
              </div>
            </div>
          </div>
          <div className="checkbox-settings-content">
            <div className="checkbox-settings-container">
              <div className="switch-container mt-20">
                <Switch
                  width={40}
                  onColor="#2142E7"
                  height={20}
                  checkedIcon={false}
                  uncheckedIcon={false}
                  onChange={(checked) => {
                    setClassifyFeedback(checked);
                  }}
                  checked={classifyFeedback}
                />
                <span>Suggest ticket priority</span>
              </div>
            </div>
          </div>
          <div className="text mb-10 mt-20">
            Choose the language for the AI generated title & ticket summary
            below.
          </div>
          <SelectDropDown
            isSearchable={true}
            labelPropertyName="name"
            valuePropertyName="code"
            selectedItem={languages.find(
              (option) => option.code === defaultSummaryLanguage,
            )}
            items={languages}
            onChange={(option) => {
              if (option?.code) {
                setDefaultSummaryLanguage(option.code);
              }
            }}
          />
          <PrimaryButton
            isLoading={loading}
            className="mt-20"
            onClick={async () => {
              setLoading(true);

              try {
                await projectStore!.updateProject(project!.id, {
                  generateAiTitle,
                  generateBotAiSummary,
                  classifyFeedback,
                  defaultSummaryLanguage,
                });
                // eslint-disable-next-line no-empty
              } catch (exp) {}

              setLoading(false);
            }}
            label="Save"
          />
        </div>
        {renderAutoTag()}
        <div className="options-group">
          <div className="options-group-header">Suggest FAQ answers</div>
          <div className="text mt-10">
            Use AI assist to automatically suggest FAQ answers from replies to
            your customers. These answers will further improve the knowledge of
            Kai.
          </div>
          <div className="checkbox-settings-content">
            <div className="checkbox-settings-container">
              <div className="switch-container mb-20 mt-20">
                <Switch
                  width={40}
                  onColor="#2142E7"
                  height={20}
                  checkedIcon={false}
                  uncheckedIcon={false}
                  onChange={(checked) => {
                    setDoNotSuggestFAQ(!checked);
                  }}
                  checked={!doNotSuggestFAQ}
                />
                <span>Enable</span>
              </div>
            </div>
          </div>
          <PrimaryButton
            isLoading={loading}
            onClick={async () => {
              setLoading(true);

              try {
                await projectStore!.updateProject(project!.id, {
                  doNotSuggestFAQ,
                });
                // eslint-disable-next-line no-empty
              } catch (exp) {}

              setLoading(false);
            }}
            label="Save"
          />
        </div>
      </PageContent>
    </PageContainer>
  );
};

export default inject(
  'projectStore',
  'organisationStore',
)(observer(AIAssistProjectSettings));

import axios from './Axios';

const removeOutboundRule = (projectId: string, id: string) => {
  return axios.delete(`/projects/${projectId}/outbound/${id}`);
};

const getStreamedEventKeys = (projectId: string) => {
  return axios.get(`/projects/${projectId}/streamedeventkeys`);
};

const getPossibleEventDataKeysForTrigger = (
  projectId: string,
  name: string,
) => {
  return axios.get(
    `/projects/${projectId}/datakeysfortrigger?name=${encodeURIComponent(
      name,
    )}`,
  );
};

const getUserPropertyFilters = (projectId: string) => {
  return axios.get(`/projects/${projectId}/userpropertyfilters`);
};

const getOutboundRules = (projectId: string, type?: string) => {
  return axios.get(`/projects/${projectId}/outbound?type=${type ?? ''}`);
};

const getOutboundRule = (projectId: string, id: string) => {
  return axios.get(`/projects/${projectId}/outbound/${id}`);
};

const getOutboundRuleStats = (projectId: string, id: string, query?: any) => {
  return axios.get(`/projects/${projectId}/outbound/${id}/stats`,
    { params: query },
  );
};

const getOutboundRuleResponses = (
  projectId: string,
  id: string,
  query: any,
) => {
  return axios.get(`/projects/${projectId}/outbound/${id}/responses`, {
    params: query,
  });
};

const getOutboundRuleResponseSamples = (
  projectId: string,
  id: string,
  query: any,
) => {
  return axios.get(`/projects/${projectId}/outbound/${id}/responses/samples`, {
    params: query,
  });
};

const getOutboundRuleRecipients = (
  projectId: string,
  id: string,
  query: any,
) => {
  return axios.get(`/projects/${projectId}/outbound/${id}/recipients`, {
    params: query,
  });
};

const getFixedAudience = (
  projectId: string,
  id: string,
  conditions: any[],
  targetAudience: string,
) => {
  return axios.post(
    `/projects/${projectId}/outbound/${id}/samplefixedaudience`,
    {
      conditions,
      targetAudience,
    },
  );
};

const askOutboundAIQuestion = (
  projectId: string,
  outboundId: string,
  question: string,
) => {
  return axios.post(
    `/projects/${projectId}/outbound/${outboundId}/aiquestion`,
    {
      question,
    },
  );
};

const markOutboundRuleResponsesAsRead = (projectId: string, id: string) => {
  return axios.put(`/projects/${projectId}/outbound/${id}/markallasread`);
};

const checkIfOutboundRuleResponsesAvailable = (
  projectId: string,
  id: string,
) => {
  return axios.get(
    `/projects/${projectId}/outbound/${id}/responses/checkavailability`,
  );
};

const createOutboundRule = (projectId: string, data: any) => {
  return axios.post(`/projects/${projectId}/outbound`, data);
};

const updateOutboundRule = (projectId: string, id: string, data: any) => {
  return axios.put(`/projects/${projectId}/outbound/${id}`, data);
};

const moveOutboundRule = (
  projectId: string,
  id: string,
  newProjectId: string,
) => {
  return axios.put(`/projects/${projectId}/outbound/${id}/move`, {
    projectId: newProjectId,
  });
};

const sendEmailPreview = (projectId: string, id: string, data: any) => {
  return axios.post(`/projects/${projectId}/outbound/${id}/emailpreview`, data);
};

const exportOutboundResponses = (projectId: string, id: string, query: any) => {
  return axios.get(`/projects/${projectId}/outbound/${id}/export`, {
    responseType: 'blob',
    params: query,
  });
};

export {
  askOutboundAIQuestion, checkIfOutboundRuleResponsesAvailable, createOutboundRule, exportOutboundResponses, getFixedAudience, getOutboundRule, getOutboundRuleRecipients, getOutboundRuleResponseSamples, getOutboundRuleResponses, getOutboundRuleStats, getOutboundRules, getPossibleEventDataKeysForTrigger, getStreamedEventKeys, getUserPropertyFilters, markOutboundRuleResponsesAsRead, moveOutboundRule, removeOutboundRule, sendEmailPreview, updateOutboundRule
};


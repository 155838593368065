import Gleap from 'gleap';
import './StartFreeTrialButton.scss';
import { trackEvent } from 'services/GTagHelper';

interface StartFreeTrialButtonProps {}

const StartFreeTrialButton = ({}: StartFreeTrialButtonProps) => {
  return (
    <>
      <div className="text mb-30 centered">
        No credit card 💳 at hand?{' '}
        <a
          href="#"
          onClick={async () => {
            Gleap.registerCustomAction((customAction) => {
              if (customAction.name === 'book.demo_live') {
                // Demo booked.
                trackEvent('book.demo_live', {
                  tutorial_name: 'onboarding',
                });
              }
            });

            trackEvent('click.demo_live', {
              tutorial_name: 'onboarding',
            });
            Gleap.startBot('64b677d82464e8eb374b8f15');
          }}
        >
          Book a demo with us.
        </a>
      </div>
    </>
  );
};

export default StartFreeTrialButton;

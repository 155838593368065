import React, { useState, useCallback, useEffect, useRef } from 'react';
import './ResizableContainer.scss';
import classNames from 'classnames';

interface ResizableContainerProps {
    children: React.ReactNode;
    maxWidth: number;
    defaultWidth: number;
    minWidth: number;
    storageKey: string;
    extendDirection?: 'left' | 'right';
    dragFinished?: () => void;
}

const prefix = 'resizable-';

const ResizableContainer = ({ children, maxWidth, defaultWidth, minWidth, storageKey, extendDirection = "right", dragFinished }: ResizableContainerProps) => {
    const savedWidth = localStorage.getItem(prefix + storageKey);
    const initialWidth = savedWidth ? parseInt(savedWidth, 10) : defaultWidth;
    const [isDragging, setIsDragging] = useState(false);
    const [panelWidth, setPanelWidth] = useState(initialWidth);
    const startX = useRef(0);
    const resizableContainerRef = useRef<HTMLDivElement>(null);
    const [isMobile, setIsMobile] = useState(false)

    const startDragging = useCallback((e) => {
        startX.current = e.clientX;
        setIsDragging(true);
        e.preventDefault();
    }, []);

    useEffect(() => {
        const checkMobile = () => {
          setIsMobile(window.innerWidth <= 768);
        };
    
        checkMobile();
    
        window.addEventListener('resize', checkMobile);
    
        return () => window.removeEventListener('resize', checkMobile);
      }, []);

    useEffect(() => {
        const onDrag = (e) => {
            requestAnimationFrame(() => {
                if (isDragging && resizableContainerRef.current) {
                    const currentX = e.clientX;
                    let newWidth;

                    if (extendDirection === 'right') {
                        const containerOffsetLeft = resizableContainerRef.current.getBoundingClientRect().left;
                        newWidth = currentX - containerOffsetLeft;
                    } else {
                        const containerOffsetRight = window.innerWidth - resizableContainerRef.current.getBoundingClientRect().right;
                        newWidth = window.innerWidth - e.clientX - containerOffsetRight;
                    }

                    newWidth = Math.max(minWidth, Math.min(maxWidth, newWidth));
                    if (newWidth !== panelWidth) {
                        setPanelWidth(newWidth);
                    }
                }
            });
        };

        const stopDragging = () => {
            setIsDragging(false);
        };

        if (isDragging) {
            window.addEventListener('mousemove', onDrag);
            window.addEventListener('mouseup', stopDragging);
        }

        return () => {
            window.removeEventListener('mousemove', onDrag);
            window.removeEventListener('mouseup', stopDragging);
        };
    }, [isDragging, maxWidth, minWidth, panelWidth, storageKey, extendDirection]);

    useEffect(() => {
        if (!isDragging) {
            dragFinished && dragFinished();
            storageKey && localStorage.setItem(prefix + storageKey, panelWidth.toString());
        }
    }, [isDragging])

    if (isMobile) {
        return <>{children}</>;
    }

    const containerClass = classNames({
        'resizable-container': true,
        'resizable-container--left': extendDirection === 'left',

    })

    return (
        <div
            ref={resizableContainerRef}
            className={containerClass}
        >
            <div
                style={extendDirection === 'left' ?
                    {  width: `${panelWidth}px` }
                    : { width: `${panelWidth}px` }}
                suppressContentEditableWarning
            >
                {children}
            </div>
            <div
                onMouseDown={startDragging}
                className='borderContainer'
                style={extendDirection === 'left' ? { left: 0 } : { right: 0 }}
            >
                <div
                    className='border'
                />
            </div>
        </div >
    );
};

export default ResizableContainer;

import PrimaryButton from 'components/PrimaryButton/PrimaryButton';
import SelectDropDown from 'components/SelectDropDown/SelectDropDown';
import { Title } from 'components/Title/Title';
import { inject, observer } from 'mobx-react';
import { useState } from 'react';
import { trackEvent } from 'services/GTagHelper';
import { OrganisationStore } from 'stores/private/OrganisationStore';
import { UsersStore } from 'stores/private/UsersStore';
import './CompleteProfile.scss';

interface CompleteProfileProps {
  usersStore?: UsersStore;
  organisationStore?: OrganisationStore;
}

const businessSizes = [
  { label: 'Small', value: '1-10', description: '1 to 10 employees' },
  { label: 'Medium', value: '11-100', description: '11 to 100 employees' },
  { label: 'Large', value: '101-500', description: '101 to 500 employees' },
  { label: 'Very Large', value: '500+', description: 'over 500 employees' },
];

const onboardingProfiles = [
  {
    name: 'Web developer',
    icon: 'laptop-code',
    value: 'webdeveloper',
    description: "I'm developing websites or web apps.",
  },
  {
    name: 'App developer',
    icon: 'mobile-screen-button',
    value: 'appdeveloper',
    description: "I'm developing apps (iOS, Android, Flutter, React Native).",
  },
  {
    name: 'Project manager',
    icon: 'chart-gantt',
    value: 'projectmanager',
    description: "I'm managing software projects.",
  },
  {
    name: 'Q/A tester',
    icon: 'vials',
    value: 'qatester',
    description: "I'm making sure that our software is stable.",
  },
  {
    name: 'Product owner',
    icon: 'file-chart-pie',
    value: 'productowner',
    description: "I'm responsible for managing my software team.",
  },
  {
    name: 'Other',
    icon: 'house-laptop',
    value: 'other',
    description: "I'm part of a software team.",
  },
];

export const CompleteProfile = ({
  usersStore,
  organisationStore,
}: CompleteProfileProps) => {
  const [teamSize, setTeamSize] = useState<string | null>(null);
  const [userRole, setUserRole] = useState<
    (typeof onboardingProfiles)[0] | null
  >(null);

  if (!usersStore) {
    return <></>;
  }

  const user = usersStore!.currentUser;
  if (!user) {
    return <></>;
  }

  const sendProfileUpdate = () => {
    if (!userRole || !teamSize) {
      return;
    }
    usersStore!.updateUser(user!.id, { userRole: userRole?.value }, true);
    organisationStore?.updateOrganisation({ teamSize: teamSize });

    // Send GA event.
    trackEvent('add.organization_extra', {
      organization_id: organisationStore?.currentOrganisation?.id,
      job_title: userRole?.value,
      organization_size: teamSize,
      tutorial_name: 'onboarding',
    });
  };

  const handleTeamSizeChange = (event) => {
    setTeamSize(event.target.value);
  };

  return (
    <div className="complete-profile">
      <div className="tell-us-more">
        <div className="tell-us-more--header">
          <Title label={`Hi ${user.firstName} 👋`} />
          <span className="text">
            Tell us more about yourself to help you get started.
          </span>
        </div>
        <div className="tell-us-more--body">
          <label htmlFor="userRole">
            What is your job title? <span className="text-danger">*</span>
          </label>
          <div className="user-role-dropdown">
            <SelectDropDown
              labelPropertyName="name"
              valuePropertyName="code"
              placeholder="Select your role"
              items={onboardingProfiles}
              onChange={(selectedOption) => {
                setUserRole(selectedOption);
              }}
            />
          </div>
          <div className="form-group">
            <label>
              Business size <span className="text-danger">*</span>
            </label>
            <div className="business-size-options">
              {businessSizes.map((size) => (
                <label key={size.value} className="business-size-option">
                  <input
                    type="radio"
                    value={size.value}
                    checked={teamSize === size.value}
                    onChange={handleTeamSizeChange}
                  />
                  <div className="business-size-text">
                    <span className="business-size-label">{size.label}</span>
                    <span className="business-size-description">
                      {'- '}
                      {size.description}
                    </span>
                  </div>
                </label>
              ))}
            </div>
          </div>
        </div>
        <PrimaryButton
          disabled={!userRole || !teamSize}
          fullWidth
          icon="arrow-right"
          iconSideRight
          label="Continue"
          onClick={() => {
            sendProfileUpdate();
          }}
        />
      </div>
    </div>
  );
};

export default inject(
  'usersStore',
  'organisationStore',
)(observer(CompleteProfile));

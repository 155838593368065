import Column from 'components/LayoutComponents/ColumnComponent/ColumnComponent';
import PrimaryButton from 'components/PrimaryButton/PrimaryButton';
import Slider from 'rc-slider';
import 'rc-slider/assets/index.css';
import { useState } from 'react';
import './UsageLimitSettings.scss';
import InfoBox from 'components/InfoBox/InfoBox';

interface UsageLimitSettingsProps {
  title: string;
  value: number;
  min: number;
  max: number;
  currencyFormatter: (value: number) => string;
  onSave: (value: number) => Promise<void>;
}

const UsageLimitSettings = ({
  title,
  value = 0,
  min,
  max,
  currencyFormatter,
  onSave,
}: UsageLimitSettingsProps) => {
  const [currentValue, setCurrentValue] = useState(value);
  const [isLoading, setIsLoading] = useState(false);

  return (
    <Column className="usage-limit-settings">
      <div className="usage-limit-settings-title">{title}</div>
      <div className="usage-limit-settings-amount-preview">
        {currencyFormatter(currentValue)}
      </div>
      <div className="usage-limit-slider">
        <div className="corner-radius">
          <Slider
            min={min}
            max={max}
            step={5}
            value={currentValue}
            onChange={(value) => {
              setCurrentValue(value);
            }}
          />
        </div>
      </div>
      {currentValue === max && (
        <InfoBox className="mt-20">
          Please contact our support team if you require a higher AI usage
          limit.
        </InfoBox>
      )}
      <PrimaryButton
        className="mt-30"
        label={`Change limit to ${currencyFormatter(currentValue)}`}
        isLoading={isLoading}
        onClick={async () => {
          setIsLoading(true);
          await onSave(currentValue);
          setIsLoading(false);
        }}
      />
    </Column>
  );
};

export default UsageLimitSettings;

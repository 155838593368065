import PaddleNewPriceTable from 'components/PaddleNewPriceTable/PaddleNewPriceTable';
import { inject, observer } from 'mobx-react';
import 'rc-slider/assets/index.css';
import { ModalStore } from 'stores/private/ModalStore';
import { PaddleStore } from 'stores/private/PaddleStore';
import './UpdateSubscriptionModal.scss';

interface UpdateSubscriptionModalProps {
  modalStore?: ModalStore;
  paddleStore?: PaddleStore;
}

const UpdateSubscriptionModal = ({
  modalStore,
  paddleStore,
}: UpdateSubscriptionModalProps) => {
  const items = paddleStore?.subscription?.items ?? [];
  let priceId = undefined;
  if (items.length > 0) {
    priceId = items[0].price.id;
  }

  return (
    <div className="update-tracked-event-modal">
      <PaddleNewPriceTable
        onboarding={false}
        currentPriceId={priceId}
        onPlanChange={(newPriceId) => {
          if (newPriceId) {
            paddleStore!.updateSubscription([
              {
                price_id: newPriceId,
                quantity: 1,
              },
            ]);
          }

          modalStore?.closeModal();
        }}
      />
    </div>
  );
};

export default inject(
  'modalStore',
  'paddleStore',
)(observer(UpdateSubscriptionModal));

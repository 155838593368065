import GleapWidget from 'assets/GleapWidget.png';
import PrimaryButton from 'components/PrimaryButton/PrimaryButton';
import TextInput from 'components/TextInput/TextInput';
import Gleap from 'gleap';
import { inject, observer } from 'mobx-react';
import { useEffect, useState } from 'react';
import { trackEvent } from 'services/GTagHelper';
import { OrganisationStore } from 'stores/private/OrganisationStore';
import { UsersStore } from 'stores/private/UsersStore';
import './CreateOrganisationOnboarding.scss';

interface CreateOrganisationOnboardingProps {
  organisationStore?: OrganisationStore;
  usersStore?: UsersStore;
}

export const CreateOrganisationOnboarding = ({
  organisationStore,
  usersStore,
}: CreateOrganisationOnboardingProps) => {
  const [orgaName, setOrgaName] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    Gleap.trackEvent('Onboarding started');
    trackEvent('begin.tutorial', {
      tutorial_name: 'onboarding',
    });
  }, []);

  const createOrganization = () => {
    if (!orgaName || orgaName.length === 0 || isLoading) {
      return;
    }

    setIsLoading(true);
    organisationStore!
      .createOrganisation(orgaName, '')
      .then((createdOrganisation: any) => {
        setIsLoading(false);
      })
      .catch(() => {
        setIsLoading(false);
      });
  };

  if (!organisationStore) {
    return <></>;
  }

  return (
    <div className="onboarding-cont">
      <div className="onboarding-cont-main">
        <div className="onboarding-title">
          Hey {usersStore?.currentUser?.firstName ?? 'there'} 👋
          <br />
          <span className="onboarding-title-sub">Let's get started!</span>
        </div>
        <TextInput
          required
          placeholder="Name of your organization"
          type="text"
          name="organame"
          className="mt-10 max-form-card text-centered"
          error=""
          onKeyPress={(event) => {
            if (event.key === 'Enter') {
              createOrganization();
            }
          }}
          onChange={(value) => {
            setOrgaName(value);
          }}
        />
        <div className="onboarding-cont-buttons">
          <PrimaryButton
            icon="arrow-right"
            iconSideRight={true}
            label="Create"
            isLoading={isLoading}
            disabled={orgaName.length === 0}
            onClick={() => {
              createOrganization();
            }}
          />
        </div>
      </div>
      <div className="onboarding-cont-side">
        <img src={GleapWidget} />
      </div>
    </div>
  );
};

export default inject(
  'organisationStore',
  'usersStore',
)(observer(CreateOrganisationOnboarding));

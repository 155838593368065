/* eslint-disable react/prop-types */
import classNames from 'classnames';
import './Filter.scss';
import ReactTooltip from 'react-tooltip';
import { isMacintosh } from 'services/Helper';
import { useEffect } from 'react';

interface FilterProps {
  value: string;
  onValueChange?: (selectedValue: string) => void;
  className?: string;
  options: {
    name: string;
    value: string;
    icon?: any;
    description?: string;
    isLoading?: boolean;
    unreadCount?: number;
  }[];
  disabled?: boolean;
  showHotkey?: boolean;
}

const Filter = ({
  value,
  onValueChange,
  className,
  options,
  disabled,
  showHotkey,
}: FilterProps) => {

    useEffect(() => {
      if (!showHotkey || disabled) {
        return;
      }
      const isMac = isMacintosh();
      const handleKeyDown = (event) => {
        // Check if CMD+number on Mac or ALT+number on Windows is pressed
        const keyNumber = parseInt(event.key);
        if (
          ((isMac && event.metaKey) || (!isMac && event.altKey)) &&
          !isNaN(keyNumber) &&
          keyNumber > 0 &&
          keyNumber <= options.length
        ) {
          event.preventDefault();
          event.stopPropagation();
  
          const selectedOption = options[keyNumber - 1];
          if (onValueChange && !disabled) {
            onValueChange(selectedOption.value);
          }
        }
      };
      
      window.addEventListener('keydown', handleKeyDown);
  
      return () => {
        window.removeEventListener('keydown', handleKeyDown);
      };
    }, []);
    
  const renderButtons = () => {
    return options.map((option, index) => {
      const priorityButtonClassName = classNames('priority-button', {
        'priority-button--active': option.value === value,
      });
      return (
        <button
          key={option.value}
          type="button"
          onClick={() => {
            if (onValueChange && !disabled) {
              onValueChange(option.value);
            }
          }}
          className={priorityButtonClassName}
          data-for="tabHotkeyTooltip"
          data-tip={JSON.stringify({
            label: option.name,
            index: index + 1,
          })}
        >
          <span>
            {option.icon && <option.icon />}
            {option.isLoading ? '--' : option.name}
            {option.unreadCount && option.unreadCount > 0 ? (
              <div className="item-unread-count">
                <div className="item-unread-count-dot" />
                {option.unreadCount} unread response
                {option.unreadCount === 1 ? '' : 's'}
              </div>
            ) : null}
          </span>
          {option.description && (
            <div className="item-description">{option.description}</div>
          )}
        </button>
      );
    });
  };

  return (
    <div
      className={`filter-selection ${className} ${
        disabled && 'filter-selection--disabled'
      }`}
    >
      {showHotkey && <ReactTooltip
        id="tabHotkeyTooltip"
        className="infoTooltip infoTooltipButton"
        delayHide={0}
        place="bottom"
        offset={{ top: -10, left: 0 }}
        type="light"
        effect="solid"
        getContent={(content) => {
          try {
            const data = JSON.parse(content);
            return (
              <div className="send-key-tooltip">
                <span>{data?.label}</span>
                <div className="hotkey-help">
                  {isMacintosh() ? <div>⌘</div> : <div>Alt</div>}
                  <div>{data.index}</div>
                </div>
              </div>
            );
          } catch (e) {}

          return null;
        }}
      />}
      {renderButtons()}
    </div>
  );
};

export default Filter;

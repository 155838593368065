import { convertTipTapToHtml } from 'helper/TipTapHelper';
import linkifyHtml from 'linkifyjs/lib/linkify-html';
import './RichTextEditor.scss';

export function replaceMarkdown(inputString: string) {
  try {
    // Replace Markdown links with HTML anchors
    const linkRegex = /\[([^\]]+)\]\((http[s]?:\/\/[^\)]+)\)/g;
    let replacedText = inputString.replace(
      linkRegex,
      '<a href="$2" target="_blank">$1</a>',
    );

    // Replace bold text (**text**) with <strong> tags
    const boldRegex = /(\*\*)(.*?)\1/g;
    replacedText = replacedText.replace(boldRegex, '<strong>$2</strong>');

    // Replace italic text (*text*) with <em> tags
    const italicRegex = /(\*)(.*?)\1/g;
    replacedText = replacedText.replace(italicRegex, '<strong>$2</strong>');

    return replacedText;
  } catch (e) {
    // Return the input string unchanged in case of an error
    return inputString;
  }
}

interface TipTapPreviewProps {
  content: any;
  fallbackValue?: string;
  className?: string;
}

const TipTapPreview = ({
  content,
  fallbackValue,
  className,
}: TipTapPreviewProps) => {
  let htmlContent = convertTipTapToHtml({ content, fallbackValue });

  try {
    htmlContent = htmlContent.replaceAll('\n', '<br>');
    htmlContent = replaceMarkdown(htmlContent);
    htmlContent = linkifyHtml(htmlContent, {
      target: {
        url: '_blank',
      },
    });
  } catch (_) {}

  return (
    <div
      className={`ProseMirror ${className}`}
      dangerouslySetInnerHTML={{ __html: htmlContent }}
    ></div>
  );
};

export default TipTapPreview;

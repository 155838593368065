import Gleap from 'gleap';
import { makeAutoObservable, runInAction } from 'mobx';
import { Bug } from 'models/Bug';
import { CreateOrganisation } from 'models/CreateOrganisation';
import { Feature } from 'models/Enums';
import { Invitation } from 'models/Invitations';
import { Organisation } from 'models/Organisation';
import { toast } from 'react-toastify';
import { trackEvent } from 'services/GTagHelper';
import { validateRecaptchaAction } from 'services/Recaptcha';
import { WEBSOCKET_EVENTS } from 'services/WebSocketService';
import {
  createOrganisation,
  createSetupIntent,
  deleteInvitation,
  deleteOrganisation,
  deleteUserFromOrga,
  getInvitedOrgaTeamMembers,
  getInvitedTeamMembers,
  getMyIssues,
  getOrgaUsers,
  getOrganisation,
  getOrganisations,
  getVoucherOfCurrentOrganisation,
  inviteTeam,
  leaveOrganisation,
  setUserRole,
  startFreeOrgaTrial,
  subscribeToPlan,
  updateOrganisation,
} from '../../services/OrganisationHttpService';

// eslint-disable-next-line import/prefer-default-export
export class OrganisationStore {
  stores: any = {};
  organisations: any[] = [];
  currentOrganisation: Organisation | undefined;
  currentOrganisationUsers: any[] = [];
  invitedTeamMembers: Invitation[] = [];
  invitedOrgaTeamMembers: Invitation[] = [];
  setupIntentClientSecret = undefined;
  isOrganisationAdminLoaded = false;
  isOrganisationAdmin = false;
  isLoadingOrganisations = false;
  isLoadingOrganisation = false;
  isLoadingOrgaUsers = false;
  loadingMAU = false;
  canShowUpsell = true;
  myIssues: Bug[] = [];
  currentMAU = 0;
  loadingMyIssues = false;
  voucherCode = {
    promotionId: '--',
    promotionCode: '--',
    createdAt: new Date(),
  };

  constructor() {
    makeAutoObservable(this);
  }

  setStores(stores) {
    this.stores = stores;
  }

  hasAIPlan = () => {
    if (!this.currentOrganisation) {
      return false;
    }

    if (
      this.currentOrganisation?.subscription?.subscriptionStatus === 'trialing'
    ) {
      return true;
    }

    if (
      this.currentOrganisation?.subscription?.subscriptionStatus !== 'active'
    ) {
      return false;
    }

    if (this.currentOrganisation?.subscription?.provider === 'paddle') {
      return true;
    }

    return this.currentOrganisation?.isMetered ?? false;
  };

  organisationDidChange = () => {
    this.isOrganisationAdmin = false;
    if (this.currentOrganisation && this.currentOrganisation !== undefined) {
      this.getOrgaUsers();
    }
  };

  setMyIssues(myIssues) {
    this.myIssues = myIssues;
  }

  setLoadingMyIssues(loadingMyIssues) {
    this.loadingMyIssues = loadingMyIssues;
  }

  loadMyIssues = async (filter) => {
    this.setLoadingMyIssues(true);
    this.setMyIssues([]);

    try {
      const resp = await getMyIssues(filter);
      if (resp && resp.data && resp.data.tickets) {
        this.setMyIssues(resp.data.tickets);
        this.setLoadingMyIssues(false);
        return;
      } else {
        this.setLoadingMyIssues(false);
      }
    } catch (err: any) {
      this.setLoadingMyIssues(false);
      toast.error('Something went wrong. Please try it again later!');
    }
  };

  leaveOrganisation = async (projectID: string) => {
    try {
      await leaveOrganisation(projectID);

      // Reload organisations
      this.setOrganisation(undefined);
      this.getMyOrganisations();

      runInAction(() => {
        // Reload projects
        this.stores.projectStore.projects = [];
        this.stores.projectStore.currentProject = undefined;
        this.stores.projectStore.getProjects();

        // Redirect to dashboard
        this.stores.navigate('/dashboard');
      });
    } catch (err: any) {
      if (err.response.status === 401) {
        toast.error('You are not authorizied');
      } else {
        toast.error('Could not leave organisation. Please try it again later!');
      }
    }
  };

  getVoucherOfCurrentOrganisation = async () => {
    if (!this.currentOrganisation?.id) {
      toast.error('No organisation selected.');
      return;
    }

    try {
      const resp = await getVoucherOfCurrentOrganisation(
        this.currentOrganisation?.id,
      );
      if (resp) {
        runInAction(() => {
          this.voucherCode = resp.data as any;
        });
        return;
      }
      // eslint-disable-next-line no-empty
    } catch (exp) {}

    toast.error('Could not load voucher. Please contact our support 🙃.');
  };

  /**
   * Sets the current organisation in the store.
   * @param currentOrganisation
   */
  setOrganisation(currentOrganisation) {
    if (!currentOrganisation || currentOrganisation === undefined) {
      this.currentOrganisationUsers = [];
    }

    this.currentOrganisation = currentOrganisation;
  }

  createOrganisation = async (name: string, picture: string) => {
    const orga: CreateOrganisation = { name, picture };
    const response: any = await createOrganisation(orga);
    this.setOrganisation(response.data as CreateOrganisation);
    this.organisationDidChange();
    this.getMyOrganisations();
    this.stores?.projectStore?.getProjects();
    this.stores?.projectStore?.getProjectsUnreadCount();
    toast.success('Organisation created 🎉');

    trackEvent('add.organization', {
      organization_id: response.data?.id,
      tutorial_name: 'onboarding',
    });

    return response.data as CreateOrganisation;
  };

  setOnboardingForKey = async (key: string) => {
    const orga = this.currentOrganisation;
    if (orga && orga.onboarding && orga.onboarding![key]) {
      return;
    }
    if (orga) {
      if (!orga.onboarding) {
        orga.onboarding = {} as any;
      }
      orga.onboarding![key] = true;
      this.updateOrganisation(orga);
    }
  };

  updateOrganisation = async (changes: any) => {
    try {
      const response: any = await updateOrganisation(
        this.currentOrganisation?.id,
        changes,
      );
      if (response && response.status === 200) {
        this.setOrganisation(response.data as Organisation);
        this.organisationDidChange();
        toast.success('Company settings saved successfully.');
      }
    } catch (err: any) {
      if (err && err.response.status === 401) {
        toast.error('You are not authorized.');
      }
    }
  };

  deleteOrganisation = async () => {
    await deleteOrganisation(this.currentOrganisation?.id);

    this.setOrganisation(undefined);
    this.getMyOrganisations();
    toast.success('Organisation deleted 🧐');
  };

  setIsLoadingOrganisation = (isLoadingOrganisation: boolean) => {
    this.isLoadingOrganisation = isLoadingOrganisation;
  };

  updateCurrentOrganisation = async () => {
    if (!this.currentOrganisation?.id) {
      return;
    }

    this.getOrganisation(this.currentOrganisation?.id);
  };

  getOrganisation = async (organisationID: string) => {
    this.setIsLoadingOrganisation(true);
    try {
      const response = await getOrganisation(organisationID);
      if (response && response.status === 200) {
        this.setOrganisation(response.data as Organisation);
      }

      this.setIsLoadingOrganisation(false);
      this.organisationDidChange();
    } catch (exp) {
      this.setIsLoadingOrganisation(false);
      this.setOrganisation(undefined);
      this.organisationDidChange();
    }
  };

  setIsLoadingOrganisations = (isLoadingOrganisations: boolean) => {
    this.isLoadingOrganisations = isLoadingOrganisations;
  };

  startFreeOrgaTrial = async () => {
    const response = await startFreeOrgaTrial(this.currentOrganisation?.id);
    if (response && response.status === 200) {
      Gleap.trackEvent('Started trial');
    } else {
      toast.error(
        'You already started a free trial. Please contact our support team to extend your trial.',
      );
    }
  };

  getMyOrganisations = async () => {
    this.setIsLoadingOrganisations(true);
    const response = await getOrganisations();
    if (response && response.status === 200) {
      runInAction(() => {
        this.organisations = response.data as any[];
      });

      if (
        !this.currentOrganisation &&
        this.organisations &&
        this.organisations.length > 0
      ) {
        this.getOrganisation(this.organisations[0].id);
      }
    }

    this.setIsLoadingOrganisations(false);
  };

  setCompletedBugReport = () => {
    if (this.currentOrganisation) {
      if (!this.currentOrganisation.onboarding) {
        this.currentOrganisation.onboarding = {} as any;
      }
      this.currentOrganisation.onboarding!.reportedbug = true;
    }
  };

  inviteTeamMember = async (emails: any[], resend = false) => {
    try {
      let token = '';
      try {
        token = (await validateRecaptchaAction('inviteTeam')) as any;
      } catch (exp) {
        toast.error('Are you human?');
        return {
          list: [],
          success: false,
        };
      }

      const response = await inviteTeam(
        this.currentOrganisation?.id!,
        emails,
        token,
      );

      this.getInvitedTeamMembers();
      this.getInvitedOrgaTeamMembers();
      this.stores?.projectStore?.getInvitedTeamMembers();

      trackEvent('invite.team_member', {
        organization_id: this.currentOrganisation?.id,
      });

      if (resend) {
        toast.success('Successfully sent invitations.');
      }

      return {
        list: response.data,
        success: true,
      };
    } catch (err: any) {
      toast.error('Could not send all invitation(s).');
      return {
        list: [],
        success: false,
      };
    }
  };

  setIsLoadingOrgaUsers = (isLoadingOrgaUsers: boolean) => {
    this.isLoadingOrgaUsers = isLoadingOrgaUsers;
  };

  getOrgaUsers = async () => {
    this.setIsLoadingOrgaUsers(true);

    if (this.isLoadingOrganisation) {
      return;
    }

    try {
      // Save current organisatiom and check if it changed after loading users.
      const savedOrganisation = this.currentOrganisation;

      const response = await getOrgaUsers(this.currentOrganisation?.id);
      if (savedOrganisation?.id !== this.currentOrganisation?.id) {
        return;
      }

      if (response.status === 200) {
        runInAction(() => {
          try {
            this.currentOrganisationUsers = response.data;

            // Update admin state.
            let isAdmin = false;
            for (let i = 0; i < this.currentOrganisationUsers.length; i++) {
              if (
                this.currentOrganisationUsers[i].id ===
                  this.stores.usersStore.currentUser.id &&
                this.currentOrganisationUsers[i].role === 'ADMIN'
              ) {
                isAdmin = true;
              }
            }

            this.isOrganisationAdmin = isAdmin;
          } catch (exp) {}
        });
      }
    } catch (_) {}

    runInAction(() => {
      this.isOrganisationAdminLoaded = true;
    });
    this.setIsLoadingOrgaUsers(false);
  };

  setUserRole = async (userID, role) => {
    try {
      const response = await setUserRole(
        this.currentOrganisation?.id,
        userID,
        role,
      );
      if (response.status === 200) {
        toast.success('Successfully updated user role.');
        this.getOrgaUsers();
      }
    } catch (err: any) {
      if (err && err.response.status === 401) {
        toast.error('You are not authorized.');
      } else if (err && err.response.status === 409) {
        toast.error('You are the last admin in the organisation!');
      } else if (err && err.response.status === 404) {
        toast.error('Updating user role failed!');
      }
    }
  };

  removeUser = async (userID) => {
    try {
      const response = await deleteUserFromOrga(
        this.currentOrganisation?.id,
        userID,
      );
      if (response.status === 200) {
        toast.success('Successfully removed user from organisation.');
        this.getOrgaUsers();

        return true;
      }
    } catch (err: any) {
      if (err && err.response.status === 401) {
        toast.error('You are not authorized.');
      } else if (err && err.response.status === 409) {
        toast.error('You are the last admin in the organisation!');
      } else if (err && err.response.status === 404) {
        toast.error('The user was not found in the organisation!');
      }
    }

    return false;
  };

  removeOwnUserFromOrga = async (userID) => {
    try {
      const response = await deleteUserFromOrga(
        this.currentOrganisation?.id,
        userID,
      );
      if (response.status === 200) {
        toast.success('Successfully deleted user from organisation.');
        this.stores.usersStore.logout();
      }
    } catch (err: any) {
      if (err && err.response.status === 401) {
        toast.error('You are not authorized.');
      } else if (err && err.response.status === 409) {
        toast.error('You have to delete the organisation first!');
      } else if (err && err.response.status === 404) {
        toast.error('The user was not found in the organisation!');
      }
    }
  };

  createSetupIntent = async () => {
    try {
      const response = await createSetupIntent(this.currentOrganisation?.id);
      if (response && response.status === 200) {
        runInAction(() => {
          this.setupIntentClientSecret = response.data.client_secret;
        });
      }
    } catch (_) {
      toast.error('Could not connect to payment server');
    }
  };

  subscribeToPlan = async (planID: string, discountCode?: string) => {
    try {
      await subscribeToPlan(this.currentOrganisation?.id, planID, discountCode);

      for (let i = 0; i < this.organisations.length; i++) {
        if (this.organisations[i].subscription) {
          this.organisations[i].subscription = {
            planID: 'notfree',
          };
        }
      }

      toast.success('Successfully subscribed to plan');

      return true;
    } catch (_) {
      if (discountCode && discountCode.length > 0) {
        toast.error('Invalid discount code.');
      } else {
        toast.error('Could not subscribe to plan.');
      }
    }
    return false;
  };

  getInvitedTeamMembers = async () => {
    try {
      const response = await getInvitedTeamMembers(
        this.currentOrganisation?.id,
      );
      runInAction(() => {
        if (response.status === 200) {
          this.invitedTeamMembers = response.data;
        }
      });
      // eslint-disable-next-line no-empty
    } catch (err: any) {}
  };

  getInvitedOrgaTeamMembers = async () => {
    try {
      const response = await getInvitedOrgaTeamMembers(
        this.currentOrganisation?.id,
      );
      runInAction(() => {
        if (response.status === 200) {
          this.invitedOrgaTeamMembers = response.data;
        }
      });
      // eslint-disable-next-line no-empty
    } catch (err: any) {}
  };

  deleteInvitation = async (invitationID) => {
    try {
      await deleteInvitation(invitationID);
    } catch (err: any) {
      toast.error('Could not delete invitation.');
    }
  };

  isFeatureInPlan = (feature: Feature, values?: string[]) => {
    try {
      const metadata = this.currentOrganisation?.subscription?.metadata;
      if (metadata && metadata[feature]) {
        if (metadata[feature] === 'true') {
          return true;
        }

        if (values && values.length > 0) {
          for (let i = 0; i < values.length; i++) {
            if (metadata[feature] === values[i]) {
              return true;
            }
            if (metadata[feature] !== 'false' && values[i] === 'number') {
              return true;
            }
          }
        }
      }
      return false;
    } catch {
      return false;
    }
  };

  projectsLeft = () => {
    try {
      let possibleProjects = 0;

      const subscription = this.currentOrganisation?.subscription;
      if (!subscription) {
        return 0;
      }

      let projectsCount = 0;
      if (this.stores?.projectStore?.projects) {
        projectsCount = this.stores?.projectStore?.projects?.length;
      }

      const metadata = subscription?.metadata;
      if (metadata && metadata.projects) {
        // eslint-disable-next-line radix
        possibleProjects = parseInt(metadata.projects);
      }

      return possibleProjects - projectsCount;
    } catch {
      return 0;
    }
  };

  openBilling = async () => {
    if (
      !this.currentOrganisation?.subscription?.planID ||
      this.currentOrganisation?.subscription?.planID === 'free'
    ) {
      this.stores.navigate(
        `/organization/${this.currentOrganisation?.id}/billing`,
      );
    } else if (this.currentOrganisation?.subscription?.planID === 'appsumo') {
      this.stores.navigate('/appsumobilling');
    } else {
    }
  };

  onEvent = (event: string, data: any) => {
    if (!data) {
      return;
    }

    if (
      event === WEBSOCKET_EVENTS.BUG_CREATED &&
      data.bugId === 1 &&
      this.currentOrganisation
    ) {
      this.setCompletedBugReport();
    }
  };

  refreshData = () => {
    // Don't refresh these infos.
    // this.getMyOrganisations();
    // this.getOrgaUsers();
  };
}

import { inject, observer } from 'mobx-react';
import { useState } from 'react';
import { BugStore } from 'stores/private/BugStore';
import { ModalStore } from 'stores/private/ModalStore';
import Column from 'components/LayoutComponents/ColumnComponent/ColumnComponent';
import PrimaryButton from 'components/PrimaryButton/PrimaryButton';
import Row from 'components/LayoutComponents/RowComponent/RowComponent';
import RecipientPicker from 'components/RecipientPicker/RecipientPicker';
import { Session } from 'models/Session';

interface AddSessionToTicketModalProps {
  bugStore?: BugStore;
  modalStore?: ModalStore;
}

const AddSessionToTicketModal = ({
  bugStore,
  modalStore,
}: AddSessionToTicketModalProps) => {
  const currentTicket = bugStore?.currentBug;
  const [sessions, setSessions] = useState([] as Session[] | null);

  const addSessionToTicket = () => {
    if (!currentTicket || !sessions) return;

    if (!currentTicket.sessions) {
      currentTicket.sessions = [];
    }

    // add to sessions and check if not already added
    const sessionIds = currentTicket.sessions.map((s) => s.id);
    sessions.forEach((s) => {
      if (!sessionIds.includes(s.id)) {
        currentTicket?.sessions?.push(s);
      }
    });

    bugStore?.updateBug(currentTicket?.id!, {
      sessions: currentTicket?.sessions,
    });

    modalStore?.closeModal();
  };

  return (
    <Column alignItems="flex-start">
      <div className="title mb-15">
        Add contact to ticket #{currentTicket?.bugId}
      </div>
      <RecipientPicker
        label=""
        icon="magnifying-glass"
        sessions={sessions}
        multipleSessions
        initialSessionId={[modalStore?.customData?.sessionId]}
        onChange={(selectedSession) => {
          setSessions(selectedSession);
        }}
      />
      <Row className="mt-20" justifyContent="flex-end">
        <PrimaryButton
          label="Add to ticket"
          disabled={!currentTicket || !sessions || sessions.length === 0}
          onClick={addSessionToTicket}
        />
      </Row>
    </Column>
  );
};

export default inject(
  'bugStore',
  'modalStore',
)(observer(AddSessionToTicketModal));

import Linkify from 'linkify-react';
import './NewLineText.scss';
import linkifyHtml from 'linkifyjs/lib/linkify-html';

interface NewLineTextProps {
  text?: string;
}

const NewLineText = ({ text }: NewLineTextProps) => {
  if (text?.includes('<a') && text?.includes('href=')) {
    let htmlContent = linkifyHtml(text || '', {
      target: {
        url: '_blank',
      },
    });
    htmlContent = htmlContent.replaceAll('\n', '<br />');

    return (
      <div
        dangerouslySetInnerHTML={{
          __html: htmlContent,
        }}
      ></div>
    );
  }

  return (
    <Linkify
      tagName="span"
      options={{
        target: {
          url: '_blank',
        },
      }}
    >
      <div className="newlinetext">
        {text &&
          typeof text.split !== 'undefined' &&
          text.split('\n').map((str, key) => <p key={key}>{str}</p>)}
      </div>
    </Linkify>
  );
};

export default NewLineText;
